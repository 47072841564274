import { AppSidebar } from '@/components/app-sidebar'
import {
  SidebarProvider
} from '@/components/ui/sidebar'
import { Outlet } from 'react-router'
import { Toaster } from 'sonner'
import { cn } from '@/utils/helper'
import { SearchProvider } from './contexts/searchContext'

export default function Layout () {
  return (
    <SearchProvider>
      <SidebarProvider style={{ '--sidebar-width': '14rem' }}>
        <AppSidebar />
        <div
          id='content'
          className={cn(
            'ml-auto w-full max-w-full',
            'peer-data-[state=collapsed]:w-[calc(100%-var(--sidebar-width-icon)-1rem)]',
            'peer-data-[state=expanded]:w-[calc(100%-var(--sidebar-width))]',
            'transition-[width] duration-200 ease-linear',
            'flex h-svh flex-col',
            'group-data-[scroll-locked=1]/body:h-full',
            'group-data-[scroll-locked=1]/body:has-[main.fixed-main]:h-svh'
          )}
        >
          <Outlet />
          <Toaster richColors expand={false} position='top-right' />
        </div>
      </SidebarProvider>
    </SearchProvider>
  )
}
