import * as React from 'react'
import { Slot } from '@radix-ui/react-slot'
import { Link } from 'react-router'
import { ChevronRight, MoreHorizontal } from 'lucide-react'

import { cn } from '@/utils/helper'

function Breadcrumb ({
  ...props
}) {
  return <nav aria-label='breadcrumb' data-slot='breadcrumb' {...props} />
}

function BreadcrumbList ({
  className,
  ...props
}) {
  return (
    (
      <ol
        data-slot='breadcrumb-list'
        className={cn(
          'text-muted-foreground flex flex-wrap items-center gap-1.5 text-sm break-words sm:gap-2.5',
          className
        )}
        {...props}
      />
    )
  )
}

function BreadcrumbItem ({
  className,
  ...props
}) {
  return (
    (
      <li
        data-slot='breadcrumb-item'
        className={cn('inline-flex items-center gap-1.5', className)}
        {...props}
      />
    )
  )
}

function BreadcrumbLink ({
  asChild,
  className,
  ...props
}) {
  const Comp = asChild ? Slot : Link

  return (
    (
      <Comp
        data-slot='breadcrumb-link'
        className={cn('hover:text-foreground transition-colors', className)}
        {...props}
      />
    )
  )
}

function BreadcrumbPage ({
  className,
  ...props
}) {
  return (
    (
      <span
        data-slot='breadcrumb-page'
        role='link'
        aria-disabled='true'
        aria-current='page'
        className={cn('text-foreground font-normal', className)}
        {...props}
      />
    )
  )
}

function BreadcrumbSeparator ({
  children,
  className,
  ...props
}) {
  return (
    (
      <li
        data-slot='breadcrumb-separator'
        role='presentation'
        aria-hidden='true'
        className={cn('[&>svg]:size-3.5', className)}
        {...props}
      >
        {children ?? <ChevronRight />}
      </li>
    )
  )
}

function BreadcrumbEllipsis ({
  className,
  ...props
}) {
  return (
    (
      <span
        data-slot='breadcrumb-ellipsis'
        role='presentation'
        aria-hidden='true'
        className={cn('flex size-9 items-center justify-center', className)}
        {...props}
      >
        <MoreHorizontal className='size-4' />
        <span className='sr-only'>More</span>
      </span>
    )
  )
}

// BreadcrumbItems link to a page
// Breadcrumbpage for the current page
// BreadcrumbSeparator for the separator
// BreadcrumbEllipsis for the ellipsis
export const BreadcrumbComponents = ({
  ...props
}) => {
  return (
    (
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbItem className='hidden md:block'>
            {props.Breadcrum1Link
              ? <BreadcrumbLink to={props.Breadcrum1Link}>
                {props.Breadcrum1}
              </BreadcrumbLink>
              : <BreadcrumbPage>{props.Breadcrum1}</BreadcrumbPage>}
          </BreadcrumbItem>
          {props.Breadcrum2 ? <BreadcrumbSeparator className='hidden md:block' /> : <></>}
          <BreadcrumbItem>
            {props.Breadcrum2Link
              ? <BreadcrumbLink to={props.Breadcrum2Link}>
                {props.Breadcrum2}
              </BreadcrumbLink>
              : <BreadcrumbPage>{props.Breadcrum2}</BreadcrumbPage>}
          </BreadcrumbItem>
          {props.Breadcrum3 ? <BreadcrumbSeparator className='hidden md:block' /> : <></>}
          <BreadcrumbItem>
            {props.Breadcrum3Link
              ? <BreadcrumbLink to={props.Breadcrum3Link}>
                {props.Breadcrum3}
              </BreadcrumbLink>
              : <BreadcrumbPage>{props.Breadcrum3}</BreadcrumbPage>}
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>
    )
  )
}
