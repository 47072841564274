import ContentSection from '@/components/shared-sections/content-section'
import { endpoints } from '@/utils/endpoints'
import useQuery from '@/utils/useQuery'
import WebhookTable from './webhook-table'
import { Button } from '@/components/ui/button'
import { Link } from 'react-router'
import { VIDEO_SOURCES_PAGINATION_LIMIT } from '@/utils/constants'
import { useState } from 'react'
import DeleteActionDialog from '@/components/delete-action-dialog'
import { toast } from 'sonner'
import http from '@/utils/http'
import { SkeletonBasics } from '@/components/shimmers/basic'
import { Plus } from 'lucide-react'

export default function Webhooks () {
  const [isDeleteOpen, setIsDeleteOpen] = useState(null)

  const { data: webhooks, setData, isLoading } = useQuery({
    url: `${endpoints.organization.webhook.base}`
  })

  const { data: allVideoSources, isLoading: isVideoSourcesLoading } = useQuery({
    url: `${endpoints.video.sources.base + VIDEO_SOURCES_PAGINATION_LIMIT}`
  })

  if (isLoading || isVideoSourcesLoading) return <SkeletonBasics />

  const updatedWebhooks = webhooks && webhooks.all_webhooks
  for (const webhook of updatedWebhooks) {
    webhook.namespace = []
    for (const source_id of webhook.sources) {
      const source = allVideoSources?.all_sources.find((src) => src.id === source_id)
      webhook.namespace.push(source?.name)
    }
  }

  const _deleteWebhook = async (id) => {
    try {
      await http({}).delete(endpoints.organization.webhook.edit.replace(':id', id))
      setData((prevTokens) => ({
        ...prevTokens,
        all_webhooks: prevTokens.all_webhooks.filter((item) => item.id !== id)
      }))
      toast.success('Webhook deleted successfully')
    } catch (error) {
      toast.error(error?.error?.message)
    } finally {
      setIsDeleteOpen(null)
    }
  }
  return (
    <ContentSection
      title='Webhooks'
      desc='Webhooks allow you to build or set up integrations that subscribe to certain events on Gumlet APIs.'
      className='max-w-none'
      has_action={
        <Link to='/organization/webhooks/add'>
          <Button className='cursor-pointer' variant='white'><Plus className='h-4 w-4' />Add Webhook</Button>
        </Link>
      }
    >
      <WebhookTable webhooks={webhooks} setIsDeleteOpen={setIsDeleteOpen} />
      {isDeleteOpen &&
        <DeleteActionDialog
          isDeleteOpen={isDeleteOpen}
          setIsDeleteOpen={setIsDeleteOpen}
          onSubmit={_deleteWebhook}
          title='Are you sure you wish to delete this webhook?'
        />}
    </ContentSection>
  )
}
