import ContentSection from '@/components/shared-sections/content-section'
import WebhookForm from './webhook-form'
import { useParams } from 'react-router'
import WebhookHistoryTable from './webhook-history-table'
import useQuery from '@/utils/useQuery'
import { endpoints } from '@/utils/endpoints'
import { DataContext } from '@/contexts/DataProvider'
import { use } from 'react'

export default function EditWebhook () {
  const { id } = useParams()
  const { userData } = use(DataContext)

  const { data: getWebhookByID } = useQuery({
    url: `${endpoints.organization.webhook.edit.replace(':id', id)}`
  })

  const { data: getWebhookHistory } = useQuery({
    url: `${endpoints.organization.webhook.getHistory.replace(':webhook_id', id)}`
  })

  if (!getWebhookHistory || !getWebhookByID) return null
  return (
    <ContentSection
      title='Edit Webhook'
      desc='Webhooks allow you to build or set up integrations that subscribe to certain events on Gumlet APIs.'
      className='max-w-none'
    >
      <div className='flex gap-12 flex-col'>
        <WebhookForm id={id} getWebhookByID={getWebhookByID} />
        <WebhookHistoryTable getWebhookHistory={getWebhookHistory} userData={userData} />
      </div>
    </ContentSection>
  )
}
