import { Copy } from 'lucide-react'
import PropTypes from 'prop-types'
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger
} from './tooltip'
import { handleCopy } from '@/utils/helper'

export function CopyButton ({ data, message = 'Copied to clipboard', className = '', tooltip = 'Copy to clipboard' }) {
  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Copy
          className={`h-3.5 w-3.5 cursor-pointer text-muted-foreground ${className}`}
          onClick={() => handleCopy({ data, message })}
        />
      </TooltipTrigger>
      <TooltipContent>
        <p>{tooltip}</p>
      </TooltipContent>
    </Tooltip>
  )
}

CopyButton.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  message: PropTypes.string,
  className: PropTypes.string,
  tooltip: PropTypes.string
}
