import * as React from 'react'
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu'
import { CheckIcon, ChevronRightIcon, CircleIcon } from 'lucide-react'

import { cn } from '@/utils/helper'

function DropdownMenu ({
  ...props
}) {
  return <DropdownMenuPrimitive.Root data-slot='dropdown-menu' {...props} />
}

function DropdownMenuPortal ({
  ...props
}) {
  return (<DropdownMenuPrimitive.Portal data-slot='dropdown-menu-portal' {...props} />)
}

function DropdownMenuTrigger ({
  ...props
}) {
  return (<DropdownMenuPrimitive.Trigger data-slot='dropdown-menu-trigger' {...props} />)
}

function DropdownMenuContent ({
  className,
  sideOffset = 4,
  ...props
}) {
  return (
    (
      <DropdownMenuPrimitive.Portal>
        <DropdownMenuPrimitive.Content
          data-slot='dropdown-menu-content'
          sideOffset={sideOffset}
          className={cn(
            'bg-background text-popover-foreground data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 z-50 min-w-[8rem] overflow-hidden rounded-md border p-2 shadow-md',
            className
          )}
          {...props}
        />
      </DropdownMenuPrimitive.Portal>
    )
  )
}

function DropdownMenuGroup ({
  ...props
}) {
  return (<DropdownMenuPrimitive.Group data-slot='dropdown-menu-group' {...props} />)
}

function DropdownMenuItem ({
  className,
  inset,
  variant = 'default',
  ...props
}) {
  return (
    (
      <DropdownMenuPrimitive.Item
        data-slot='dropdown-menu-item'
        data-inset={inset}
        data-variant={variant}
        className={cn(
          "focus:bg-accent focus:text-accent-foreground data-[variant=destructive]:text-destructive data-[variant=destructive]:focus:bg-destructive/10 data-[variant=destructive]:focus:text-destructive data-[variant=destructive]:*:[svg]:!text-destructive [&_svg:not([class*='text-'])]:text-muted-foreground relative flex items-center gap-2 rounded-sm px-2 py-2 text-sm outline-hidden select-none data-[disabled]:pointer-events-none data-[disabled]:opacity-50 data-[inset]:pl-8 [&_svg]:pointer-events-none [&_svg]:shrink-0 [&_svg:not([class*='size-'])]:size-4 cursor-pointer",
          className
        )}
        {...props}
      />
    )
  )
}

function DropdownMenuCheckboxItem ({
  className,
  children,
  checked,
  ...props
}) {
  return (
    (
      <DropdownMenuPrimitive.CheckboxItem
        data-slot='dropdown-menu-checkbox-item'
        className={cn(
          "focus:bg-accent focus:text-accent-foreground relative flex cursor-default items-center gap-2 rounded-sm py-1.5 pr-2 pl-8 text-sm outline-hidden select-none data-[disabled]:pointer-events-none data-[disabled]:opacity-50 [&_svg]:pointer-events-none [&_svg]:shrink-0 [&_svg:not([class*='size-'])]:size-4",
          className
        )}
        checked={checked}
        {...props}
      >
        <span
          className='pointer-events-none absolute left-2 flex size-3.5 items-center justify-center'
        >
          <DropdownMenuPrimitive.ItemIndicator>
            <CheckIcon className='size-4' />
          </DropdownMenuPrimitive.ItemIndicator>
        </span>
        {children}
      </DropdownMenuPrimitive.CheckboxItem>
    )
  )
}

function DropdownMenuRadioGroup ({
  ...props
}) {
  return (<DropdownMenuPrimitive.RadioGroup data-slot='dropdown-menu-radio-group' {...props} />)
}

function DropdownMenuRadioItem ({
  className,
  children,
  ...props
}) {
  return (
    (
      <DropdownMenuPrimitive.RadioItem
        data-slot='dropdown-menu-radio-item'
        className={cn(
          "focus:bg-accent focus:text-accent-foreground relative flex cursor-default items-center gap-2 rounded-sm py-1.5 pr-2 pl-8 text-sm outline-hidden select-none data-[disabled]:pointer-events-none data-[disabled]:opacity-50 [&_svg]:pointer-events-none [&_svg]:shrink-0 [&_svg:not([class*='size-'])]:size-4",
          className
        )}
        {...props}
      >
        <span
          className='pointer-events-none absolute left-2 flex size-3.5 items-center justify-center'
        >
          <DropdownMenuPrimitive.ItemIndicator>
            <CircleIcon className='size-2 fill-current' />
          </DropdownMenuPrimitive.ItemIndicator>
        </span>
        {children}
      </DropdownMenuPrimitive.RadioItem>
    )
  )
}

function DropdownMenuLabel ({
  className,
  inset,
  ...props
}) {
  return (
    (
      <DropdownMenuPrimitive.Label
        data-slot='dropdown-menu-label'
        data-inset={inset}
        className={cn('px-2 py-1.5 text-sm font-semibold data-[inset]:pl-8', className)}
        {...props}
      />
    )
  )
}

function DropdownMenuSeparator ({
  className,
  ...props
}) {
  return (
    (
      <DropdownMenuPrimitive.Separator
        data-slot='dropdown-menu-separator'
        className={cn('bg-border -mx-1 my-1 h-px', className)}
        {...props}
      />
    )
  )
}

function DropdownMenuShortcut ({
  className,
  ...props
}) {
  return (
    (
      <span
        data-slot='dropdown-menu-shortcut'
        className={cn('text-muted-foreground ml-auto text-xs tracking-widest', className)}
        {...props}
      />
    )
  )
}

function DropdownMenuSub ({
  ...props
}) {
  return <DropdownMenuPrimitive.Sub data-slot='dropdown-menu-sub' {...props} />
}

function DropdownMenuSubTrigger ({
  className,
  inset,
  children,
  ...props
}) {
  return (
    (
      <DropdownMenuPrimitive.SubTrigger
        data-slot='dropdown-menu-sub-trigger'
        data-inset={inset}
        className={cn(
          'focus:bg-accent focus:text-accent-foreground data-[state=open]:bg-accent data-[state=open]:text-accent-foreground flex cursor-default items-center rounded-sm px-2 py-1.5 text-sm outline-hidden select-none data-[inset]:pl-8',
          className
        )}
        {...props}
      >
        {children}
        <ChevronRightIcon className='ml-auto size-4' />
      </DropdownMenuPrimitive.SubTrigger>
    )
  )
}

function DropdownMenuSubContent ({
  className,
  ...props
}) {
  return (
    (
      <DropdownMenuPrimitive.SubContent
        data-slot='dropdown-menu-sub-content'
        className={cn(
          'bg-popover text-popover-foreground data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 z-50 min-w-[8rem] overflow-hidden rounded-md border p-1 shadow-lg',
          className
        )}
        {...props}
      />
    )
  )
}

export {
  DropdownMenu,
  DropdownMenuPortal,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuLabel,
  DropdownMenuItem,
  DropdownMenuCheckboxItem,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuSub,
  DropdownMenuSubTrigger,
  DropdownMenuSubContent
}
