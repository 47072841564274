import React, { useState } from 'react'
import { Button } from '@/components/ui/button'
import { toast } from 'sonner'
import http from '@/utils/http'
import { endpoints } from '@/utils/endpoints'
import { Input } from '@/components/ui/input'
import Cookies from 'js-cookie'
import { authenticators } from './data'
import PropTypes from 'prop-types'

export default function TwoFactorForm ({
  userData
}) {
  const [loading, setLoading] = useState(false)
  const [token, setToken] = useState('')
  const [error, setError] = useState('')
  const [qrCode, setQRCode] = useState('')
  // get cookies for two factor auth
  const isTwoFactorAuthOn =
    Cookies.get('--gm-two-factor-auth-activation')
      ? Cookies.get('--gm-two-factor-auth-activation') === 'active'
      : userData?.two_factor_auth

  const _handleInputChange = (value) => {
    if (!/^\d*$/.test(value)) return
    // Restrict to 6 digits
    if (value.length > 6) {
      setError('Only 6 digits are allowed')
      return
    } else {
      setError('') // Clear error when valid
    }
    setToken(value)
  }

  // handle two-factor authentication
  const _handleTwoFactor = async (value) => {
    setLoading(true)
    setError('')
    const promise =
      value === 'enable'
        ? http({}).post(endpoints.organization.profile.enableTwoFactor)
        : http({}).post(endpoints.organization.profile.disableTwoFactor)

    toast.promise(promise, {
      loading: value === 'enable' ? 'Generating QR code...' : 'Disabling two-factor authentication...',
      success: value === 'enable' ? 'QR code Generated' : 'Two-factor authentication disabled',
      error: (error) => error?.error?.message || 'An error occurred while generating QR code'
    })

    try {
      const response = await promise
      if (response.two_factor_qr && value === 'enable') {
        setQRCode(response?.two_factor_qr)
      } else if (value === 'disable') {
        setQRCode('')
        // set the two factor cookies active in the cookies
        Cookies.set('--gm-two-factor-auth-activation', 'inactive', {
          path: '/',
          expires: new Date(new Date().getTime() + 2 * 60000)
        })
      }
    } catch (error) {
      console.error('Error enabling two-factor authentication', error)
    } finally {
      setLoading(false)
    }
  }

  /* Verify 2fa api call */
  const handleVerifyTwoFactor = async () => {
    setLoading(true)
    setError('')
    if (!token || token.length < 6) {
      setLoading(false)
      setError('Only 6 digits are allowed')
      return
    }
    // promise to verify 2fa
    const payload = { token }
    const promise = http({}).post(
      endpoints.organization.profile.verifyTwoFactor,
      payload
    )
    toast.promise(promise, {
      loading: 'Verifying code...',
      success: 'Two-factor authentication enabled',
      error: (error) => error?.error?.message || 'An error occurred'
    })

    try {
      await promise
      setToken('')
      setQRCode('')
      // set the two factor cookies active in the cookies
      Cookies.set('--gm-two-factor-auth-activation', 'active', {
        path: '/',
        // set expires cookies after 10 min of the
        expires: new Date(new Date().getTime() + 2 * 60000)
      })
    } catch (error) {
      console.error('Error verifying two-factor authentication', error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className='--two-factor-outer-div'>
      {qrCode === '' && !isTwoFactorAuthOn &&
        <Button
          type='submit'
          variant='white'
          className='cursor-pointer'
          onClick={() => _handleTwoFactor('enable')}
          disabled={loading}
        >
          Enable 2FA
        </Button>}
      {qrCode === '' && isTwoFactorAuthOn &&
        <div className='--active-two-factor-qr-code'>
          <Button
            type='submit'
            variant='destructive'
            className='cursor-pointer'
            onClick={() => _handleTwoFactor('disable')}
            disabled={loading}
          >
            Disabled two-factor authentication
          </Button>
        </div>}
      {qrCode !== '' &&
        <div className='--two-factor-qr-code'>
          <div className='--two-factor-qr-code-inner p-1 flex justify-between items-start gap-8'>
            <div className='--two-factor-qr-code-inner-text'>
              <ul className='pr-6 pt-0 space-y-8'>
                <li className='flex items-start gap-4'>
                  <span className='relative flex shrink-0 overflow-hidden rounded-full h-9 w-9'>
                    <span className='flex h-full w-full items-center justify-center rounded-full bg-muted'>
                      1
                    </span>
                  </span>
                  <span className='text-sm'>
                    <p>Use one-time password authenticator on your mobile device or computer to enable two-factor authentication (2FA).</p>
                    <div className='space-y-4 text-gray-900 dark:text-gray-100 mt-4'>
                      {authenticators.map(({ name, links }) => (
                        <div key={name} className='flex flex-col'>
                          <span className='font-semibold'>{name}</span>
                          <div className='flex flex-wrap gap-2 text-gray-600 dark:text-gray-500 text-xs'>
                            {links.map(({ platform, url }, index) => (
                              <span key={platform}>
                                <a href={url} rel='nofollow'>{platform}</a>
                                {index < links.length - 1 && ' | '}
                              </span>
                            ))}
                          </div>
                        </div>
                      ))}
                    </div>
                  </span>
                </li>
                <li className='flex items-start gap-4'>
                  <span className='relative flex shrink-0 overflow-hidden rounded-full h-9 w-9'>
                    <span className='flex h-full w-full items-center justify-center rounded-full bg-muted'>
                      2
                    </span>
                  </span>
                  <div className='flex flex-col gap-3'>
                    <p className='text-sm'>Enter the code which display in your authenticator screen</p>
                    <div className='flex gap-4'>
                      <Input
                        type='text'
                        placeholder='Enter code'
                        onChange={(e) => _handleInputChange(e.target.value)}
                        onKeyDown={(e) => e.key === 'Enter' && handleVerifyTwoFactor()}
                        value={token}
                      />
                      <Button
                        type='submit'
                        variant='white'
                        className='cursor-pointer'
                        disabled={loading}
                        onClick={() => handleVerifyTwoFactor()}

                      >
                        Verify
                      </Button>
                    </div>
                    {error && <p className='text-red-500 text-xs'>{error}</p>}
                  </div>
                </li>
              </ul>
            </div>
            <div className='flex flex-col gap-2'>
              <img
                src={qrCode}
                className='--two-factor-qr-code-inner-image rounded-xl'
                alt='qrcode'
                style={{ maxWidth: '10rem' }}
              />
              <small className='text-xs text-gray-500 dark:text-gray-400 text-center'>
                Scan this QR code with your authenticator app
              </small>
            </div>
          </div>
        </div>}
    </div>
  )
}

TwoFactorForm.prototype = {
  userData: PropTypes.object.isRequired
}
