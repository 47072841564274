import PropTypes from 'prop-types'
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  getPaginationRowModel
} from '@tanstack/react-table'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from '@/components/ui/table'
import { Card } from '@/components/ui/card'
import DataTablePagination from './pagination'
import { Badge } from '@/components/ui/badge'
import { Button } from '@/components/ui/button'
import { Download, ExternalLink } from 'lucide-react'

export default function TotalInvoices ({
  invoices
}) {
  const columns = [
    // date, amount, paymet status, paylent link, download
    {
      id: 'date',
      accessorKey: 'created',
      header: 'Date',
      cell: ({ getValue }) =>
        getValue()
          ? new Date(getValue() * 1000).toDateString()
          : '-'
    },
    {
      id: 'amount',
      accessorKey: 'action',
      header: 'Amount',
      cell: ({ row }) => {
        const amount = row.original.amount_due // Fetch amount_due
        const currency = row.original.currency || '' // Fetch currency, fallback to empty string
        return `${amount?.toFixed(2)} ${currency.toUpperCase()}` // Format as "0.00 USD"
      }
    },
    {
      id: 'payment_status',
      accessorKey: 'status',
      header: 'Payment Status',
      cell: ({ getValue }) => {
        const status = getValue()
        return status === 'paid'
          ? (
            <Badge variant='primary'>Paid</Badge>
            )
          : (
            <Badge variant='destructive'>Unpaid</Badge>
            )
      }
    },
    {
      id: 'payment_link',
      accessorKey: 'action',
      header: 'Payment Link',
      cell: ({ row }) => {
        return row.original.paid
          ? <a href={row.original.hosted_invoice_url} target='_blank' rel='noreferrer noopener'>
            <Button variant='outline' size='xs'>
              <ExternalLink size={6} />
              Payment Info
            </Button>
            </a>
          : <a href={row.original.hosted_invoice_url} target='_blank' rel='noreferrer noopener'>
            <Button variant='destructive' size='xs'>
              Pay Now
            </Button>

          </a>
      }
    },
    {
      id: 'download',
      accessorKey: 'action',
      header: 'Download',
      cell: ({ row }) => {
        return (
          <a href={row.original.invoice_pdf} target='_blank' rel='noreferrer noopener'>
            <Button variant='outline' size='xs'>
              <Download size={6} />
              <span className='--invoice-download-link'>Download</span>
            </Button>
          </a>
        )
      }
    }
  ]

  const table = useReactTable({
    data: invoices,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel()
  })

  return (
    <div className='space-y-4 w-full'>
      <Card className='rounded-md border shadow-none'>
        <Table>
          <TableHeader>
            {
              table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id} className='group/row'>
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead
                        key={header.id}
                        colSpan={header.colSpan}
                        className={header.column.columnDef.meta?.className ?? ''}
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                      </TableHead>
                    )
                  })}
                </TableRow>
              ))
            }
          </TableHeader>
          <TableBody>
            {
              table.getRowModel().rows?.length
                ? (
                    table.getRowModel().rows.map((row) => (
                      <TableRow
                        key={row.id}
                        data-state={row.getIsSelected() && 'selected'}
                        className='group/row'
                      >
                        {row.getVisibleCells().map((cell) => {
                          return (
                            <TableCell
                              key={cell.id}
                              className={cell.column.columnDef.meta?.className ?? ''}
                            >
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              ) || '-'}
                            </TableCell>
                          )
                        })}
                      </TableRow>
                    ))
                  )
                : (
                  <TableRow>
                    <TableCell colSpan={columns.length} className='h-25 text-center'>
                      No invoices found
                    </TableCell>
                  </TableRow>
                  )
            }
          </TableBody>
        </Table>
      </Card>
      <DataTablePagination
        table={table}
        className='mt-4 flex justify-between'
      />
    </div>
  )
}

TotalInvoices.propTypes = {
  invoices: PropTypes.array
}
