import PropTypes from 'prop-types'
import StripeElements from './stripe-elements'
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog'

export default function StripeCardModal ({
  isOpen,
  setIsOpen,
  refetchCards
}) {
  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Add Card</DialogTitle>
          <StripeElements
            setIsOpen={setIsOpen}
            refetchCards={refetchCards}
            isModal
          />
        </DialogHeader>
      </DialogContent>
    </Dialog>
  )
}

StripeCardModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  refetchCards: PropTypes.func.isRequired
}
