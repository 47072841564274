import { Button } from '@/components/ui/button'
import PropTypes from 'prop-types'
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { Form, FormControl, FormField, FormItem, FormLabel } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { useForm } from 'react-hook-form'
import { LoadingSpinner } from '@/components/ui/spinner'
import { useEffect } from 'react'
import { Checkbox } from '@/components/ui/checkbox'
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group'

export default function NewStreamModal ({ isDialogOpen, setIsDialogOpen, _createStream, _updateStreamTitle }) {
  const form = useForm({ mode: 'onChange', defaultValues: { title: '', resolution: ['360p', '480p', '720p'], orientation: 'landscape' } })
  const isEdit = typeof isDialogOpen === 'object'

  const RESOLUTION_OPTIONS = [
    { id: 1, name: '240p' },
    { id: 2, name: '360p' },
    { id: 3, name: '480p' },
    { id: 4, name: '720p' },
    { id: 5, name: '1080p' }
  ]

  useEffect(() => {
    if (!isDialogOpen) {
      form.reset()
    }
    if (isEdit) {
      form.setValue('title', isDialogOpen.input.title)
    }
  }, [form, isDialogOpen, isEdit])
  return (
    <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{isEdit ? 'Update Title' : 'Add Live Stream'}</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(isEdit ? _updateStreamTitle : _createStream)} className='space-y-8'>
            <FormField
              control={form.control}
              name='title'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Title</FormLabel>
                  <FormControl>
                    <Input placeholder='e.g. title-1' {...field} required={isEdit} />
                  </FormControl>
                </FormItem>
              )}
            />
            {!isEdit &&
              <>
                <FormField
                  control={form.control}
                  name='resolution'
                  render={() => (
                    <FormItem>
                      <FormLabel>Select Resolutions</FormLabel>
                      <div className='flex items-center space-x-2 mt-2'>
                        {RESOLUTION_OPTIONS.map((item) => (
                          <FormField
                            key={item.id}
                            control={form.control}
                            name='resolution'
                            render={({ field }) => {
                              return (
                                <FormItem key={item.id} className='flex flex-row items-start space-y-0'>
                                  <FormControl>
                                    <Checkbox
                                      checked={field.value?.includes(item.name)}
                                      onCheckedChange={(checked) => {
                                        checked
                                          ? field.onChange([...field.value, item.name])
                                          : field.onChange(
                                            field.value?.filter(
                                              (value) => value !== item.name
                                            )
                                          )
                                      }}
                                    />
                                  </FormControl>
                                  <FormLabel className='text-sm font-normal'>
                                    {item.name}
                                  </FormLabel>
                                </FormItem>
                              )
                            }}
                          />
                        ))}
                      </div>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name='orientation'
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Select Orientation</FormLabel>
                      <FormControl>
                        <RadioGroup
                          onValueChange={field.onChange}
                          defaultValue={field.value}
                          className='flex items-center space-x-2'
                        >
                          <FormItem className='flex items-center'>
                            <FormControl>
                              <RadioGroupItem value='landscape' />
                            </FormControl>
                            <FormLabel className='text-sm font-normal'>
                              Landscape
                            </FormLabel>
                          </FormItem>
                          <FormItem className='flex items-center'>
                            <FormControl>
                              <RadioGroupItem value='portrait' />
                            </FormControl>
                            <FormLabel className='text-sm font-normal'>
                              Portrait
                            </FormLabel>
                          </FormItem>
                        </RadioGroup>
                      </FormControl>
                    </FormItem>
                  )}
                />
              </>}
            <DialogFooter>
              <Button type='button' variant='outline' onClick={() => setIsDialogOpen(false)} className='cursor-pointer'>Cancel</Button>
              <Button type='submit' className='cursor-pointer' disabled={form.formState.isSubmitting}>{form.formState.isSubmitting && <LoadingSpinner />}{isEdit ? 'Update Title' : 'Create Stream'}</Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}

NewStreamModal.propTypes = {
  isDialogOpen: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
  setIsDialogOpen: PropTypes.func.isRequired,
  _createStream: PropTypes.func.isRequired,
  _updateStreamTitle: PropTypes.func.isRequired
}
