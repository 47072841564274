'use strict'

import React, { useState } from 'react'
import { Button } from '@/components/ui/button'
import { convertEpochToTimezone } from '@/utils/helper'
import PropTypes from 'prop-types'
import { Edit } from 'lucide-react'
import EditCollection from './edit-collection-form'

export default function CollectionLists ({
  collections
}) {
  return (
    <ul className='faded-bottom no-scrollbar grid gap-4 overflow-auto pb-16 pt-4 md:grid-cols-2 lg:grid-cols-3'>
      {
      collections && collections.all_live_sources.map((collection, index) => (
        <li
          key={index}
          className='rounded-lg border p-4 hover:shadow-md'
        >
          <div className='mb-8 flex items-center justify-between'>
            <div
              className='flex size-10 items-center justify-center rounded-lg bg-muted p-2'
            >
              {collection.name.charAt(0)}
            </div>
            <EditCollection collection={collection} />
          </div>
          <div>
            <h2 className='mb-1 font-semibold'>{collection.name}</h2>
            <p className='line-clamp-2 text-gray-500 text-xs'>{convertEpochToTimezone(collection.created_at)}</p>
          </div>
        </li>
      ))
    }
    </ul>
  )
}

CollectionLists.propTypes = {
  collections: PropTypes.object.isRequired
}
