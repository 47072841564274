import * as React from 'react'
import { Slot } from '@radix-ui/react-slot'
import { cva } from 'class-variance-authority'

import { cn } from '@/utils/helper'

const buttonVariants = cva(
  "inline-flex items-center justify-center gap-1 whitespace-nowrap rounded-md text-sm font-medium transition-[color,box-shadow] disabled:pointer-events-none disabled:opacity-50 [&_svg]:pointer-events-none [&_svg:not([class*='size-'])]:size-4 [&_svg]:shrink-0 ring-ring/10 dark:ring-ring/20 dark:outline-ring/40 outline-ring/50 focus-visible:ring-4 focus-visible:outline-1 aria-invalid:focus-visible:ring-0 cursor-pointer",
  {
    variants: {
      variant: {
        default:
          'bg-primary text-primary-foreground shadow-sm hover:bg-primary/90',
        white:
          'dark:bg-white dark:text-gray-900 shadow-xs dark:hover:bg-white dark:hover:text-gray-900 bg-gray-900 text-white shadow-xs hover:bg-gray-900/90',
        destructive:
          'bg-destructive text-destructive-foreground shadow-xs hover:bg-destructive/90',
        delete:
          'bg-destructive text-destructive-foreground shadow-xs hover:bg-destructive/90',
        outline:
          'border border-input bg-background shadow-xs hover:bg-accent hover:text-accent-foreground',
        secondary:
          'bg-secondary text-secondary-foreground shadow-xs hover:bg-secondary/80',
        ghost: 'hover:bg-accent hover:text-accent-foreground',
        link: 'text-primary underline-offset-4 hover:underline'
      },
      size: {
        default: 'h-9 px-4 py-2 has-[>svg]:px-3',
        xs: 'h-6 px-2.5 py-1.5 has-[>svg]:px-2 text-xs',
        sm: 'h-8 rounded-md px-4 has-[>svg]:px-3.5 text-sm',
        lg: 'h-10 rounded-md px-6 has-[>svg]:px-4',
        icon: 'size-9'
      }
    },
    defaultVariants: {
      variant: 'default',
      size: 'default'
    }
  }
)

function Button ({
  className,
  variant,
  size,
  asChild = false,
  href,
  ...props
}) {
  let Comp = 'button'

  if (asChild) {
    Comp = Slot
  } else if (href) {
    Comp = 'a'
  }

  return (
    (
      <Comp
        data-slot='button'
        className={cn(buttonVariants({ variant, size, className }))}
        href={href}
        {...props}
      />
    )
  )
}

export { Button, buttonVariants }
