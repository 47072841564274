import { NavProfile } from '@/components/nav-profile'
import { NavHeader } from '@/components/nav-header'
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarRail
} from '@/components/ui/sidebar'
import { use } from 'react'
import { DataContext } from '@/contexts/DataProvider'
import { data } from '@/components/data/sidebar'
import { NavGroup } from '@/components/ui-layout/nav-group'
import PropTypes from 'prop-types'

export function AppSidebar ({
  ...props
}) {
  const { userData } = use(DataContext)
  return (
    (
      <Sidebar collapsible='icon' variant='floating' {...props}>
        <SidebarHeader>
          <NavHeader userData={userData} />
        </SidebarHeader>
        <SidebarContent>
          {data.navGroups.map((props) => (
            <NavGroup key={props.title} {...props} />
          ))}
        </SidebarContent>
        <SidebarFooter>
          <NavProfile userData={userData} />
        </SidebarFooter>
        <SidebarRail />
      </Sidebar>
    )
  )
}

AppSidebar.propTypes = {
  title: PropTypes.string.isRequired
}
