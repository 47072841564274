import { format } from 'date-fns'
import { CalendarIcon } from 'lucide-react'
import PropTypes from 'prop-types'
import { cn, getDateRange } from '@/utils/helper'
import { Button } from '@/components/ui/button'
// import { Calendar } from '@/components/ui/calendar'
import {
  Popover,
  PopoverContent,
  PopoverTrigger
} from '@/components/ui/popover'
import { ToggleGroup, ToggleGroupItem } from '@/components/ui/toggle-group'

export function DatePickerWithPresets ({
  range,
  date,
  onChange,
  className
}) {
  const handlePresetChange = (value) => {
    const dateRange = getDateRange(value)
    onChange({
      type: 'preset',
      value,
      dateRange: {
        start: dateRange.start,
        end: dateRange.end
      }
    })
  }

  const handleCustomDateChange = (newDate) => {
    // If we have a complete range
    if (newDate?.from && newDate?.to) {
      onChange({
        type: 'custom',
        value: newDate,
        dateRange: {
          start: format(newDate.from, 'yyyy-MM-dd'),
          end: format(newDate.to, 'yyyy-MM-dd')
        }
      })
    }
    // If we're selecting the first date
    else if (newDate?.from) {
      onChange({
        type: 'custom',
        value: { from: newDate.from, to: null },
        dateRange: null
      })
    }
  }

  return (
    <div className={cn('flex items-center gap-2', className)}>
      <ToggleGroup
        type='single'
        value={date?.from && date?.to ? null : range}
        onValueChange={handlePresetChange}
        variant='outline'
        className='*:data-[slot=toggle-group-item]:!px-4'
      >
        <ToggleGroupItem value='this_month' className='cursor-pointer'>This month</ToggleGroupItem>
        <ToggleGroupItem value='last_month' className='cursor-pointer'>Last month</ToggleGroupItem>
      </ToggleGroup>

      <Popover>
        <PopoverTrigger asChild>
          <Button
            variant='outline'
            className={cn(
              'w-[240px] justify-start text-left font-normal',
              !date && 'text-muted-foreground'
            )}
          >
            <CalendarIcon className='mr-2 h-4 w-4' />
            {date?.from
              ? (
                  date.to
                    ? (
                      <>
                        {format(date.from, 'LLL dd, y')} -{' '}
                        {format(date.to, 'LLL dd, y')}
                      </>
                      )
                    : (
                        format(date.from, 'LLL dd, y')
                      )
                )
              : (
                <span>Pick a date</span>
                )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className='w-auto p-0' align='start'>
          {/* <Calendar
            mode='range'
            selected={date}
            onSelect={handleCustomDateChange}
            initialFocus
          /> */}
        </PopoverContent>
      </Popover>
    </div>
  )
}

DatePickerWithPresets.propTypes = {
  date: PropTypes.shape({
    from: PropTypes.instanceOf(Date),
    to: PropTypes.instanceOf(Date)
  }),
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  range: PropTypes.string
}

DatePickerWithPresets.defaultProps = {
  date: null,
  className: ''
}
