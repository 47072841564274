import { format, startOfMonth } from 'date-fns'
import { endpoints } from './endpoints'
import http from './http'

async function getAnalyticsData (endpoint) {
  const payload = {
    metrics: ['bandwidth_consumption'],
    group_by: 'monthly',
    date_range: {
      start_at: format(startOfMonth(new Date()), 'yyyy-MM-dd'),
      end_at: format(new Date(), 'yyyy-MM-dd')
    }
  }

  const data = await http({}).post(endpoint, payload)
  return data.bandwidth_consumption && data.bandwidth_consumption.length > 0
    ? data.bandwidth_consumption.reduce((prev, cur) => prev + cur.units, 0)
    : 0
}

export async function getImageAnalyticsData () {
  return getAnalyticsData(endpoints.image.analytics)
}

export async function getVideoAnalyticsData () {
  return getAnalyticsData(endpoints.video.analytics)
}
