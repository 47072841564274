import { Button } from 'components/ui/button'

export default function Suggestion () {
  // add utm parameters to the url to tracking
  const utlString = `https://feedback.gumlet.com/feature-requests?utm_source=react-webapp&utm_content=feedback`
  return (
    <div className='flex gap-8 flex-col'>
      <div className='bg-card text-card-foreground flex flex-col gap-6 rounded-lg border p-6'>
        <div className='flex flex-col space-y-1.5'>
          <div className='font-semibold leading-none tracking-tight'>
            Have Feedback?
          </div>
          <div className='text-sm text-muted-foreground mt-2'>
            We’d love to hear your thoughts! Click below to share feedback or suggestions with our team at{' '}
            <a href='mailto:support@gumlet.com' className='underline text-foreground'>support@gumlet.com</a>.
          </div>
          <Button className='cursor-pointer mt-4 w-fit' href={utlString} target='_blank' rel='noopener noreferrer'>Give Feedback</Button>
        </div>
      </div>
    </div>
  )
}
