import { use, useState } from 'react'
import ContentSection from '@/components/shared-sections/content-section'
import ApiKeyTable from './apikey-table'
import { endpoints } from '@/utils/endpoints'
import useQuery from '@/utils/useQuery'
import { DataContext } from '@/contexts/DataProvider'
import { Button } from '@/components/ui/button'
import http from '@/utils/http'
import { toast } from 'sonner'
import AddFormDialog from './add-form-dialog'
import CopyKeyDialog from './copy-key-dialog'
import DeleteActionDialog from '../../../components/delete-action-dialog'
import { IMAGE_SOURCES_PAGINATION_LIMIT, VIDEO_SOURCES_PAGINATION_LIMIT } from '@/utils/constants'
import { SkeletonBasics } from '@/components/shimmers/basic'
import { Plus } from 'lucide-react'

export default function ApiKey () {
  const { userData } = use(DataContext)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [copyKeyModal, setCopyKeyModal] = useState(null)
  const [isDeleteOpen, setIsDeleteOpen] = useState(null)

  const { data: allImageSources, isLoading: isImageSourcesLoading } = useQuery({
    url: `${endpoints.image.sources.base + IMAGE_SOURCES_PAGINATION_LIMIT}`
  })

  const { data: allVideoSources, isLoading: isVideoSourcesLoading } = useQuery({
    url: `${endpoints.video.sources.base + VIDEO_SOURCES_PAGINATION_LIMIT}`
  })

  const { data: allUserTokens, setData, refetch, isLoading: isUserTokensLoading } = useQuery({
    url: `${endpoints.userToken.apikey.base}`
  })

  if (isImageSourcesLoading || isVideoSourcesLoading || isUserTokensLoading) return <SkeletonBasics />

  const userTokens = allUserTokens?.token_list?.map((token) => {
    return {
      ...token,
      sourceAccess: token.source_access?.map((source_id) => {
        const source = allImageSources?.all_sources?.find((src) => src.id === source_id)
        return source ? source.namespace : null
      }).filter(Boolean),
      videoSourceAccess: token.video_source_access?.map((source_id) => {
        const source = allVideoSources?.all_sources?.find((src) => src.id === source_id)
        return source ? source.name : null
      }).filter(Boolean)
    }
  }) || []

  async function _createUserToken (data) {
    for (const { key, message, condition } of [
      { key: 'roles', message: 'Select one or more roles' },
      {
        key: 'sources',
        message: 'Select one or more image sources',
        condition: data.roles.some(r => r.value === 'source-specific') && !data.sources?.length
      },
      {
        key: 'video_sources',
        message: 'Select one or more video sources',
        condition: data.roles.some(r => r.value === 'video-source-specific') && !data.video_sources?.length
      }
    ]) {
      if ((key !== 'sources' && key !== 'video_sources' && !data[key]?.length) || condition) {
        return toast.error(message)
      }
    }

    const payload = {
      name: data.name,
      roles: data.roles.map(role => role.value)
    }
    const sources = data.sources.filter(source => source.value).map(source => source.value)
    const video_sources = data.video_sources.filter(video => video.value).map(video => video.value)

    if (sources.length > 0) {
      payload.sources = sources
    }

    if (video_sources.length > 0) {
      payload.video_sources = video_sources
    }
    try {
      const response = await http({}).post(endpoints.userToken.apikey.add, payload)
      toast.success('API Key created successfully')
      refetch()
      setIsDialogOpen(false)
      setCopyKeyModal(response)
    } catch (error) {
      toast.error(error?.error?.message)
    }
  }

  const _deleteUserToken = async (id) => {
    try {
      const response = await http({}).delete(endpoints.userToken.apikey.delete.replace(':token_id', id))
      if (response.success) {
        setData((prevTokens) => ({
          ...prevTokens,
          token_list: prevTokens.token_list.filter((item) => item.id !== id)
        }))
        toast.success('API Key deleted successfully')
        setIsDeleteOpen(null)
      }
    } catch (error) {
      toast.error(error?.message)
    }
  }
  const actionComponent = (<Button variant='white' className='cursor-pointer' onClick={() => setIsDialogOpen(true)}><Plus className='h-4 w-4' />Create New API Key</Button>)

  return (
    <ContentSection
      title='API Keys'
      className='max-w-none'
      has_action={actionComponent}
    >
      <ApiKeyTable userTokens={userTokens} userData={userData} _deleteUserToken={_deleteUserToken} setIsDialogOpen={setIsDialogOpen} setIsDeleteOpen={setIsDeleteOpen} />
      <AddFormDialog isDialogOpen={isDialogOpen} setIsDialogOpen={setIsDialogOpen} allImageSources={allImageSources?.all_sources} allVideoSources={allVideoSources?.all_sources} _createUserToken={_createUserToken} />
      {
        copyKeyModal && <CopyKeyDialog copyKeyModal={copyKeyModal} setCopyKeyModal={setCopyKeyModal} />
      }
      {
        isDeleteOpen && <DeleteActionDialog isDeleteOpen={isDeleteOpen} setIsDeleteOpen={setIsDeleteOpen} onSubmit={_deleteUserToken} title='The API Key will be deleted. Are you sure you want to continue?' />
      }
    </ContentSection>
  )
}
