import { useState } from "react"
import { useForm } from 'react-hook-form'
import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import PropTypes from 'prop-types'
import { convertEpochToTimezone } from '@/utils/helper'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'components/ui/select'
import { TIMEZONES } from '@/utils/constants'
import http from '@/utils/http'
import { endpoints } from '@/utils/endpoints'
import { toast } from 'sonner'
import { LoadingSpinner } from '@/components/ui/spinner'
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
} from "@/components/ui/popover"
import {
  Command,
  CommandInput,
  CommandList,
  CommandEmpty,
  CommandGroup,
  CommandItem,
} from "@/components/ui/command"
import { ChevronsUpDown, Check } from "lucide-react"
import { cn } from "@/utils/helper"

export default function ProfileForm ({ userData, setUserData }) {
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      name: userData.name || '',
      email: userData.email || '',
      creation_date: convertEpochToTimezone(userData.creation_date, userData.timezone) || '',
      status: userData.unpaid ? 'Inactive' : 'Active',
      roles: userData.roles.join(', ') || '',
      timezone: userData.timezone || ''
    }
  })

  async function onSubmit (data) {
    const payload = {
      name: data.name,
      timezone: data.timezone
    }

    try {
      const response = await http({}).post(endpoints.user, payload)
      setUserData(response)
      toast.success('Profile updated successfully')
    } catch (error) {
      toast.error(error?.error?.message || 'Failed to update profile')
    }
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className='space-y-8'>
        <div className='grid grid-cols-1 gap-6 md:grid-cols-2'>
          <FormField
            control={form.control}
            name='name'
            render={({ field }) => (
              <FormItem>
                <FormLabel>Username</FormLabel>
                <FormControl>
                  <Input placeholder='name' {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name='email'
            render={({ field }) => (
              <FormItem>
                <FormLabel>Email</FormLabel>
                <FormControl>
                  <Input placeholder='email' {...field} disabled />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name='creation_date'
            render={({ field }) => (
              <FormItem>
                <FormLabel>Account Created</FormLabel>
                <FormControl>
                  <Input placeholder='account created' {...field} disabled />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name='status'
            render={({ field }) => (
              <FormItem>
                <FormLabel>Account Status</FormLabel>
                <FormControl>
                  <Input placeholder='status' {...field} disabled />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name='roles'
            render={({ field }) => (
              <FormItem>
                <FormLabel>Roles</FormLabel>
                <FormControl>
                  <Input placeholder='roles' {...field} disabled />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="timezone"
            render={({ field }) => {
              const [open, setOpen] = useState(false)

              const selectedTimezone = TIMEZONES.find(
                (zone) => zone.value === field.value
              )

              return (
                <FormItem className="flex flex-col">
                  <FormLabel>Timezone</FormLabel>
                  <Popover open={open} onOpenChange={setOpen}>
                    <PopoverTrigger asChild>
                      <Button
                        variant="outline"
                        role="combobox"
                        aria-expanded={open}
                        className="w-[270px] justify-between"
                      >
                        <span className='truncate text-left'>
                          {selectedTimezone
                            ? selectedTimezone.label
                            : "Select timezone..."}
                        </span>
                        <ChevronsUpDown className="ml-2 h-4 w-4 opacity-50 shrink-0" />
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent className="w-[270px] p-0 scroll-auto">
                      <Command>
                        <CommandInput placeholder="Search timezone..." className="h-9" />
                        <CommandList>
                          <CommandEmpty>No timezone found.</CommandEmpty>
                          <CommandGroup>
                            {TIMEZONES.map((zone) => (
                              <CommandItem
                                key={zone.value}
                                value={zone.value}
                                onSelect={(currentValue) => {
                                  if (currentValue !== field.value) {
                                    field.onChange(currentValue)
                                  }
                                  setOpen(false)
                                }}
                                className={cn(
                                  'text-sm text-muted-foreground'
                                )}
                              >
                                {zone.label}
                                <Check
                                  className={cn(
                                    "ml-auto h-4 w-4",
                                    field.value === zone.value
                                      ? "opacity-100"
                                      : "opacity-0"
                                  )}
                                />
                              </CommandItem>
                            ))}
                          </CommandGroup>
                        </CommandList>
                      </Command>
                    </PopoverContent>
                  </Popover>
                  <FormMessage />
                </FormItem>
              )
            }}
          />
        </div>
        <div className='flex justify-start'>
          <Button type='submit' className='cursor-pointer' disabled={!form.formState.isDirty || form.formState.isSubmitting}>{form.formState.isSubmitting && <LoadingSpinner />}Update Profile</Button>
        </div>
      </form>
    </Form>
  )
}

ProfileForm.propTypes = {
  setUserData: PropTypes.func.isRequired,
  userData: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    creation_date: PropTypes.date,
    timezone: PropTypes.string,
    unpaid: PropTypes.bool,
    roles: PropTypes.arrayOf(PropTypes.string)
  }).isRequired
}
