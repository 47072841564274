import PropTypes from 'prop-types'
import {
  flexRender,
  getCoreRowModel,
  useReactTable
} from '@tanstack/react-table'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from '@/components/ui/table'
import { convertEpochToTimezone } from '@/utils/helper'
import { Button } from '@/components/ui/button'
import { Badge } from '@/components/ui/badge'
import { Card } from '@/components/ui/card'

export default function SessionTable ({ sessions, userData, currentSession, setIsDeleteOpen }) {
  const columns = [
    {
      id: 'browser',
      accessorKey: 'browser',
      header: 'Browser',
      cell: ({ getValue }) => getValue() || '-'
    },
    {
      id: 'os',
      accessorKey: 'os',
      header: 'OS',
      cell: ({ getValue }) => getValue() || '-'
    },
    {
      id: 'login_time',
      accessorKey: 'login_time',
      header: 'Login Time',
      cell: ({ getValue }) =>
        getValue()
          ? convertEpochToTimezone(getValue(), userData.timezone)
          : '-'
    },
    {
      id: 'last_used',
      accessorKey: 'last_used',
      header: 'Last Used',
      cell: ({ getValue }) =>
        getValue()
          ? convertEpochToTimezone(getValue(), userData.timezone)
          : '-'
    },
    {
      id: 'actions',
      accessorKey: 'actions',
      header: 'Actions',
      cell: ({ row }) =>
        currentSession === row.original.id
          ? (
            <div className='flex space-x-2'>
              <Badge variant='success' className='px-2 py-0.5'>
                Running
              </Badge>
            </div>
            )
          : (
            <Button
              onClick={() => setIsDeleteOpen(row.original)}
              className='cursor-pointer'
              variant='delete'
              size='xs'
            >
              Delete
            </Button>
            )
    }
  ]
  const table = useReactTable({
    data: sessions.reverse(),
    columns,
    getCoreRowModel: getCoreRowModel()
  })
  return (
    <div className='space-y-4 w-full'>
      <Card className='rounded-md border shadow-none'>
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id} className='group/row'>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead
                      key={header.id}
                      colSpan={header.colSpan}
                      className={header.column.columnDef.meta?.className ?? ''}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                    </TableHead>
                  )
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length
              ? (
                  table.getRowModel().rows.map((row) => (
                    <TableRow
                      key={row.id}
                      data-state={row.getIsSelected() && 'selected'}
                      className='group/row'
                    >
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <TableCell
                            key={cell.id}
                            className={cell.column.columnDef.meta?.className ?? ''}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            ) || '-'}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  ))
                )
              : (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    className='h-24 text-center'
                  >
                    No results.
                  </TableCell>
                </TableRow>
                )}
          </TableBody>
        </Table>
      </Card>
    </div>
  )
}

SessionTable.propTypes = {
  sessions: PropTypes.array.isRequired,
  userData: PropTypes.shape({ timezone: PropTypes.string }).isRequired,
  currentSession: PropTypes.string.isRequired,
  setIsDeleteOpen: PropTypes.func.isRequired
}
