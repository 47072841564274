import { Button } from '@/components/ui/button'
import { openIntercom } from '@/utils/helper'
import { Input } from '@/components/ui/input'
import { Form, FormControl, FormField, FormItem } from '@/components/ui/form'
import { useForm } from 'react-hook-form'

export default function Help () {
    const form = useForm({ mode: 'onChange', defaultValues: { search: '' } })
    const searchQuery = form.watch('search')
  
    async function onSubmit (data) {
      const searchQuery = encodeURIComponent(data.search)
      const searchUrl = `https://community.gumlet.com/search?q=${searchQuery}`
      window.open(searchUrl, '_blank')
    }
  return (
    <div className='flex flex-col gap-8'>
      <div className='rounded-xl border bg-card text-card-foreground w-full max-w-6xl mx-auto h-48 shadow-none'>
        <div className='flex flex-col space-y-1.5 p-6'>
          <div className='font-semibold leading-none tracking-tight'>
            Community Help
          </div>
          <div className='text-sm text-muted-foreground mt-2'>
            If you have any issues related to setting up media delivery, source creation, video security etc, then the community is the best place to start. Our team actively answers user queries.
          </div>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className='mt-4 flex flex-row gap-2'>
              <FormField
                control={form.control}
                name='search'
                render={({ field }) => (
                  <FormItem className='w-full max-w-80'>
                    <FormControl>
                      <Input placeholder='Search Community' {...field} required />
                    </FormControl>
                  </FormItem>
                )}
              />
              <Button type='submit' className='cursor-pointer' disabled={!searchQuery.trim()}>Search</Button>
            </form>
          </Form>
        </div>
      </div>
      <div className='flex gap-8 flex-col'>
        <div className='bg-card text-card-foreground flex flex-col gap-6 rounded-lg border p-6'>
          <div className='flex flex-col space-y-1.5'>
            <div className='font-semibold leading-none tracking-tight'>
              Need a Human?
            </div>
            <div className='text-sm text-muted-foreground mt-2'>
              You can start a conversation by clicking on this button. If you prefer the old-school way, you can drop us an email at {' '}
              <a href='mailto:support@gumlet.com' className='underline text-foreground'>support@gumlet.com</a>.
            </div>
            <Button type='submit' className='cursor-pointer mt-4 max-w-24' onClick={openIntercom}>Start Chat</Button>
          </div>
        </div>
      </div>
    </div>
  )
}
