import ContentSection from '@/components/shared-sections/content-section'
import TwoFactorForm from './two-factor-form'
import { DataContext } from '@/contexts/DataProvider'
import { use } from 'react'

export default function TwoFactor () {
  const { userData } = use(DataContext)
  return (
    <ContentSection
      title='Two-Factor Authentication'
      className='max-w-xl'
    >
      <TwoFactorForm
        userData={userData}
      />
    </ContentSection>
  )
}
