import { Button } from '@/components/ui/button'
import { Form, FormControl, FormField, FormItem, FormLabel } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { PasswordInput } from '@/components/ui/password-input'
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@/components/ui/card'
import { LoadingSpinner } from '@/components/ui/spinner'
import { Trash2 } from 'lucide-react'
import { useForm } from 'react-hook-form'
import { isEmpty } from '@/utils/helper'
import PropTypes from 'prop-types'
import { toast } from 'sonner'
import { endpoints } from '@/utils/endpoints'
import http from '@/utils/http'
import { CopyButton } from '@/components/ui/copy-button'

const LabelValueRow = ({ label, value, isPassword }) => {
  return (
    <div className='flex gap-4 text-sm items-center'>
      <span className='font-medium min-w-[220px]'>{label}</span>
      {value
        ? isPassword
          ? <PasswordInput value={value} disabled className='max-w-96 w-full' />
          : (
            <span className='flex gap-2 items-center text-muted-foreground break-all'>
              {value}
              <CopyButton data={value} message={`${label} copied to clipboard`} tooltip={`Copy ${label}`} />
            </span>
            )
        : <span>-</span>}
    </div>
  )
}

export default function FairPlayForm ({ fairplayData, setIsDeleteOpen }) {
  const form = useForm({
    defaultValues: {
      fairplay_cer: null,
      private_key_pem: null,
      application_service_key: '',
      private_key_passphrase: ''
    }
  })
  const fileCertRef = form.register('fairplay_cer', { required: 'Fairplay Certificate is required' })
  const filePemRef = form.register('private_key_pem', { required: 'Private Key is required' })

  async function _generateFairplayCredential (data) {
    const files = [
      { file: data?.fairplay_cer?.[0], name: 'Certificate' },
      { file: data?.private_key_pem?.[0], name: 'Private Key' }
    ]

    for (const { file, name } of files) {
      if (!file) {
        toast.error(`${name} is required.`)
        return
      }
      if (file.size > 1024 * 1024) {
        toast.error(`${name} file size must be 1 MB or less.`)
        return
      }
    }
    const payload = new FormData()
    payload.append('fairplay_cer', data?.fairplay_cer[0])
    payload.append('private_key_pem', data?.private_key_pem[0])
    payload.append('application_service_key', data?.application_service_key)
    payload.append('private_key_passphrase', data?.private_key_passphrase)
    try {
      await http({ headerType: 'multipart' }).post(endpoints.organization.drm.fairplay.base, payload)
      toast.success('Fairplay credentials generated successfully')
    } catch (error) {
      toast.error(error?.error?.message)
    }
  }

  return (
    <Card className='rounded-lg border shadow-none'>
      <CardHeader className='flex justify-between flex-row'>
        <CardTitle className='text-base'>Fairplay Setup</CardTitle>
        <a
          href='https://docs.gumlet.com/docs/fairplay-drm'
          target='_blank'
          rel='noreferrer'
          className='text-sm underline text-muted-foreground'
        >
          How to get fairplay credentials?
        </a>
      </CardHeader>

      <CardContent className='space-y-6'>
        {isEmpty(fairplayData)
          ? (
            <Form {...form}>
              <form onSubmit={form.handleSubmit(_generateFairplayCredential)} className='space-y-6 max-w-none'>
                <div className='grid grid-cols-1 gap-8 md:grid-cols-2'>
                  {[
                    { name: 'fairplay_cer', label: 'Apple Fairplay Certificate (.cer)', type: 'file', ref: fileCertRef, accept: '.cer' },
                    { name: 'private_key_pem', label: 'Private Key (.pem)', type: 'file', ref: filePemRef, accept: '.pem' },
                    { name: 'application_service_key', label: 'Application Secret Key (ASK)', type: 'password', placeholder: 'Enter your application secret key' },
                    { name: 'private_key_passphrase', label: 'Private Key Passphrase', type: 'password', placeholder: 'Enter your private key passphrase' }
                  ].map(({ name, label, type, ref, accept, placeholder }) => (
                    <FormField
                      key={name}
                      control={form.control}
                      name={name}
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>{label}</FormLabel>
                          <FormControl>
                            {type === 'file' ? <Input type='file' {...ref} accept={accept} /> : <PasswordInput {...field} placeholder={placeholder} required />}
                          </FormControl>
                        </FormItem>
                      )}
                    />
                  ))}
                </div>
                <div className='flex justify-end'>
                  <Button type='submit' disabled={form.formState.isSubmitting} className='cursor-pointer'>
                    {form.formState.isSubmitting && <LoadingSpinner />} Save Changes
                  </Button>
                </div>
              </form>
            </Form>
            )
          : (
            <>
              <LabelValueRow label='License URL :' value={fairplayData.licence_url} />
              <LabelValueRow label='Certificate URL :' value={fairplayData.certificate_url} />
              <LabelValueRow label='Application Secret Key (ASK) :' value={fairplayData.application_service_key || ''} isPassword />
              <LabelValueRow label='Private Key Passphrase :' value={fairplayData.private_key_passphrase || ''} isPassword />
              <CardFooter className='flex justify-end p-0'>
                <Button variant='destructive' className='cursor-pointer text-sm' onClick={() => setIsDeleteOpen(true)}>
                  <Trash2 className='h-3 w-3' /> Delete Credentials
                </Button>
              </CardFooter>
            </>
            )}
      </CardContent>
    </Card>
  )
}

FairPlayForm.propTypes = {
  fairplayData: PropTypes.shape({
    licence_url: PropTypes.string,
    certificate_url: PropTypes.string,
    application_service_key: PropTypes.string,
    private_key_passphrase: PropTypes.string
  }),
  setIsDeleteOpen: PropTypes.func
}

LabelValueRow.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  isPassword: PropTypes.bool
}
