import PropTypes from 'prop-types'
import { Button } from '@/components/ui/button'
import { Link } from 'react-router'
import { Separator } from '@/components/ui/separator'
import { Card } from '@/components/ui/card'
import { SquarePen } from 'lucide-react'

export default function WebhookTable ({ webhooks, setIsDeleteOpen }) {
  return (
    <Card className='rounded-lg border shadow-none'>
      {
            webhooks.all_webhooks.length === 0
              ? (
                <div className='flex flex-col items-center justify-center h-24 text-center'>
                  <p className='text-sm'>No webhooks found</p>
                </div>
                )
              : webhooks.all_webhooks.map((webhook, i) => {
                const characterLimit = 70
                const limitedText = webhook.url.length > characterLimit ? webhook.url.slice(0, characterLimit) + '...' : webhook.url
                return (
                  <>
                    <div className='space-y-2 flex flex-row items-center justify-between p-4' key={i}>
                      <div className='mb-0'>
                        <label className='font-medium peer-disabled:cursor-not-allowed peer-disabled:opacity-70 text-base'>
                          <Link to={`/organization/webhooks/edit/${webhook.id}`}>{limitedText}</Link>
                        </label>
                        <ul className='text-[0.8rem] text-muted-foreground flex gap-1.5 mt-1 items-center'>
                          <li className='list-inline-item'>
                            <small>Source: </small>
                            <small>{webhook && webhook.namespace.toString()}</small>
                          </li>
                          <Separator orientation='vertical' className='h-3' />
                          <li className='list-inline-item fs-6'>
                            <small>Token: </small>
                            {webhook.secret_token
                              ? (
                                <small>{webhook.secret_token}</small>
                                )
                              : (
                                <small> None</small>
                                )}
                          </li>
                        </ul>
                      </div>
                      <div className='flex flex-row items-center space-x-2'>
                        <Link to={`/organization/webhooks/edit/${webhook.id}`}><Button className='cursor-pointer' variant='outline' size='sm'><SquarePen />Edit</Button></Link>
                        <Button className='cursor-pointer' variant='destructive' size='sm' onClick={() => setIsDeleteOpen(webhook)}>Delete</Button>
                      </div>
                    </div>
                    {i < webhooks.all_webhooks.length - 1 && <Separator className='my-1 flex-none' />}
                  </>
                )
              })
        }
    </Card>
  )
}

WebhookTable.propTypes = {
  webhooks: PropTypes.array,
  setIsDeleteOpen: PropTypes.func
}
