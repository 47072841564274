import { useLocation  } from 'react-router'
import { SectionHeader } from '@/components/ui-layout/section-header'
import { Separator } from '@/components/ui/separator'
import { Header } from '@/components/ui-layout/header'
import {
  BreadcrumbComponents
} from '@/components/ui/breadcrumb'
import {
  MessagesSquare,
  MessageSquareQuote,
  FilePenLine
} from 'lucide-react'
import SidebarNav from '@/components/sidebar-nav'
import { Outlet } from 'react-router'
import { RoleBasedRouteChildren } from '@/utils/roleBasedRoute'
import { SkeletonBasics } from '@/components/shimmers/basic'
import Unauthorized from '@/app/401'
import  { getBreadcrumbItems } from '@/utils/helper'

export default function Support () {
  const location = useLocation()
  const breadcrumbItem = getBreadcrumbItems(location.pathname)
  const { isAllowed, loading } = RoleBasedRouteChildren()
  return (
    <>
      <Header>
        <BreadcrumbComponents
          Breadcrum1='Help'
          Breadcrum2={breadcrumbItem}
        />
      </Header>

      <SectionHeader fixed>
        <div className='space-y-0.5'>
          <h1 className='text-2xl font-medium md:text-3xl'>
            Need help?
          </h1>
        </div>
        <Separator className='my-4 lg:my-6' />
        <div className='flex flex-1 flex-col space-y-2 overflow-hidden md:space-y-2 lg:flex-row lg:space-x-12 lg:space-y-0'>
          <aside className='top-0 lg:sticky lg:w-1/5'>
            <SidebarNav items={sidebarNavItems} />
          </aside>

          <div className='flex w-full overflow-y-hidden p-1 pr-4'>
            {loading ? <SkeletonBasics /> : isAllowed ? <Outlet /> : <Unauthorized />}
          </div>
        </div>
      </SectionHeader>
    </>
  )
}

const sidebarNavItems = [
  {
    title: 'Support',
    icon: <MessagesSquare size={18} />,
    href: '/help/support'
  },
  {
    title: 'Feedback',
    icon: <FilePenLine size={18} />,
    href: '/help/feedback'
  },
  {
    title: 'Review',
    icon: <MessageSquareQuote size={18} />,
    href: '/help/review'
  }
]
