import UserProfile from '@/app/user'
import Profile from '@/app/user/profile'
import Password from '@/app/user/password'
import Summary from '@/app/user/summary'
import TwoFactor from '@/app/user/two-factor'
import ApiKey from '@/app/user/apikey'
import Sessions from '@/app/user/sessions'
import Organization from '@/app/organization'
import Coupons from '@/app/organization/coupons'
import Webhooks from '@/app/organization/webhooks'
import AddWebhook from '@/app/organization/webhooks/add-webhook'
import EditWebhook from '@/app/organization/webhooks/edit-webhook'
import Accounts from '@/app/organization/accounts'
import Billing from '@/app/billing'
import PlanDetails from '@/app/billing/plans'
import Invoices from '@/app/billing/invoices'
import PaymentMethods from '@/app/billing/payment-methods'
import BillingAlerts from '@/app/billing/alerts'
import DRMCredentials from '@/app/organization/drm-credentials'
import Unauthorized from '@/app/401'
import { Navigate } from 'react-router'
import BillingDetailsForm from '@/app/billing/details'
import PlanChange from '@/app/plan-change'
import { routePaths } from '@/utils/routePaths'
import Collections from '@/app/live-stream/collections'
import Manage from '@/app/live-stream/assets/manage'
import LiveStreamDetail from '@/app/live-stream/assets/detail'
import UsageAnalytics from '@/app/live-stream/assets/usage-analytics'
import Support from '@/app/support'
import Help from 'app/support/help'
import Reviews from '@/app/support/review'
import Suggestion from '@/app/support/suggestion'

export const routesConfig = [
  {
    path: routePaths.help,
    element: <Support />,
    exact: true,
    children: [
      {
        path: '',
        element: <Navigate to='support' replace />
      },
      {
        path: 'support',
        element: <Help />
      },
      {
        path: 'review',
        element: <Reviews />
      },
      {
        path: 'feedback',
        element: <Suggestion />
      }
    ]
  },
  {
    // live stream
    path: routePaths.stream.sources.allsources,
    element: <Collections />,
    exact: true
  },
  {
    // live stream
    path: routePaths.stream.assets.base,
    element: <Manage />,
    exact: true
  },
  {
    path: routePaths.stream.assets.info,
    element: <LiveStreamDetail />,
    exact: true
  },
  {
    path: routePaths.stream.analytics,
    element: <UsageAnalytics />,
    exact: true
  },
  {
    path: routePaths.planChange,
    element: <PlanChange />,
    exact: true
  },
  {
    path: '/user',
    element: <UserProfile />,
    exact: true,
    children: [
      {
        path: '',
        element: <Navigate to='profile' replace />
      },
      {
        path: 'profile',
        element: <Profile />
      },
      {
        path: 'password',
        element: <Password />
      },
      {
        path: 'two-factor',
        element: <TwoFactor />
      },
      {
        path: 'apikey',
        element: <ApiKey />
      },
      {
        path: 'sessions',
        element: <Sessions />
      },
      {
        path: 'summary',
        element: <Summary />
      }
    ]
  },
  {
    path: '/organization',
    element: <Organization />,
    exact: true,
    children: [
      {
        path: '',
        element: <Navigate to='accounts' replace />
      },
      {
        path: 'accounts',
        element: <Accounts />
      },
      {
        path: 'webhooks',
        element: <Webhooks />
      },
      {
        path: '/organization/webhooks/add',
        element: <AddWebhook />
      },
      {
        path: '/organization/webhooks/edit/:id',
        element: <EditWebhook />
      },
      {
        path: 'drm-credentials',
        element: <DRMCredentials />
      },
      {
        path: 'coupons',
        element: <Coupons />
      }
    ]
  },
  {
    path: '/organization',
    element: <Billing />,
    exact: true,
    children: [
      {
        path: '',
        element: <Navigate to='billing' replace />
      },
      {
        path: 'billing',
        element: <PlanDetails />
      },
      {
        path: 'invoices',
        element: <Invoices />
      },
      {
        path: 'payment-methods',
        element: <PaymentMethods />
      },
      {
        path: 'alerts',
        element: <BillingAlerts />
      },
      {
        path: 'changebilling',
        element: <BillingDetailsForm />
      }
    ]
  },
  {
    path: '*',
    element: <div>Page Not Found</div>,
    exact: true
  },
  {
    path: '/unauthorized',
    element: <Unauthorized from='routes' />
  }
]
