import { DataContext } from '@/contexts/DataProvider'
import ContentSection from '@/components/shared-sections/content-section'
import ProfileForm from './profile-form'
import { use } from 'react'
// import SwitchUser from './switch-user'
// import Cookies from 'js-cookie'
// import { USER_ROLES } from '@/utils/constants'

export default function Profile () {
  const { userData, setUserData } = use(DataContext)

  // const switchedUser = Cookies.get('switched_user')
  // const originalUser = Cookies.get('original_user')

  // const getRoles = userData.roles.some((role) => USER_ROLES.switchUser.includes(role))

  return (
    <ContentSection
      title='Profile'
      className='max-w-xl'
    >
      <div className='flex flex-col gap-6'>
        {/* {switchedUser || getRoles ? <SwitchUser switchedUser={switchedUser} originalUser={originalUser} /> : null} */}
        <ProfileForm userData={userData} setUserData={setUserData} />
      </div>
    </ContentSection>
  )
}
