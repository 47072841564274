import { useForm } from 'react-hook-form'
import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel
} from '@/components/ui/form'
import { Switch } from '@/components/ui/switch'
import PropTypes from 'prop-types'
import { endpoints } from '@/utils/endpoints'
import http from '@/utils/http'
import { toast } from 'sonner'

export function SummaryForm ({ orgData }) {
  const form = useForm({
    defaultValues: {
      monthly_summary: orgData?.monthly_summary || false
    }
  })

  async function onSubmit (data) {
    try {
      await http({}).post(endpoints.monthlySummary, data)
      toast.success('Notifications updated successfully')
    } catch (error) {
      toast.error(error.error.message)
    }
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className='space-y-8'>
        <div className='relative'>
          <div className='space-y-4'>
            <FormField
              control={form.control}
              name='monthly_summary'
              render={({ field }) => (
                <FormItem className='flex flex-row items-center justify-between rounded-lg border p-4'>
                  <div className='space-y-0.5'>
                    <FormLabel className='text-base'>
                      Monthly Usage Summary
                    </FormLabel>
                    <FormDescription>
                      Receive emails about monthly usage activity.
                    </FormDescription>
                  </div>
                  <FormControl>
                    <Switch
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
          </div>
        </div>
        <Button type='submit' className='cursor-pointer' disabled={!form.formState.isDirty}>Update notification</Button>
      </form>
    </Form>
  )
}

SummaryForm.propTypes = {
  orgData: PropTypes.shape({ monthly_summary: PropTypes.bool }).isRequired
}
