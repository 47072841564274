import { cn } from '@/utils/helper'
import { useSearch } from '@/contexts/searchContext'
import { Button } from './ui/button'
import PropTypes from 'prop-types'
import { Sparkles } from 'lucide-react'

export function Search ({
  className = '',
  placeholder = 'Search'
}) {
  const { setOpen } = useSearch()
  return (
    <Button
      variant='outline'
      className={cn(
        'relative h-8 w-full flex-1 justify-start rounded-md bg-muted/25 text-sm font-normal text-muted-foreground shadow-none hover:bg-muted/50 sm:pr-12 md:w-40 md:flex-none lg:w-56 xl:w-64',
        'hover:bg-accent/50',
        className
      )}
      onClick={() => setOpen(true)}
    >
      <Sparkles className='h-4 w-4 shrink-0 opacity-70' />
      <span className='ml-0'>{placeholder}</span>
      <kbd className='pointer-events-none absolute right-[0.3rem] top-[0.3rem] hidden h-5 select-none items-center gap-1 rounded border px-1.5 font-mono text-[10px] font-medium opacity-100 sm:flex bg-accent'>
        <span className='text-xs'>⌘</span>K
      </kbd>
    </Button>
  )
}

Search.prototype = {
  className: PropTypes.string,
  placeholder: PropTypes.string
}
