import { use, useEffect, useState } from 'react'
import { BreadcrumbComponents } from '@/components/ui/breadcrumb'
import { Header } from '@/components/ui-layout/header'
import { SectionHeader } from '@/components/ui-layout/section-header'
import { Separator } from '@/components/ui/separator'
import { endpoints } from '@/utils/endpoints'
import useQuery from '@/utils/useQuery'
import { SkeletonBasics } from '@/components/shimmers/basic'
import { useNavigate } from 'react-router'
import { Button } from '@/components/ui/button'
import { Plus } from 'lucide-react'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'components/ui/select'
import { AssetListTable } from './asset-list-table'
import { DataContext } from '@/contexts/DataProvider'
import NewStreamModal from './new-stream-modal'
import http from '@/utils/http'
import { toast } from 'sonner'
import { routePaths } from '@/utils/routePaths'

export default function Manage () {
  const navigate = useNavigate()
  const { userData } = use(DataContext)
  const [isDialogOpen, setIsDialogOpen] = useState(null)

  const sourceId = new URLSearchParams(location.search).get('source_id')

  const { data: allCollectionApiResponse, isLoading } = useQuery({
    url: `${endpoints.stream.sources.base}`
  })

  const { data: assetListApiResponse, setData: setAssetListApiResponse, isLoading: isAssetListLoading } = useQuery({
    url: `${endpoints.stream.asset.getLiveAssetList.replace(':collection_id', sourceId)}`,
    hasCondition: !sourceId
  })

  useEffect(() => {
    // Don't proceed if still loading collections
    if (isLoading) return
    const hasLiveSources = allCollectionApiResponse?.all_live_sources?.length > 0
    const urlParams = new URLSearchParams(location.search)
    if (hasLiveSources) {
      // Get first source as default
      const defaultSourceId = allCollectionApiResponse.all_live_sources[0]._id
      // Handle source_id in URL
      const needsDefaultSource = !urlParams.has('source_id') ||
        !allCollectionApiResponse.all_live_sources.some(source => source._id === sourceId)
      if (needsDefaultSource) {
        urlParams.set('source_id', defaultSourceId)
        navigate(`${location.pathname}?${urlParams.toString()}`, { replace: false })
      }
    } else {
      // Redirect if no sources exist
      toast.info('No live sources found, please create a source first')
      navigate(routePaths.stream.sources.allsources)
    }
  }, [allCollectionApiResponse, navigate, sourceId, isLoading])

  if (isLoading || isAssetListLoading) return <SkeletonBasics />

  async function _createStream (data) {
    if (!data.resolution.length) return toast.error('Please select at least one resolution')

    const payload = {
      ...data,
      live_source_id: sourceId,
      resolution: data.resolution.join(',')
    }
    try {
      const response = await http({}).post(endpoints.stream.asset.base, payload)
      if (response.live_asset_id) {
        navigate(routePaths.stream.assets.info.replace(':assetID', response.live_asset_id))
      }
      toast.success('Stream created successfully')
    } catch (error) {
      toast.error(error.error.message)
    }
  }

  async function _updateStreamTitle (data) {
    const payload = {
      title: data.title,
      live_asset_id: isDialogOpen.live_asset_id
    }
    try {
      const response = await http({}).post(endpoints.stream.asset.update, payload)
      setAssetListApiResponse((prevState) => ({
        ...prevState,
        all_live_assets: prevState.all_live_assets.map(asset =>
          asset.live_asset_id === isDialogOpen.live_asset_id
            ? { ...asset, input: { ...asset.input, title: response.input.title } }
            : asset
        )
      }))
      toast.success('Stream title updated successfully')
    } catch (error) {
      toast.error(error.error.message)
    } finally {
      setIsDialogOpen(null)
    }
  }

  return (
    <>
      <Header>
        <BreadcrumbComponents
          Breadcrum1='Live Stream'
          Breadcrum1Link='/dashboard/video'
          Breadcrum3='All Streams'
        />
      </Header>
      <SectionHeader fixed>
        <div className='flex items-end justify-between sm:my-0 sm:items-center'>
          <h1 className='text-2xl font-medium md:text-3xl'>
            Live Streams
          </h1>
          <div className='flex flex-col gap-4 sm:flex-row'>
            <Select
              value={sourceId} onValueChange={(value) => {
                const urlParams = new URLSearchParams(location.search)
                urlParams.set('source_id', value)
                navigate(`${location.pathname}?${urlParams.toString()}`, { replace: false })
              }}
            >
              <SelectTrigger className='SelectTrigger w-36'>
                <SelectValue placeholder='Select collection' />
              </SelectTrigger>
              <SelectContent>
                {allCollectionApiResponse.all_live_sources.map((source, i) => (
                  <SelectItem key={i} value={source._id}>{source.name}</SelectItem>
                ))}
              </SelectContent>
            </Select>
            <Button className='cursor-pointer' onClick={() => setIsDialogOpen(true)}><Plus />Create a New Stream</Button>
          </div>
        </div>
        <Separator className='my-4 lg:my-6' />
        {assetListApiResponse && <AssetListTable data={assetListApiResponse} userData={userData} setIsDialogOpen={setIsDialogOpen} />}
        {isDialogOpen && <NewStreamModal isDialogOpen={isDialogOpen} setIsDialogOpen={setIsDialogOpen} _createStream={_createStream} _updateStreamTitle={_updateStreamTitle} />}
      </SectionHeader>
    </>
  )
}
