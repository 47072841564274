import Layout from './layout'
import { Route, Routes } from 'react-router'
import ProtectedRoute from './protectedRoute'
import { enhanceRoutesWithRBAC } from '@/utils/enhanceRoutes'
import { routesConfig } from './routesConfig'

const enhancedRoutes = enhanceRoutesWithRBAC(routesConfig)

export default function App () {
  return (
    <Routes>
      <Route path='/' element={<ProtectedRoute element={<Layout />} />}>
        {enhancedRoutes.map((route, index) => (
          route.children
            ? (
              <Route key={index} path={route.path} element={route.element}>
                {route.children.map((child, childIndex) => (
                  <Route key={childIndex} path={child.path} element={child.element} />
                ))}
              </Route>
              )
            : (
              <Route key={index} path={route.path} element={route.element} />
              )
        ))}
      </Route>
    </Routes>
  )
}
