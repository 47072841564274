import * as React from 'react'
import { cva } from 'class-variance-authority'

import { cn } from '@/utils/helper'

const alertVariants = cva(
  'relative w-full rounded-lg border px-4 py-3 text-sm grid has-[>svg]:grid-cols-[calc(var(--spacing)*4)_1fr] grid-cols-[0_1fr] has-[>svg]:gap-x-3 gap-y-0.5 items-start [&>svg]:size-4 [&>svg]:translate-y-0.5 [&>svg]:text-current place-content-center',
  {
    variants: {
      variant: {
        default: 'bg-background text-foreground',
        destructive: 'text-destructive-foreground [&>svg]:text-current *:data-[slot=alert-description]:text-destructive-foreground/80',
        success: 'text-success/15 [&>svg]:text-current *:data-[slot=alert-description]:text-success',
        warning: 'bg-warning/15 [&>svg]:text-current *:data-[slot=alert-description]:text-warning'
      }
    },
    defaultVariants: {
      variant: 'default'
    }
  }
)

function Alert ({
  className,
  variant,
  ...props
}) {
  return (
    (
      <div
        data-slot='alert'
        role='alert'
        className={cn(alertVariants({ variant }), className)}
        {...props}
      />
    )
  )
}

function AlertTitle ({
  className,
  ...props
}) {
  return (
    (
      <div
        data-slot='alert-title'
        className={cn('col-start-2 line-clamp-1 min-h-4 font-medium tracking-tight', className)}
        {...props}
      />
    )
  )
}

function AlertDescription ({
  className,
  ...props
}) {
  return (
    (
      <div
        data-slot='alert-description'
        className={cn(
          'text-foreground col-start-2 grid justify-items-start gap-1 text-sm [&_p]:leading-relaxed',
          className
        )}
        {...props}
      />
    )
  )
}

export { Alert, AlertTitle, AlertDescription }
