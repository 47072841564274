import React, { use } from 'react'
import ContentSection from '@/components/shared-sections/content-section'
import { DataContext } from '@/contexts/DataProvider'
import BillingAlertForm from './alert-form'
import useQuery from '@/utils/useQuery'
import { endpoints } from '@/utils/endpoints'
import { SkeletonBasics } from '@/components/shimmers/basic'

export default function BillingAlerts () {
  const { data: billingAlertData } = useQuery({
    url: `${endpoints.organization.billing.alerts.base}`
  })
  if (!billingAlertData) return <SkeletonBasics />
  const { userData } = use(DataContext)
  return (
    <ContentSection
      title='Set Alerts'
      desc='Send me an email anytime my monthly usage exceeds this amount.'
    >
      <BillingAlertForm
        billingAlertData={billingAlertData.length ? billingAlertData[0] : []}
        userData={userData}
      />
    </ContentSection>
  )
}
