import PropTypes from 'prop-types'
import {
  flexRender,
  getCoreRowModel,
  useReactTable
} from '@tanstack/react-table'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from '@/components/ui/table'
import { convertEpochToTimezone } from '@/utils/helper'
import { Badge } from '@/components/ui/badge'
import { TooltipContent, TooltipTrigger } from '@/components/ui/tooltip'
import { Tooltip } from '@radix-ui/react-tooltip'
import { Separator } from '@/components/ui/separator'

export default function WebhookHistoryTable ({ getWebhookHistory, userData }) {
  const renderShowStatus = (item, row) => {
    if (item === 'retrying') {
      return (
        <>
          <Tooltip>
            <TooltipTrigger asChild><Badge variant='secondary' size='sm'>{item}</Badge></TooltipTrigger>
            <TooltipContent
              side='top'
              align='center'
            >retrying... {row.original.retry_count}
            </TooltipContent>
          </Tooltip>
        </>
      )
    } else if (item === 'failed') {
      return <Badge variant='destructive' size='sm'>{item}</Badge>
    } else if (item === 'success') {
      return <Badge variant='outline' size='sm'>{item}</Badge>
    }
  }
  const columns = [
    {
      id: 'asset_id',
      accessorKey: 'asset_id',
      header: 'Asset ID',
      cell: ({ getValue }) => getValue() || '-'
    },
    {
      id: 'event',
      accessorKey: 'event',
      header: 'Event',
      cell: ({ getValue }) => getValue() || '-'
    },
    {
      id: 'status',
      accessorKey: 'status',
      header: 'Status',
      cell: ({ getValue, row }) => renderShowStatus(getValue(), row) || '-'
    },
    {
      id: 'created_at',
      accessorKey: 'created_at',
      header: 'Created At',
      cell: ({ getValue }) => getValue() ? convertEpochToTimezone(getValue(), userData.timezone) : '-'
    }
  ]

  const table = useReactTable({
    data: getWebhookHistory,
    columns,
    getCoreRowModel: getCoreRowModel()
  })
  return (
    <div className='space-y-4 w-full'>
      <div className='flex items-center justify-between'>
        <h3 className='text-lg font-medium'>History</h3>
      </div>
      <Separator className='my-4 flex-none' />
      <div className='rounded-md border'>
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id} className='group/row'>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead
                      key={header.id}
                      colSpan={header.colSpan}
                      className={header.column.columnDef.meta?.className ?? ''}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                    </TableHead>
                  )
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length
              ? (
                  table.getRowModel().rows.map((row) => (
                    <TableRow
                      key={row.id}
                      data-state={row.getIsSelected() && 'selected'}
                      className='group/row'
                    >
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <TableCell
                            key={cell.id}
                            className={cell.column.columnDef.meta?.className ?? ''}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            ) || '-'}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  ))
                )
              : (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    className='h-24 text-center'
                  >
                    <p className='mt-4'>No webhook history.</p>
                  </TableCell>
                </TableRow>
                )}
          </TableBody>
        </Table>
      </div>
    </div>
  )
}

WebhookHistoryTable.propTypes = {
  getWebhookHistory: PropTypes.array,
  userData: PropTypes.object
}
