import { cn } from '@/utils/helper'
import PropTypes from 'prop-types'
import * as TooltipPrimitive from '@radix-ui/react-tooltip'

function TooltipProvider ({
  delayDuration = 0,
  ...props
}) {
  return (<TooltipPrimitive.Provider data-slot='tooltip-provider' delayDuration={delayDuration} {...props} />)
}

TooltipProvider.propTypes = {
  delayDuration: PropTypes.number
}

function Tooltip ({
  ...props
}) {
  return (
    (
      <TooltipProvider>
        <TooltipPrimitive.Root data-slot='tooltip' {...props} />
      </TooltipProvider>
    )
  )
}

function TooltipTrigger ({
  ...props
}) {
  return <TooltipPrimitive.Trigger data-slot='tooltip-trigger' {...props} />
}

function TooltipContent ({
  className,
  side = 'top', // Default position
  sideOffset = 4,
  children,
  ...props
}) {
  return (
    <TooltipPrimitive.Portal>
      <TooltipPrimitive.Content
        data-slot='tooltip-content'
        side={side} // Apply the side prop dynamically
        sideOffset={sideOffset}
        className={cn(
          'bg-primary text-primary-foreground animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 z-50 w-auto rounded-md px-3 py-1.5 text-xs text-center',
          className
        )}
        {...props}
      >
        {children}
        <TooltipPrimitive.Arrow
          className='bg-primary fill-primary z-50 size-2.5 translate-y-[calc(-50%_-_2px)] rotate-45 rounded-[2px]'
        />
      </TooltipPrimitive.Content>
    </TooltipPrimitive.Portal>
  )
}
export { Tooltip, TooltipTrigger, TooltipContent, TooltipProvider }

TooltipContent.propTypes = {
  className: PropTypes.string,
  side: PropTypes.string,
  sideOffset: PropTypes.number,
  children: PropTypes.node
}
