import React from 'react'
import { useForm } from 'react-hook-form'
import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/dialog'
import { Input } from '@/components/ui/input'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'components/ui/select'
import {
  Form,
  FormField,
  FormControl,
  FormItem,
  FormLabel
} from '@/components/ui/form'
import useQuery from '@/utils/useQuery'
import { endpoints } from '@/utils/endpoints'

export default function EditCollection ({
  collection
}) {
  const { data: videoSources, isLoading } = useQuery({
    url: `${endpoints.video.sources.base}`
  })
  // console.log(videoSources)
  // get all collections from the api
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      name: collection.name,
      video_source_id: collection.video_source_id

    }
  })
  async function onSubmit (data) {
    console.log(data)
  }

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant='outline' size='sm' title='edit'>Edit</Button>
      </DialogTrigger>
      <DialogContent className='sm:max-w-[425px]'>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className='grid gap-4'>
            <DialogHeader>
              <DialogTitle>Edit Collection</DialogTitle>
              <DialogDescription className='text-xs'>{collection._id}</DialogDescription>
            </DialogHeader>
            <div className='grid gap-4 py-4'>
              <div className='grid items-center gap-4'>
                <FormField
                  control={form.control}
                  name='name'
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Name</FormLabel>
                      <FormControl>
                        <Input {...field} />
                      </FormControl>
                    </FormItem>
                  )}
                />
              </div>
              <div className='grid items-center gap-4'>
                {/* // dropdown form field */}
                <FormField
                  control={form.control}
                  name='video_source_id'
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Video Source</FormLabel>
                      <FormControl>
                        <Select {...field}>
                          <SelectTrigger>
                            <SelectValue>Select Video Source</SelectValue>
                          </SelectTrigger>
                          <SelectContent>
                            <SelectItem value='1'>1</SelectItem>
                            <SelectItem value='2'>2</SelectItem>
                            <SelectItem value='3'>3</SelectItem>
                          </SelectContent>
                        </Select>
                      </FormControl>
                    </FormItem>
                  )}
                />
              </div>
            </div>
            <DialogFooter>
              <Button variant='outline' size='sm'>Cancel</Button>
              <Button type='submit' variant='default' size='sm'>Save changes</Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}
