import { useLocation } from 'react-router'
import { SectionHeader } from '@/components/ui-layout/section-header'
import { Separator } from '@/components/ui/separator'
import { Header } from '@/components/ui-layout/header'
import {
  BreadcrumbComponents
} from '@/components/ui/breadcrumb'

import {
  Building2,
  NotepadText,
  CreditCard,
  FilePenLine,
  BellRing
} from 'lucide-react'
import SidebarNav from '@/components/sidebar-nav'
import { Outlet } from 'react-router'
import { SkeletonBasics } from '@/components/shimmers/basic'
import { RoleBasedRouteChildren } from '@/utils/roleBasedRoute'
import Unauthorized from '../401'
import { getBreadcrumbItems } from '@/utils/helper'

export default function Billing () {
  const location = useLocation()
  let breadcrumbItem = getBreadcrumbItems(location.pathname)
  // case to handle the breadcrumb for some routes
  switch (breadcrumbItem) {
    case 'Changebilling':
      breadcrumbItem = 'Billing Details'
      break
    case 'Add':
      breadcrumbItem = 'Webhooks'
      break
    case 'Payment-methods':
      breadcrumbItem = 'Payment Methods'
      break
    default:
      break
  }
  const { isAllowed, loading } = RoleBasedRouteChildren()
  return (
    <>
      <Header>
        <BreadcrumbComponents
          Breadcrum1='Organization'
          Breadcrum2={breadcrumbItem}
        />
      </Header>

      <SectionHeader fixed>
        <div className='space-y-0.5'>
          <h1 className='text-2xl font-medium md:text-3xl'>
            Billing
          </h1>
        </div>
        <Separator className='my-4 lg:my-6' />
        <div className='flex flex-1 flex-col space-y-2 overflow-hidden md:space-y-2 lg:flex-row lg:space-x-12 lg:space-y-0'>
          <aside className='top-0 lg:sticky lg:w-1/5'>
            <SidebarNav items={sidebarNavItems} />
          </aside>
          <div className='flex w-full overflow-y-hidden p-1 pr-4'>
            {loading ? <SkeletonBasics /> : isAllowed ? <Outlet /> : <Unauthorized />}
          </div>
        </div>
      </SectionHeader>
    </>
  )
}

const sidebarNavItems = [
  {
    title: 'Your Plan',
    icon: <Building2 size={18} />,
    href: '/organization/billing'
  },
  {
    title: 'Invoices',
    icon: <NotepadText size={18} />,
    href: '/organization/invoices'
  },
  {
    title: 'Payment Methods',
    icon: <CreditCard size={18} />,
    href: '/organization/payment-methods'
  },
  {
    title: 'Billing Details',
    icon: <FilePenLine size={18} />,
    href: '/organization/changebilling'
  },
  {
    title: 'Billing Alert',
    icon: <BellRing size={18} />,
    href: '/organization/alerts'
  }
]
