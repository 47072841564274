import PropTypes from 'prop-types'
import { UNAUTHORIZED_TOOLTIP } from '@/utils/constants'
export default function Unauthorized ({ from }) {
  return (
    <div className={`flex flex-col items-center justify-center gap-3 ${from === 'routes' ? 'min-h-full' : 'h-96 rounded-lg border p-4'} w-full`}>
      <h6 className='text-lg font-semibold leading-tight font-mono text-muted-foreground'>401</h6>
      <span className='font-medium text-xl'>Unauthorized Access</span>
      <p className='text-center text-muted-foreground text-sm'>
        {UNAUTHORIZED_TOOLTIP}
      </p>
    </div>
  )
}

Unauthorized.propTypes = {
  from: PropTypes.string
}

Unauthorized.defaultProps = {
  from: 'component'
}
