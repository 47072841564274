import {
  GalleryVerticalEnd,
  CircleHelp,
  Images,
  TvMinimalPlay,
  Tv,
  ChartPie,
  ListVideo,
  FilePlus2,
  ChartLine,
  FileStack,
  Settings2,
  MessageSquareWarning,
  Mails,
  FilePenLine,
  SquarePlay,
  Boxes
} from 'lucide-react'
import { routePaths as paths } from '@/utils/routePaths'

export const data = {
  user: {
    name: 'shadcn',
    email: 'm@example.com',
    avatar: '/avatars/shadcn.jpg'
  },
  header: {
    name: 'Gumlet',
    logo: GalleryVerticalEnd
  },
  navGroups: [
    {
      title: 'Video',
      items: [
        {
          title: 'Video',
          url: paths.video.dashboard,
          icon: TvMinimalPlay,
          items: [
            {
              title: 'Overview',
              url: paths.video.dashboard,
              role: 'manage',
              searchIcon: TvMinimalPlay
            },
            {
              title: 'Video Library',
              url: paths.video.cms.manage,
              role: 'asset',
              searchIcon: ListVideo
            },
            {
              title: 'Import Videos',
              url: paths.video.importers.base,
              role: 'import-videos',
              searchIcon: FilePlus2
            },
            {
              title: 'Collections',
              url: paths.video.sources.allsources,
              role: 'collections',
              searchIcon: FileStack
            },
            {
              title: 'Reports',
              url: paths.video.reports.allreports,
              role: 'reports',
              searchIcon: Mails
            },
            {
              title: 'Alerts',
              url: paths.video.alerts.allalerts,
              role: 'alerts',
              searchIcon: MessageSquareWarning
            },
            {
              title: 'Profiles',
              url: paths.video.profiles.allprofiles,
              role: 'profile',
              searchIcon: Settings2
            },
            {
              title: 'Usage Analytics',
              url: '/video/analytics',
              role: 'analytics',
              searchIcon: ChartPie

            }
          ]
        },
        {
          title: 'Live Streams',
          url: paths.stream.assets.base,
          icon: Tv,
          items: [
            {
              title: 'Stream Manager',
              url: paths.stream.assets.base,
              role: 'stream',
              searchIcon: Tv
            },
            {
              title: 'Collections',
              url: paths.stream.sources.allsources,
              role: 'collections',
              searchIcon: FileStack
            },
            {
              title: 'Usage Analytics',
              url: paths.stream.analytics,
              role: 'analytics',
              searchIcon: ChartLine
            }
          ]
        },
        {
          title: 'Analytics',
          url: paths.insights.boards.base.route,
          icon: ChartPie,
          items: [
            {
              title: 'Dashboard',
              url: paths.insights.boards.base.route,
              role: 'boards',
              searchIcon: FilePenLine
            },
            {
              title: 'Playbacks',
              url: paths.insights.views.base.route,
              role: 'views',
              searchIcon: SquarePlay
            },
            {
              title: 'Properties',
              url: paths.insights.properties.base.route,
              role: 'properties',
              searchIcon: Boxes
            }
          ]
        }
      ]
    },
    {
      title: 'Images',
      items: [
        {
          title: 'Images',
          url: paths.image.dashboard,
          icon: Images,
          items: [
            {
              title: 'Overview',
              url: paths.image.dashboard,
              role: 'manage',
              searchIcon: Images
            },
            {
              title: 'Sources',
              url: paths.image.sources.allsources,
              role: 'sources',
              searchIcon: FileStack
            },
            {
              title: 'Purge Cache',
              url: paths.image.cache,
              role: 'cache',
              searchIcon: FilePenLine
            },
            {
              title: 'Analytics',
              url: paths.image.analytics,
              role: 'analytics',
              searchIcon: ChartLine
            },
            {
              title: 'Reports',
              url: paths.image.reports.allreports,
              role: 'reports',
              searchIcon: Mails
            },
            {
              title: 'Alerts',
              url: paths.image.alerts.allalerts,
              role: 'alerts',
              searchIcon: MessageSquareWarning
            }
          ]
        }
      ]
    },
    {
      title: 'Other',
      items: [
        {
          title: 'Help',
          url: paths.help,
          icon: CircleHelp,
          searchIcon: CircleHelp
        }
      ]
    }
  ]
}
