import { DataContext } from '@/contexts/DataProvider'
import ContentSection from '@/components/shared-sections/content-section'
import { use } from 'react'
import PasswordForm from './password-form'

export default function Password () {
  const { userData, setUserData } = use(DataContext)
  return (
    <ContentSection
      title='Password'
    >
      <PasswordForm userData={userData} setUserData={setUserData} />
    </ContentSection>
  )
}
