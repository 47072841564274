import PropTypes from 'prop-types'
import Cookies from 'js-cookie'
import { config } from './config'

const ProtectedRoute = ({ element }) => {
  const userToken = Cookies.get('user_token')

  if (!userToken) {
    window.location.href = config.WEBAPP_NONWWW_HOST + '/dashboard/logout/'
  }

  return element
}
ProtectedRoute.propTypes = {
  element: PropTypes.element.isRequired
}

export default ProtectedRoute
