import {
  Card,
  CardContent,
  CardFooter
} from '@/components/ui/card'
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger
} from '@/components/ui/tabs'
import { ArrowRight } from 'lucide-react'
import { Link, useSearchParams } from 'react-router'
import VideoPricing from './video-pricing'
import ImagePricing from './image-pricing'
import { PRICING_PLANS } from '@/utils/constants'
import PropTypes from 'prop-types'
import { Button } from '@/components/ui/button'
import { Switch } from '@/components/ui/switch'
import { Label } from '@/components/ui/label'

const getPlanIndex = (plans, planId) => plans.findIndex((plan) => plan.id === planId)

const isUpgrade = (currentPlanIndex, selectedPlanIndex) => currentPlanIndex < selectedPlanIndex

const isLegacyPlan = (plan) => plan?.display_name?.includes('Legacy')

export default function PricingTabs ({ orgData, setDowngradeModal, setConfirmPlan, isYearly, setIsYearly, handlePlanChange, setOpenCalendly }) {
  const [searchParams] = useSearchParams()
  const plan = searchParams.get('plan') || 'video'

  function getDynamicButton (plan, planType) {
    if (!orgData) return null
    const { image: currentImagePlan, video: currentVideoPlan } = orgData.plan_data || {}
    const isCurrentPlan = currentImagePlan?.plan === plan.id || currentVideoPlan?.plan === plan.id

    if (isCurrentPlan) {
      return (
        <Button size='sm' className='w-full' disabled variant='secondary'>
          Current Plan
        </Button>
      )
    }

    const currentImagePlanIndex = getPlanIndex(PRICING_PLANS.image.plans, currentImagePlan?.plan)
    const currentVideoPlanIndex = getPlanIndex(PRICING_PLANS.video.plans, currentVideoPlan?.plan)
    const selectedImagePlanIndex = getPlanIndex(PRICING_PLANS.image.plans, plan.id)
    const selectedVideoPlanIndex = getPlanIndex(PRICING_PLANS.video.plans, plan.id)

    const isImageUpgrade = planType === 'image' && isUpgrade(currentImagePlanIndex, selectedImagePlanIndex)
    const isVideoUpgrade = planType === 'video' && isUpgrade(currentVideoPlanIndex, selectedVideoPlanIndex)
    const isUpgradePlan = isImageUpgrade || isVideoUpgrade

    const hasLegacyPlan = isLegacyPlan(currentImagePlan) || isLegacyPlan(currentVideoPlan)

    if (hasLegacyPlan) {
      return (
        <Button
          size='sm'
          className='w-full'
          onClick={() => handlePlanChange(plan, planType)}
        >
          {`Switch to ${plan.plan}`}
        </Button>
      )
    }

    return (
      <Button
        onClick={() => isUpgradePlan ? handlePlanChange(plan, planType) : setDowngradeModal({ plan, planType })}
        size='sm'
        className='w-full group'
        variant={isUpgradePlan ? 'secondary' : 'outline'}
      >
        {isUpgradePlan ? plan.btnTxt : 'Downgrade Plan'}
        <ArrowRight className='w-3 h-3 opacity-0 transition-all group-hover:opacity-100 group-hover:translate-x-1' />
      </Button>
    )
  }

  const renderPricingContent = (type) => (
    <Card className='rounded-none border-none shadow-none'>
      <CardContent className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 pb-4 text-xl'>
        {type === 'video'
          ? (
            <VideoPricing PRICING_PLANS={PRICING_PLANS} getDynamicButton={getDynamicButton} isYearly={isYearly} orgData={orgData} setOpenCalendly={setOpenCalendly} />
            )
          : (
            <ImagePricing PRICING_PLANS={PRICING_PLANS} getDynamicButton={getDynamicButton} isYearly={isYearly} orgData={orgData} setOpenCalendly={setOpenCalendly} />
            )}
      </CardContent>
      <CardFooter className='flex justify-end items-center pb-2'>
        <a href={`https://gumlet.com/pricing/?tab=${type}`} target='_blank' rel='noopener noreferrer' className='text-xs text-muted-foreground flex items-center gap-1 group'>Learn more about our plans <ArrowRight className='w-4 h-4 transition-transform group-hover:translate-x-1' /></a>
        {
          (type === 'video' && (orgData?.plan_data?.video.plan !== 'videoPlan1' && orgData?.plan_data?.video.plan !== 'free')) && (
            <span className='text-sm text-muted-foreground flex items-center gap-1'>Not using Gumlet, downgrade to <p className='text-primary underline cursor-pointer' onClick={() => setDowngradeModal({ plan: PRICING_PLANS.video.plans[0], planType: type })}>Free Plan</p></span>
          )
        }
      </CardFooter>
    </Card>
  )

  return (
    <Tabs value={plan} onValueChange={() => setConfirmPlan({})}>
      <div className='px-6 py-3 flex lg:flex-row flex-col justify-between items-center'>
        <TabsList className='grid grid-cols-2 gap-0 bg-accent w-full lg:w-auto order-1 lg:order-0'>
          <TabsTrigger value='video' className='cursor-pointer' asChild>
            <Link to='?plan=video' className='text-xs'>&nbsp;&nbsp;&nbsp;Video&nbsp;&nbsp;&nbsp;</Link>
          </TabsTrigger>
          <TabsTrigger value='image' className='cursor-pointer' asChild>
            <Link to='?plan=image' className='text-xs'>&nbsp;&nbsp;&nbsp;Image&nbsp;&nbsp;&nbsp;</Link>
          </TabsTrigger>
        </TabsList>
        <div className='flex items-center justify-between lg:pb-0 pb-10'>
          <div className='flex items-center space-x-2'>
            <Label htmlFor='billing-toggle' className='text-xs'>Monthly</Label>
            <Switch
              id='billing-toggle'
              checked={isYearly}
              onCheckedChange={setIsYearly}
            />
            <Label htmlFor='billing-toggle' className='text-xs'>Yearly <span className={`text-xs ${isYearly ? 'text-primary' : 'text-muted-foreground'}`}>(Save up to 50%)</span></Label>
          </div>
        </div>
      </div>
      <TabsContent value='video'>
        {renderPricingContent('video')}
      </TabsContent>
      <TabsContent value='image'>
        {renderPricingContent('image')}
      </TabsContent>
    </Tabs>
  )
}

PricingTabs.propTypes = {
  orgData: PropTypes.object.isRequired,
  setDowngradeModal: PropTypes.func.isRequired,
  setConfirmPlan: PropTypes.func.isRequired,
  isYearly: PropTypes.bool.isRequired,
  setIsYearly: PropTypes.func.isRequired,
  handlePlanChange: PropTypes.func.isRequired,
  setOpenCalendly: PropTypes.func.isRequired
}
