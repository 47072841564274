import PropTypes from 'prop-types'
import { Progress } from '@/components/ui/progress'
import { Image, TvMinimalPlay as Video, Zap } from 'lucide-react'
import { calculateProgressBarWidth, convertDataSize, formatBytes1000 } from '@/utils/helper'
import { Button } from '@/components/ui/button'
import { INCLUDE_PLANS } from '@/utils/constants'
import { Link } from 'react-router'
import { routePaths } from '@/utils/routePaths'
import { Badge } from '@/components/ui/badge'

const PlanCard = ({ icon: Icon, title, planName, isTrial, plan, orgData, details }) => (
  <div className='rounded-lg border bg-card text-card-foreground flex flex-col h-full'>
    <div className='p-4 flex items-start justify-between pb-0'>
      <div className='flex flex-col align-items-center gap-2'>
        <span className='text-xl font-medium'>
          {planName}
          {!isTrial && 
            <Badge variant='secondary' className='text-xs px-2 rounded-lg ml-2'>
              Trial
            </Badge>
          }
          <Badge variant='success' className='text-xs px-2 rounded-lg ml-2'>
            {/* <Icon className='h-4 w-4' /> */}
            {title.replace(' Plan', '')}
          </Badge>
        </span>
      </div>
      <div className='flex size-10 items-center justify-center rounded-full bg-muted'>
        <Icon className='h-5 w-5' />
      </div>
    </div>
    <div className='p-4 flex flex-col gap-3'>
      {details.map((item, index) => (
        <div key={index} className='flex flex-col gap-2'>
          <div className='flex justify-between'>
            <p className='text-xs text-muted-foreground'>{item.label}</p>
            <p className='text-xs text-muted-foreground'>{item.value} / {item.total}</p>
          </div>
          <Progress value={item.progress} />
        </div>
      ))}
    </div>
    <div className='p-4 pt-2 flex justify-end mt-auto'>
      {
        INCLUDE_PLANS.appSumoPlans.includes(orgData?.plan_data?.video?.plan) && plan === 'video'
          ? <Button
              size='lg'
              href='https://appsumo.com/account/products/'
              target='_blank'
              rel='noreferrer'
            >
            Upgrade Plan
            </Button>
          : <Link to={`${routePaths.planChange}?plan=${plan}`}><Button size='sm' variant='default'>Upgrade Plan</Button></Link>
      }
    </div>
  </div>
)

PlanCard.propTypes = {
  icon: PropTypes.elementType.isRequired,
  title: PropTypes.string.isRequired,
  planName: PropTypes.string.isRequired,
  isTrial: PropTypes.bool,
  plan: PropTypes.string.isRequired,
  orgData: PropTypes.object.isRequired,
  details: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      total: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      progress: PropTypes.number.isRequired
    })
  ).isRequired
}

export default function CurrentPlanDetail ({ orgData, billing, bandwidthConsumption, videoBandwidthConsumption }) {
  const videoPlan = orgData?.plan_data?.video
  const imagePlan = orgData?.plan_data?.image

  // Video Plan Calculations
  const videoStorageUsed = Math.ceil(videoPlan?.usage.storage / 60)
  const videoStorageTotal = Math.floor(videoPlan?.quota.storage / 60)
  const videoStorageProgress = calculateProgressBarWidth(videoStorageUsed, videoStorageTotal)

  const isVideoUnlimited = videoPlan?.quota.bandwidth >= 1e14
  const videoBandwidthQuota = isVideoUnlimited ? 'Unlimited' : formatBytes1000(videoPlan?.quota.bandwidth, 1)
  const videoBandwidthProgress = isVideoUnlimited
    ? 0
    : convertDataSize(videoBandwidthConsumption, videoBandwidthQuota)

  // Optional CNAME Quota
  const hasCnameQuota = !!videoPlan?.quota?.video_cname
  const videoCnameUsed = videoPlan?.usage?.video_cname || 0
  const videoCnameTotal = videoPlan?.quota?.video_cname || 0
  const videoCnameProgress = videoCnameTotal
    ? Math.min((videoCnameUsed / videoCnameTotal) * 100, 100)
    : 0

  // Image Plan Calculations
  const imageBandwidthQuota = formatBytes1000(imagePlan?.quota.bandwidth, 1)
  const imageBandwidthProgress = convertDataSize(bandwidthConsumption, imageBandwidthQuota)

  return (
    videoPlan.plan === imagePlan.plan
      ? <div className='rounded-lg border bg-card text-card-foreground flex flex-col h-full'>
        <div className='p-4 flex items-center justify-between'>
          <div className='flex flex-col gap-1'>
            <span className='tracking-tight text-xs text-muted-foreground uppercase'>Current Plan</span>
            <div className='text-xl font-bold text-primary'>
              Enterprise
            </div>
          </div>
          <Button size='sm'><Link to={`${routePaths.help}`}>Contact Support</Link></Button>
        </div>
        <div className='p-4 flex flex-col align-items-center gap-1'>
          <span className='tracking-tight text-xs text-muted-foreground uppercase'>Gumlet Credits</span>
          <div className='text-xl font-bold text-primary'>
            {billing.currency === 'USD' ? '$' : ''}
            {billing.total_balance} {billing.currency}
          </div>
        </div>
        </div>
      : <div className='grid gap-4 md:grid-cols-1 lg:grid-cols-2'>
        <PlanCard
          icon={Video}
          title='Video Plan'
          planName={videoPlan?.display_name}
          isTrial={videoPlan?.trialing}
          plan='video'
          orgData={orgData}
          details={[
            {
              label: 'Storage minutes',
              value: videoStorageUsed,
              total: videoStorageTotal,
              progress: videoStorageProgress
            },
            {
              label: 'Streaming usage',
              value: videoBandwidthConsumption,
              total: videoBandwidthQuota,
              progress: videoBandwidthProgress
            },
            ...(hasCnameQuota
              ? [
                  {
                    label: 'CNAME Quota',
                    value: videoCnameUsed,
                    total: videoCnameTotal,
                    progress: videoCnameProgress
                  }
                ]
              : [])
          ]}
        />
        <PlanCard
          icon={Image}
          title='Image Plan'
          planName={imagePlan?.display_name}
          isTrial={imagePlan?.trialing}
          plan='image'
          orgData={orgData}
          details={[
            {
              label: 'Bandwidth',
              value: bandwidthConsumption,
              total: imageBandwidthQuota,
              progress: imageBandwidthProgress
            }
          ]}
        />
        </div>
  )
}

CurrentPlanDetail.propTypes = {
  orgData: PropTypes.object.isRequired,
  bandwidthConsumption: PropTypes.string.isRequired,
  videoBandwidthConsumption: PropTypes.string.isRequired,
  billing: PropTypes.object.isRequired
}
