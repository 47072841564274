import { ScrollArea } from '@/components/ui/scroll-area'
import { Separator } from '@/components/ui/separator'
import PropTypes from 'prop-types'

export default function ContentSection ({ title, desc, className, has_action, children }) {
  return (
    <div className='flex flex-1 flex-col'>
      <div className='flex-none'>
        <div className='flex items-center justify-between'>
          <div>
            <h3 className='text-lg font-medium'>{title}</h3>
            <p className='text-sm text-muted-foreground'>{desc}</p>
          </div>
          {has_action}
        </div>
      </div>
      <Separator className='my-4 flex-none' />
      <ScrollArea className='faded-bottom overflow-hidden -mx-4 flex-1 scroll-smooth px-4 md:pb-8'>
        <div className={`-mx-1 px-1.5 ${className || 'lg:max-w-xl'}`}>{children}</div>
      </ScrollArea>
    </div>
  )
}

ContentSection.propTypes = {
  title: PropTypes.string.isRequired,
  desc: PropTypes.string,
  className: PropTypes.string,
  has_action: PropTypes.bool,
  children: PropTypes.node
}
