import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import './index.css'
import App from './App.jsx'
import { BrowserRouter } from 'react-router'
import { DataProvider } from './contexts/DataProvider'
import { ThemeProvider } from './components/theme-provider'
import { TooltipProvider } from './components/ui/tooltip'
import { IntercomProvider } from 'react-use-intercom'
import { config } from 'config'

createRoot(document.getElementById('root')).render(
  <StrictMode>
    <IntercomProvider appId={config.INTERCOM_APP_ID}>
      <BrowserRouter>
        <DataProvider>
          <ThemeProvider defaultTheme='dark' storageKey='vite-ui-theme'>
            <TooltipProvider>
              <App />
            </TooltipProvider>
          </ThemeProvider>
        </DataProvider>
      </BrowserRouter>
    </IntercomProvider>
  </StrictMode>
)
