import { Button } from '@/components/ui/button'
import { Card, CardContent } from '@/components/ui/card'
import { Separator } from '@/components/ui/separator'
import { LoadingSpinner } from '@/components/ui/spinner'
import { PRICING_PLANS } from '@/utils/constants'
import { getDiscountedProratedPrice, getProratedPrice } from '@/utils/helper'
import { ArrowLeft, AlertCircle, Image, Play } from 'lucide-react'
import { Badge } from '@/components/ui/badge'

import PropTypes from 'prop-types'
import {
  Alert,
  AlertDescription,
  AlertTitle
} from '@/components/ui/alert'

export default function PlanSummary ({ confirmPlan, setConfirmPlan, isYearly, coupons, _confirmPlanChange, isLoading }) {
  const getPlanSummary = PRICING_PLANS[confirmPlan.planType].plans.find((plan) => plan.plan === confirmPlan.plan.plan)
  const filteredCoupon = coupons && coupons.coupons.find((coupon) => coupon.origin === 'stripe')
  return (
    <div className='flex flex-col gap-6 p-6'>
      <div className='flex justify-end gap-4'>
        <Button variant='outline' size='lg' className='group' onClick={() => setConfirmPlan({})}><ArrowLeft className='w-4 h-4 transition-transform group-hover:-translate-x-1' />Back to Plans</Button>
        <Button size='lg' onClick={_confirmPlanChange} disabled={isLoading}>{isLoading && <LoadingSpinner />} Confirm Plan</Button>
      </div>
      <Separator />
      <div className='grid grid-cols-1 md:grid-cols-2 gap-6'>
        <Card className='rounded-lg border bg-card text-card-foreground flex flex-col py-2'>
          <CardContent className='pt-6'>
            <h3 className='text-md font-semibold mb-4'>Selected Plan</h3>
            <div className='space-y-4'>
              <div className='flex justify-between items-center'>
                <div className='flex items-center gap-2'>
                  <span className='font-medium text-sm'>Plan Name</span>
                  <Badge variant='success' className='text-xs'>
                    {
                      confirmPlan.planType === 'video' ? <Play className='w-4 h-4' /> : <Image className='w-4 h-4' />
                    }
                    {confirmPlan.planType}
                  </Badge>
                </div>
                <span className='capitalize text-sm'>{confirmPlan.plan.plan}</span>
              </div>
              <Separator className='my-3' />
              {
                getPlanSummary.summary.map((item, index) => {
                  return (
                    <div key={index}>
                      <div className='flex justify-between items-center text-sm'>
                        <span className='text-muted-foreground'>{item.label}</span>
                        <span>{item.value}</span>
                      </div>
                      {index !== getPlanSummary.summary.length - 1 && <Separator className='my-3' />}
                    </div>
                  )
                })
              }
            </div>
          </CardContent>
        </Card>

        <Card className='rounded-lg border bg-card text-card-foreground flex flex-col py-2'>
          <CardContent className='pt-6'>
            <h3 className='text-md font-semibold mb-4'>Payment Summary</h3>
            <div className='flex flex-col gap-6'>
              <div className='space-y-4'>
                <div className='flex justify-between items-center text-sm'>
                  <span>Plan Price</span>
                  <span>{confirmPlan.plan.plan === 'Free' ? '0$/month' : `$${isYearly ? confirmPlan.plan.yearlyPrice : confirmPlan.plan.price}/month`}</span>
                </div>
                <Separator className='my-3' />
                {confirmPlan.plan.plan !== 'Free' && !isYearly && (
                  <>
                    <div className='flex justify-between items-center text-sm'>
                      <span>Prorated Cost</span>
                      <span>{getProratedPrice(confirmPlan.plan.price, isYearly)}</span>
                    </div>
                    <Separator className='my-3' />
                  </>
                )}
                <div className='flex justify-between items-center text-md mb-1'>
                  <span className='font-semibold'>Total Due Today</span>
                  <span className='font-semibold'>
                    {confirmPlan.plan.plan === 'Free' ||
                          confirmPlan.plan.plan === 'free'
                      ? '$0'
                      : getDiscountedProratedPrice(
                        confirmPlan.plan.price,
                        confirmPlan.plan.yearlyPrice,
                        isYearly
                      )}
                  </span>
                </div>
                {filteredCoupon?.value
                  ? (
                    <p className='text-primary text-sm pt-0'>
                      ({filteredCoupon?.value}
                      {filteredCoupon?.discount_type === 'percent' ? '%' : '$'} discount
                      will be applied on the total price)
                    </p>
                    )
                  : (
                    <></>
                    )}
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
      <div className='flex justify-between pt-0 gap-4 items-center'>
        <Alert status='destructive' className='bg-accent flex-wrap'>
          <AlertCircle className='w-6 h-6 text-primary' />
          <AlertTitle>Refund Policy</AlertTitle>
          <AlertDescription>
            <div className='flex justify-between pt-0 gap-4 items-center'>
              <p className='text-xs text-muted-foreground'>If you&apos;re unhappy with your purchase for any reason, we provide 30 day no questions asked refunds.</p>
            </div>
          </AlertDescription>
        </Alert>
      </div>
    </div>
  )
}

PlanSummary.propTypes = {
  confirmPlan: PropTypes.object.isRequired,
  setConfirmPlan: PropTypes.func.isRequired,
  isYearly: PropTypes.bool.isRequired,
  coupons: PropTypes.array.isRequired,
  _confirmPlanChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
}
