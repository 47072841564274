'use client'
import * as React from 'react'
import { useCallback } from 'react'
import { useSearch } from '@/contexts/searchContext'
import {
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList
} from '@/components/ui/command'
import PropTypes from 'prop-types'
import { ScrollArea } from '@/components/ui/scroll-area'
import { useNavigate } from 'react-router'
import { data } from '@/components/data/sidebar'
import { File } from 'lucide-react'

CommandMenu.propTypes = {
  setOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
}

export function CommandMenu () {
  const navigate = useNavigate()
  const { open, setOpen } = useSearch()
  const runCommand = useCallback(
    command => {
      setOpen(false)
      command()
    },
    [setOpen]
  )

  return (
    <CommandDialog open={open} onOpenChange={setOpen}>
      <CommandInput placeholder='Type a command or search...' />
      <CommandList>
        <ScrollArea type='hover' className='h-72 pr-1'>
          <CommandEmpty>No results found.</CommandEmpty>
          {data.navGroups.map((group) => (
            <CommandGroup key={group.title} heading={group.title}>
              {group.items.map((navItem, i) => {
                if (!navItem.items) {
                  return (
                    <CommandItem
                      key={`${navItem.url}-${i}`}
                      value={navItem.title}
                      onSelect={() => {
                        runCommand(() => navigate(navItem.url))
                      }}
                    >
                      <div className='mr-2 flex h-4 w-4 items-center justify-center'>
                        {navItem.searchIcon ? React.createElement(navItem.searchIcon, { className: 'size-2 text-muted-foreground' }) : <File className='size-2 text-muted-foreground/80' />}
                      </div>
                      {navItem.title}
                    </CommandItem>
                  )
                }

                return navItem.items?.map((subItem, i) => (
                  <CommandItem
                    key={`${subItem.url}-${i}`}
                    value={subItem.title}
                    onSelect={() => {
                      runCommand(() => navigate(subItem.url))
                    }}
                  >
                    <div className='mr-2 flex h-4 w-4 items-center justify-center'>
                      {subItem.searchIcon ? React.createElement(subItem.searchIcon, { className: 'size-2 text-muted-foreground' }) : <File className='size-2 text-muted-foreground/80' />}
                    </div>
                    {subItem.title}
                  </CommandItem>
                ))
              })}
            </CommandGroup>
          ))}
        </ScrollArea>
      </CommandList>
    </CommandDialog>
  )
}
