import PropTypes from 'prop-types'
import { Button } from '@/components/ui/button'
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog'

export default function DowngradeModal ({
  downgradeModal,
  setDowngradeModal,
  orgData,
  handlePlanChange
}) {
  const { plan, planType } = downgradeModal
  return (
    <Dialog open={downgradeModal} onOpenChange={setDowngradeModal}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Do you want to lose all the content?</DialogTitle>
          <DialogDescription className='mt-2'>
            You are about to downgrade the plan to <b>{plan.plan}</b>. You will lose the content stored on the <b>{orgData.plan_data[planType].display_name}</b> plan. If you are facing any issues, reach out to chat support.
          </DialogDescription>
        </DialogHeader>
        <DialogFooter className='mt-4'>
          <Button className='cursor-pointer' variant='outline' onClick={() => { handlePlanChange(plan, planType); setDowngradeModal(false) }}>Downgrade</Button>
          <Button onClick={() => setDowngradeModal(false)} className='cursor-pointer'>Save my content</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

DowngradeModal.propTypes = {
  downgradeModal: PropTypes.bool.isRequired,
  setDowngradeModal: PropTypes.func.isRequired,
  orgData: PropTypes.object.isRequired,
  handlePlanChange: PropTypes.func.isRequired
}
