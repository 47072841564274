import { Header } from '@/components/ui-layout/header'
import { SectionHeader } from '@/components/ui-layout/section-header'
import { BreadcrumbComponents } from '@/components/ui/breadcrumb'
import { Button } from '@/components/ui/button'
import { Separator } from '@/components/ui/separator'
import { useParams, Link } from 'react-router'
import { routePaths } from '@/utils/routePaths'
import { ArrowLeft, StopCircle } from 'lucide-react'
import useQuery from '@/utils/useQuery'
import { endpoints } from '@/utils/endpoints'
import { SkeletonBasics } from '@/components/shimmers/basic'
import AssetInfo from './asset-info'
import { DataContext } from '@/contexts/DataProvider'
import AssetEvents from './asset-events'
import { use } from 'react'

export default function LiveStreamDetail () {
  const { userData } = use(DataContext)

  const { assetID } = useParams()

  const { data: getLiveAssetApiResponse, isLoading } = useQuery({
    url: `${endpoints.stream.asset.getLiveAsset.replace(':assetID', assetID)}`
  })

  const { data: getLiveAssetHistoryApiResponse, isLoading: isLoadingHistory } = useQuery({
    url: `${endpoints.stream.asset.getLiveAssethistory.replace(':assetID', assetID)}`
  })

  if (isLoading || isLoadingHistory) return <SkeletonBasics />

  return (
    <>
      <Header>
        <BreadcrumbComponents
          Breadcrum1='Stream Management'
          Breadcrum1Link={routePaths.stream.assets.base}
          Breadcrum3='Live Stream'
        />
      </Header>
      <SectionHeader fixed>
        <div className='flex items-end justify-between sm:my-0 sm:items-center'>
          <h1 className='text-2xl font-medium tracking-tight md:text-3xl'>
            {getLiveAssetApiResponse && getLiveAssetApiResponse.input.title}
          </h1>
          <div className='flex flex-col gap-4 sm:flex-row'>
            <Button className='cursor-pointer' variant='outline' asChild><Link to={routePaths.stream.assets.base}><ArrowLeft /> Go to All Live Streams</Link></Button>
            {
            getLiveAssetApiResponse?.status === 'complete' || getLiveAssetApiResponse?.status === 'deleted'
              ? (
                <></>
                )
              : (
                <Button
                  className='cursor-pointer'
                  variant='default'
                  disabled={getLiveAssetApiResponse?.status === 'preparing'}
                >
                  <StopCircle className='mr-1' /> Complete Stream
                </Button>
                )
          }
          </div>
        </div>
        <Separator className='my-4 lg:my-6' />
        <div className='grid grid-cols-1 lg:grid-cols-10 gap-6'>
          <div className='lg:col-span-7'>
            <AssetInfo getLiveAssetApiResponse={getLiveAssetApiResponse} userData={userData} />
          </div>
          {
            getLiveAssetHistoryApiResponse && (
              <div className='lg:col-span-3'>
                <AssetEvents getLiveAssetHistoryApiResponse={getLiveAssetHistoryApiResponse} userData={userData} />
              </div>
            )
          }
        </div>
        {/* {
        isDeleteModalOpen && <DeleteActionDialog isDeleteOpen={isDeleteModalOpen} setIsDeleteOpen={setIsDeleteModalOpen} onSubmit={_deleteLiveStream} title='This live stream will be deleted. Are you sure you want to continue?' />
      } */}
      </SectionHeader>
    </>
  )
}
