import { use, useState } from 'react'
import ContentSection from '@/components/shared-sections/content-section'
import SessionTable from './session-table'
import { endpoints } from '@/utils/endpoints'
import http from '@/utils/http'
import { toast } from 'sonner'
import { DataContext } from '@/contexts/DataProvider'
import { Button } from '@/components/ui/button'
import Cookies from 'js-cookie'
import useQuery from '@/utils/useQuery'
import DeleteActionDialog from '@/components/delete-action-dialog'
import { SkeletonBasics } from 'components/shimmers/basic'

export default function Sessions () {
  const { userData } = use(DataContext)
  const currentSession = Cookies.get('user_token')
  const [isDeleteOpen, setIsDeleteOpen] = useState(false)

  const { data: sessions, setData: setSessions, isLoading } = useQuery({
    url: `${endpoints.userToken.session.getSessions}`
  })

  if (isLoading) return <SkeletonBasics />

  const _deleteSession = async (id) => {
    try {
      const data = await http({}).delete(
        endpoints.userToken.session.deleteSession.replace(':session_id', id)
      )
      if (data.success) {
        toast.success('Session deleted succussfully')
        setSessions({
          ...sessions,
          sessions: sessions.sessions.filter((item) => item.id !== id)
        })
      }
    } catch (error) {
      toast.error(error?.response?.data?.error?.message)
    } finally {
      setIsDeleteOpen(false)
    }
  }

  const _deleteAllSession = async () => {
    try {
      const data = await http({}).delete(
        endpoints.userToken.session.deleteAllSessions
      )
      if (data.success) {
        toast.success('Sessions deleted succussfully')
        setSessions({
          ...sessions,
          sessions: sessions.sessions.filter((item) => item.id === currentSession)
        })
      }
    } catch (error) {
      toast.error(error?.response?.data?.error?.message)
    }
  }

  if (!sessions) {
    return null
  }

  const actionComponent = (
    <Button
      variant='white'
      className='cursor-pointer'
      onClick={() => {
        const confirmDelete = window.confirm('Are you sure you want to delete all sessions?')
        if (confirmDelete) {
          _deleteAllSession()
        }
      }}
    >
      End All Sessions
    </Button>
  )
  return (
    <ContentSection
      title='Active Sessions'
      className='max-w-none'
      has_action={sessions.sessions.length > 1 && actionComponent}
    >
      <SessionTable sessions={sessions.sessions} userData={userData} currentSession={currentSession} _deleteSession={_deleteSession} setIsDeleteOpen={setIsDeleteOpen} />
      {
        isDeleteOpen && <DeleteActionDialog isDeleteOpen={isDeleteOpen} setIsDeleteOpen={setIsDeleteOpen} onSubmit={_deleteSession} title='The session will be terminated. Are you sure you want to continue?' />
      }
    </ContentSection>
  )
}
