import React, { useState } from 'react'
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/dialog'
import { Button } from '@/components/ui/button'
import { Form, FormField, FormControl, FormItem, FormLabel } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Checkbox } from '@/components/ui/checkbox'
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group'
import { useForm } from 'react-hook-form'
import { Plus } from 'lucide-react'
import { toast } from 'sonner'

const RESOLUTION_OPTIONS = [
  { id: 1, name: '240p' },
  { id: 2, name: '360p' },
  { id: 3, name: '480p' },
  { id: 4, name: '720p' },
  { id: 5, name: '1080p' }
]

const ORIENTATION_OPTIONS = [
  { id: 1, name: 'landscape' },
  { id: 2, name: 'portrait' }
]

export default function AddCollection () {
  const [isAddCollection, setAddStreamCollection] = useState('')
  const [LiveStreamOption, showLiveStreamOptions] = useState(false)

  const collectionForm = useForm({
    mode: 'onChange',
    defaultValues: {
      name: ''
    }
  })

  const liveStreamForm = useForm({
    mode: 'onChange',
    defaultValues: {
      title: '',
      resolution: ['360p', '480p', '720p'],
      orientation: 'landscape'
    }
  })

  async function onCollectionFormSubmit (data) {
    if (!data.name) {
      toast.error('Please enter a collection name')
      return
    }
    if (data.name) {
      setAddStreamCollection(data.name)
      showLiveStreamOptions(true)
    }
  }
  async function onLiveStreamFormSubmit (data) {
    console.log(data)
  }

  const CollectionForm = () => {
    return (
      <Form {...collectionForm}>
        <form className='grid gap-4' onSubmit={collectionForm.handleSubmit(onCollectionFormSubmit)}>
          <DialogHeader>
            <DialogTitle>Add Collection</DialogTitle>
          </DialogHeader>
          <div className='grid gap-4 py-4'>
            <FormField
              control={collectionForm.control}
              name='name'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Collection Name</FormLabel>
                  <FormControl>
                    <Input placeholder='Collection Name' {...field} />
                  </FormControl>
                </FormItem>
              )}
            />
          </div>
          <DialogFooter>
            <Button type='close' variant='muted'>Cancel</Button>
            <Button type='submit' variant='default'>Add Collection</Button>
          </DialogFooter>
        </form>
      </Form>
    )
  }

  const LiveStreamOptions = () => {
    return (
      <Form {...liveStreamForm}>
        <form className='grid gap-4' onSubmit={liveStreamForm.handleSubmit(onLiveStreamFormSubmit)}>
          <DialogHeader>
            <DialogTitle>Want to start a live stream?</DialogTitle>
          </DialogHeader>
          <div className='grid gap-4 space-y-4'>
            <FormField
              control={liveStreamForm.control}
              name='title'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Title</FormLabel>
                  <FormControl>
                    <Input placeholder='Title' {...field} />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={liveStreamForm.control}
              name='resolution'
              render={() => (
                <FormItem>
                  <FormLabel>Select Resolutions</FormLabel>
                  <div className='flex items-center space-x-2 mt-2'>
                    {RESOLUTION_OPTIONS.map((item) => (
                      <FormField
                        key={item.id}
                        control={liveStreamForm.control}
                        name='resolution'
                        render={({ field }) => {
                          return (
                            <FormItem key={item.id} className='flex flex-row items-start space-y-0'>
                              <FormControl>
                                <Checkbox
                                  checked={field.value?.includes(item.name)}
                                  onCheckedChange={(checked) => {
                                    checked
                                      ? field.onChange([...field.value, item.name])
                                      : field.onChange(
                                        field.value?.filter(
                                          (value) => value !== item.name
                                        )
                                      )
                                  }}
                                />
                              </FormControl>
                              <FormLabel className='text-sm font-normal'>
                                {item.name}
                              </FormLabel>
                            </FormItem>
                          )
                        }}
                      />
                    ))}
                  </div>
                </FormItem>
              )}
            />
            <FormField
              control={liveStreamForm.control}
              name='orientation'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Select Orientation</FormLabel>
                  <FormControl>
                    <RadioGroup
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                      className='flex items-center space-x-2'
                    >
                      <FormItem className='flex items-center'>
                        <FormControl>
                          <RadioGroupItem value='landscape' />
                        </FormControl>
                        <FormLabel className='text-sm font-normal'>
                          Landscape
                        </FormLabel>
                      </FormItem>
                      <FormItem className='flex items-center'>
                        <FormControl>
                          <RadioGroupItem value='portrait' />
                        </FormControl>
                        <FormLabel className='text-sm font-normal'>
                          Portrait
                        </FormLabel>
                      </FormItem>
                    </RadioGroup>
                  </FormControl>
                </FormItem>
              )}
            />
          </div>
          <DialogFooter>
            <Button type='close' variant='secondary'>No I don't</Button>
            <Button type='submit' variant='default'>Create a Stream</Button>
          </DialogFooter>
        </form>
      </Form>
    )
  }

  return (
    // Add Dialog component to add a new collection with collection name detail.
    <Dialog>
      <DialogTrigger asChild>
        <Button variant='white' size='lg'>
          <Plus size={16} />
          Add Collection
        </Button>
      </DialogTrigger>
      <DialogContent className='sm:max-w-[425px]'>
        {LiveStreamOption ? LiveStreamOptions() : CollectionForm()}
      </DialogContent>
    </Dialog>
  )
}
