import { useState } from 'react'
import {
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from '@/components/ui/table'
import PropTypes from 'prop-types'
import { convertEpochToTimezone } from '@/utils/helper'
import { Badge } from '@/components/ui/badge'
import { Card } from '@/components/ui/card'
import { DataTablePagination } from '@/components/data-table-pagination'
import { Link } from 'react-router'
import { routePaths } from '@/utils/routePaths'
import { Pencil } from 'lucide-react'
import { CopyButton } from '@/components/ui/copy-button'

export function AssetListTable ({ data, userData, setIsDialogOpen }) {
  const [rowSelection, setRowSelection] = useState({})
  const [columnVisibility, setColumnVisibility] = useState({})
  const [columnFilters, setColumnFilters] = useState([])
  const [sorting, setSorting] = useState([])

  const renderShowStatus = (item) => {
    if (item === 'complete') {
      return <Badge variant='success' size='sm'>{item}</Badge>
    } else if (item === 'created') {
      return <Badge variant='outline' size='sm'>{item}</Badge>
    } else if (item === 'active') {
      return <Badge variant='primary' size='sm'>{item}</Badge>
    } else {
      return <Badge variant='secondary' size='sm'>{item}</Badge>
    }
  }

  const columns = [
    {
      id: 'title',
      accessorKey: 'title',
      header: 'Title',
      cell: ({ row }) => <div className='flex items-center gap-1 group'><Link to={routePaths.stream.assets.info.replace(':assetID', row.original.live_asset_id)} className='text-primary'>{row.original.input.title}</Link> <Pencil className='w-3 h-3 text-muted-foreground cursor-pointer group-hover:opacity-100 opacity-0' onClick={() => setIsDialogOpen(row.original)} /></div> || '-'
    },
    {
      id: 'live_asset_id',
      accessorKey: 'live_asset_id',
      header: 'Stream ID',
      cell: ({ getValue }) => <div className='flex items-center gap-2'>{getValue()} <CopyButton data={getValue()} message='Asset ID copied to clipboard' tooltip='Copy Stream ID' /></div> || '-'
    },
    {
      id: 'status',
      accessorKey: 'status',
      header: 'Status',
      cell: ({ getValue }) => renderShowStatus(getValue()) || '-'
    },
    {
      id: 'created_at',
      accessorKey: 'created_at',
      header: 'Created At',
      cell: ({ getValue }) => getValue() ? convertEpochToTimezone(getValue(), userData.timezone) : '-'
    }
  ]

  const table = useReactTable({
    data: data.all_live_assets || [],
    columns,
    state: {
      sorting,
      columnVisibility,
      rowSelection,
      columnFilters
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues()
  })

  return (
    <div className='space-y-4'>
      <Card className='rounded-lg border shadow-none'>
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id} className='group/row'>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead
                      key={header.id}
                      colSpan={header.colSpan}
                      className={header.column.columnDef.meta?.className ?? ''}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                    </TableHead>
                  )
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length
              ? (
                  table.getRowModel().rows.map((row) => (
                    <TableRow
                      key={row.id}
                      data-state={row.getIsSelected() && 'selected'}
                      className='group/row'
                    >
                      {row.getVisibleCells().map((cell) => (
                        <TableCell
                          key={cell.id}
                          className={cell.column.columnDef.meta?.className ?? ''}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
                )
              : (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    className='h-24 text-center'
                  >
                    No results.
                  </TableCell>
                </TableRow>
                )}
          </TableBody>
        </Table>
      </Card>
      <DataTablePagination table={table} />
    </div>
  )
}

AssetListTable.propTypes = {
  data: PropTypes.object.isRequired,
  userData: PropTypes.object.isRequired,
  sourceId: PropTypes.string.isRequired,
  setIsDialogOpen: PropTypes.func.isRequired
}
