import { Button } from '@/components/ui/button'
import PropTypes from 'prop-types'
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { Alert, AlertDescription } from '@/components/ui/alert'
import { Badge } from '@/components/ui/badge'
import { CopyButton } from '@/components/ui/copy-button'
import { Input } from 'components/ui/input'
import { Label } from 'components/ui/label'

export default function CopyKeyDialog ({ copyKeyModal, setCopyKeyModal }) {
  return (
    <Dialog open={copyKeyModal} onOpenChange={setCopyKeyModal}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>API key successfully created</DialogTitle>
        </DialogHeader>
        <div className='flex flex-col gap-2 py-6'>
          <div className='flex gap-2 items-center'>
            <Label>Key Name: </Label>
            <Badge variant='primary' size='lg'>{copyKeyModal?.token_name}</Badge>
          </div>
          <div className='flex gap-2 items-center'>
            <Input className='font-heading text-md font-medium tracking-tight t' value={copyKeyModal?.token} disabled readOnly />
            <CopyButton className="border text-lg h-9 w-9 p-2 rounded-sm" data={copyKeyModal?.token} message='API key copied to clipboard' tooltip='Copy API Key' />
          </div>
          <Alert variant='warning' className='w-full p-2'>
            <AlertDescription className='text-xs'>
              Please copy this key somewhere safe, it will not be visible later.
            </AlertDescription>
          </Alert>
        </div>
        <DialogFooter>
          <Button type='button' variant='outline' onClick={() => setCopyKeyModal(false)} className='cursor-pointer'>Close</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

CopyKeyDialog.propTypes = {
  copyKeyModal: PropTypes.bool.isRequired,
  setCopyKeyModal: PropTypes.func.isRequired
}
