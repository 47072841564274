import * as React from 'react'
import { Slot } from '@radix-ui/react-slot'
import { Controller, FormProvider, useFormContext, useFormState } from 'react-hook-form'

import { cn } from '@/utils/helper'
import { Label } from '@/components/ui/label'

const Form = FormProvider

const FormFieldContext = React.createContext({})

const FormField = (
  {
    ...props
  }
) => {
  return (
    (
      <FormFieldContext.Provider value={{ name: props.name }}>
        <Controller {...props} />
      </FormFieldContext.Provider>
    )
  )
}

const useFormField = () => {
  const fieldContext = React.useContext(FormFieldContext)
  const itemContext = React.useContext(FormItemContext)
  const { getFieldState } = useFormContext()
  const formState = useFormState({ name: fieldContext.name })
  const fieldState = getFieldState(fieldContext.name, formState)

  if (!fieldContext) {
    throw new Error('useFormField should be used within <FormField>')
  }

  const { id } = itemContext

  return {
    id,
    name: fieldContext.name,
    formItemId: `${id}-form-item`,
    formDescriptionId: `${id}-form-item-description`,
    formMessageId: `${id}-form-item-message`,
    ...fieldState
  }
}

const FormItemContext = React.createContext({})

function FormItem ({
  className,
  ...props
}) {
  const id = React.useId()

  return (
    (
      <FormItemContext.Provider value={{ id }}>
        <div data-slot='form-item' className={cn('grid gap-2', className)} {...props} />
      </FormItemContext.Provider>
    )
  )
}

function FormLabel ({
  className,
  children,
  required,
  ...props
}) {
  const { error, formItemId } = useFormField()

  return (
    (
      <Label
        data-slot='form-label'
        data-error={!!error}
        className={cn('data-[error=true]:text-destructive-foreground text-xs', className)}
        htmlFor={formItemId}
        {...props}
      >
        {children}
        {required && <span className='text-destructive'>*</span>}
      </Label>
    )
  )
}

function FormControl ({
  ...props
}) {
  const { error, formItemId, formDescriptionId, formMessageId } = useFormField()

  return (
    (
      <Slot
        data-slot='form-control'
        id={formItemId}
        aria-describedby={
        !error
          ? `${formDescriptionId}`
          : `${formDescriptionId} ${formMessageId}`
      }
        aria-invalid={!!error}
        {...props}
      />
    )
  )
}

function FormDescription ({
  className,
  ...props
}) {
  const { formDescriptionId } = useFormField()

  return (
    (
      <div
        data-slot='form-description'
        id={formDescriptionId}
        className={cn('text-muted-foreground text-sm', className)}
        {...props}
      />
    )
  )
}

function FormMessage ({
  className,
  ...props
}) {
  const { error, formMessageId } = useFormField()
  const body = error ? String(error?.message ?? '') : props.children

  if (!body) {
    return null
  }

  return (
    (
      <p
        data-slot='form-message'
        id={formMessageId}
        className={cn('text-errored text-xs', className)}
        {...props}
      >
        {body}
      </p>
    )
  )
}

export {
  useFormField,
  Form,
  FormItem,
  FormLabel,
  FormControl,
  FormDescription,
  FormMessage,
  FormField
}
