import http from '@/utils/http'
import { toast } from 'sonner'
import useQuery from '@/utils/useQuery'
import { endpoints } from '@/utils/endpoints'
import ContentSection from '@/components/shared-sections/content-section'
import { useState } from 'react'
import WidevineCard from './widevine-card'
import FairPlayForm from './fairplay-form'
import DeleteActionDialog from '@/components/delete-action-dialog'
import { SkeletonBasics } from '@/components/shimmers/basic'

export default function DRMCredentials () {
  const [isDeleteOpen, setIsDeleteOpen] = useState(null)

  const { data: widevineData, setData: setWidewindeData } = useQuery({
    url: `${endpoints.organization.drm.widevine.base}`
  })

  const { data: fairplayData, setData: setFairPlayData } = useQuery({
    url: `${endpoints.organization.drm.fairplay.base}`
  })

  if (!widevineData || !fairplayData) return <SkeletonBasics />

  async function _deleteFairPlay () {
    try {
      await http({}).delete(endpoints.organization.drm.fairplay.base)
      setFairPlayData({})
      setIsDeleteOpen(false)
      toast('Fairplay credentials deleted successfully')
    } catch (error) {
      toast(error?.error?.message)
    }
  }

  return (
    <ContentSection
      title='DRM Credentials'
      desc='Webhooks allow you to build or set up integrations that subscribe to certain events on Gumlet APIs.'
      className='max-w-none'
    >
      <div className='flex flex-col gap-6'>
        <WidevineCard widevineData={widevineData} setWidewindeData={setWidewindeData} />
        <FairPlayForm fairplayData={fairplayData} setIsDeleteOpen={setIsDeleteOpen} />
      </div>
      {
        isDeleteOpen && <DeleteActionDialog isDeleteOpen={isDeleteOpen} setIsDeleteOpen={setIsDeleteOpen} onSubmit={_deleteFairPlay} title='This action can not be undone. Existing fairplay credentials will be deleted.' />
      }
    </ContentSection>
  )
}
