import { Alert, AlertTitle } from '@/components/ui/alert'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card'
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow
} from '@/components/ui/table'
import PropTypes from 'prop-types'

export default function UpcomingInvoices ({
  upcomingInvoice,
  billing
}) {
  return (
    <>
      {upcomingInvoice?.trial_end_date && <Alert variant='default'>
        <AlertTitle>
          Your trial will end on <b>{upcomingInvoice?.trial_end_date}</b>.
        </AlertTitle>
      </Alert>}
      <p className='text-sm text-gray-500 mb-4' />
      <Card className='rounded-lg border shadow-none'>
        <CardHeader>
          <CardTitle>Upcoming Invoice</CardTitle>
          <CardDescription>
            <span>This is a preview of the invoice that will be generated on{' '}</span><b>{upcomingInvoice?.next_invoice_date}</b>. It may change if the
            subscription is updated.
          </CardDescription>
          <CardContent className='pt-6 px-0 pb-0'>
            <Table className='p-0'>
              <TableHeader>
                <TableRow>
                  <TableHead className='w-[350px]'>DESCRIPTION</TableHead>
                  <TableHead>QTY</TableHead>
                  <TableHead>UNIT PRICE ( {billing.currency} )</TableHead>
                  <TableHead className='text-right'>AMOUNT ( {billing.currency} )</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                <TableRow>
                  <TableCell className='font-semibold text-accent-foreground' colSpan={4}>
                    {upcomingInvoice?.invoice_start_date} -{' '}
                    {upcomingInvoice?.invoice_last_date}
                  </TableCell>
                </TableRow>
                {
                  upcomingInvoice?.upcoming_invoice?.lines.data.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell className='text-sm text-muted-foreground'>{item.description}</TableCell>
                      <TableCell className='text-sm text-muted-foreground'>{item.quantity}</TableCell>
                      <TableCell className='text-sm text-muted-foreground'>{item.unit_price} {item.currency}</TableCell>
                      <TableCell className='text-right text-sm text-muted-foreground'>{item.amount}</TableCell>
                    </TableRow>
                  ))
                }
              </TableBody>
              <TableFooter>
                {
                  billing.currency === 'INR' &&
                    <TableRow>
                      <TableCell className='font-semibold text-accent-foreground' colSpan={3}>Subtotal:</TableCell>
                      <TableCell className='text-right'>{upcomingInvoice?.upcoming_invoice?.subtotal}</TableCell>
                    </TableRow>
                }
                {
                  upcomingInvoice?.upcoming_invoice?.total_tax_amounts?.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell className='font-semibold text-accent-foreground' colSpan={3}>{item.tax_type} (%)</TableCell>
                      <TableCell className='text-right'>{(item.amount / 100).toFixed(2)}</TableCell>
                    </TableRow>
                  ))
                }
                <TableRow className='bg-accent'>
                  <TableCell className='font-semibold text-accent-foreground' colSpan={3}>Total Amount:</TableCell>
                  <TableCell className='text-right'>{upcomingInvoice?.current_month_usage} {billing.currency}</TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </CardContent>
        </CardHeader>

      </Card>

    </>
  )
}

UpcomingInvoices.propTypes = {
  upcomingInvoice: PropTypes.object.isRequired,
  billing: PropTypes.object.isRequired
}
