import { Header } from '@/components/ui-layout/header'
import { SectionHeader } from '@/components/ui-layout/section-header'
import { BreadcrumbComponents } from '@/components/ui/breadcrumb'
import PricingTabs from './pricing-tabs'
import { use, useState } from 'react'
import { DataContext } from '@/contexts/DataProvider'
import DowngradeModal from './downgrade-modal'
import PlanSummary from './plan-summary'
import { PopupModal } from 'react-calendly'
import useQuery from '@/utils/useQuery'
import { endpoints } from '@/utils/endpoints'
import http from '@/utils/http'
import { toast } from 'sonner'
import StripeElements from '@/components/stripe-elements'
import { SkeletonCard } from '@/components/shimmers/card'
import { ScrollArea } from '@/components/ui/scroll-area'
import { useNavigate } from 'react-router'

export default function PlanChange () {
  const { orgData, userData } = use(DataContext)
  const navigate = useNavigate()
  const [isYearly, setIsYearly] = useState(false)
  const [confirmPlan, setConfirmPlan] = useState({})
  const [downgradeModal, setDowngradeModal] = useState(null)
  const [openCalendly, setOpenCalendly] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [showStripeElement, setShowStripeElement] = useState(null)

  const { data: coupons, isLoading: couponsLoading } = useQuery({
    url: `${endpoints.organization.coupon.base}`
  })

  const { data: cards, refetch, isLoading: cardsLoading } = useQuery({
    url: `${endpoints.organization.billing.card.base}`
  })

  const handlePlanChange = (plan, planType) => {
    const formattedPlan = {
      ...plan,
      price: parseInt(plan.price.replace('$', ''), 10),
      yearlyPrice: parseInt(plan.yearlyPrice.replace('$', ''), 10)
    }
    if (cards && cards.cards.length === 0) {
      setShowStripeElement({ plan: formattedPlan, planType })
    } else {
      setConfirmPlan({ plan: formattedPlan, planType })
    }
  }

  async function _confirmPlanChange () {
    const payload = {
      plan: confirmPlan.plan.id,
      plan_type: confirmPlan.planType,
      plan_cycle: isYearly ? 'yearly' : 'monthly'
    }
    try {
      setIsLoading(true)
      const response = await http({}).post(endpoints.organization.billing.plan, payload)
      if (response && response.success) {
        navigate('/dashboard/billing')
      }
    } catch (error) {
      toast(error.message || error.error.message)
    } finally {
      setIsLoading(false)
    }
  }

  const handleStripeSuccess = (action) => {
    if (action === 'close') {
      setShowStripeElement(null)
      setConfirmPlan({})
    } else if (action === 'success' && showStripeElement) {
      setConfirmPlan(showStripeElement)
      setShowStripeElement(null)
    }
  }

  return (
    <>
      <Header>
        <BreadcrumbComponents
          Breadcrum1='Billling'
          Breadcrum1Link='/organization/billing'
          Breadcrum2='Plans'
        />
      </Header>
      {couponsLoading || cardsLoading
        ? <SkeletonCard description='Just a minute' />
        : <>
          <SectionHeader fixed>
            <ScrollArea className='faded-bottom overflow-hidden -mx-4 flex-1 scroll-smooth px-4 md:pb-8'>
              {
              Object.keys(confirmPlan).length > 0 || showStripeElement
                ? showStripeElement ? <div className='mx-auto rounded-lg border bg-card text-card-foreground flex flex-col p-4 max-w-md'><StripeElements setIsOpen={handleStripeSuccess} refetchCards={refetch} /></div> : <PlanSummary confirmPlan={confirmPlan} setConfirmPlan={setConfirmPlan} isYearly={isYearly} coupons={coupons} _confirmPlanChange={_confirmPlanChange} isLoading={isLoading} />
                : <div className='rounded-lg border bg-card text-card-foreground flex flex-col py-2 lg:mt-10'><PricingTabs orgData={orgData} setDowngradeModal={setDowngradeModal} setConfirmPlan={setConfirmPlan} isYearly={isYearly} setIsYearly={setIsYearly} handlePlanChange={handlePlanChange} setOpenCalendly={setOpenCalendly} /></div>
              }
            </ScrollArea>
          </SectionHeader>
          {
            downgradeModal && <DowngradeModal downgradeModal={downgradeModal} setDowngradeModal={setDowngradeModal} orgData={orgData} handlePlanChange={handlePlanChange} />
          }
          <PopupModal
            url='https://meetings.hubspot.com/pratik-w/inbound-team-?uuid=3c2bc46a-f982-49fb-b636-0f84240e9e19'
            prefill={{
              email: userData?.email
            }}
            rootElement={document.getElementById('root')}
            open={openCalendly}
            onModalClose={() => setOpenCalendly(false)}
          />
        </>}
    </>
  )
}
