import { DataContext } from '@/contexts/DataProvider'
import { use, useState } from 'react'
import { ScrollArea } from 'components/ui/scroll-area'
import { SkeletonBasics } from 'components/shimmers/basic'

export default function Reviews () {
  const [isLoading, setIsLoading] = useState(true)
  const { userData } = use(DataContext)
  return (
    <ScrollArea className='faded-bottom overflow-hidden -mx-4 flex-1 scroll-smooth px-4 md:pb-8'>
      {isLoading && <SkeletonBasics />} {/* Or your custom shimmer component */}
      <iframe
        onLoad={() => setIsLoading(false)}
        src={`https://webapp.gumlet.com/page/g2-redirect/?email=${userData.email}`}
        className={`h-[620px] w-full transition-opacity duration-300 ${
          isLoading ? 'opacity-0' : 'opacity-100',
          'rounded-2xl'
        }`}
      />
    </ScrollArea>
  )
}
