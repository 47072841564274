import React from 'react'
import http from './http'
import { toast } from 'sonner'

const useQuery = ({ url, errorResponse, hasCondition }) => {
  const [apiData, setApiData] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(true)
  const [error, setError] = React.useState(null)

  async function fetchData () {
    try {
      setIsLoading(true)
      setError(null)
      const data = await http({ errorResponse }).get(url)
      setApiData(data)
    } catch (error) {
      setError(error)
      if (errorResponse && errorResponse[error?.response?.status]) {
        setApiData(errorResponse[error?.response?.status])
      }
      toast.error(error?.error?.message || error?.error?.error?.message || 'Something went wrong')
    } finally {
      setIsLoading(false)
    }
  }

  React.useEffect(() => {
    if (hasCondition) return
    fetchData()
  }, [url, errorResponse])

  return { data: apiData, setData: setApiData, refetch: fetchData, isLoading, error }
}

export default useQuery
