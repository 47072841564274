import { useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@/components/ui/button'
import { LoadingSpinner } from '@/components/ui/spinner'
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog'

export default function DeleteActionDialog ({
  isDeleteOpen,
  setIsDeleteOpen,
  onSubmit,
  title,
  btnVariant,
  btnText
}) {
  const [loading, setLoading] = useState(false)

  const handleDelete = async () => {
    setLoading(true)
    try {
      await onSubmit(isDeleteOpen.id)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }
  return (
    <Dialog open={isDeleteOpen} onOpenChange={setIsDeleteOpen}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Action</DialogTitle>
          <DialogDescription className='mt-2'>
            {title}
          </DialogDescription>
        </DialogHeader>
        <DialogFooter className='mt-4'>
          <Button onClick={() => setIsDeleteOpen(false)} className='cursor-pointer' variant='outline'>Close</Button>
          <Button onClick={handleDelete} disabled={loading} className='cursor-pointer' variant={btnVariant || 'delete'}>{loading && <LoadingSpinner />} {btnText || 'Delete'}</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

DeleteActionDialog.propTypes = {
  isDeleteOpen: PropTypes.bool.isRequired,
  setIsDeleteOpen: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  btnVariant: PropTypes.string,
  btnText: PropTypes.string
}
