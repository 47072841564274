import { Button } from '@/components/ui/button'
import { Separator } from '@/components/ui/separator'
import { BadgeCheck, MessageSquareText } from 'lucide-react'
import PropTypes from 'prop-types'

export default function ImagePricing ({ PRICING_PLANS, getDynamicButton, isYearly, setOpenCalendly }) {
  return (
    PRICING_PLANS.image.plans.map((plan, index) => (
      <div
        key={index}
        className='relative rounded-lg border p-6 border-border flex flex-col min-h-[420px]'
      >
        <div className='flex flex-col flex-grow'>
          <div className='flex flex-col gap-4'>
            <div className='flex flex-col gap-1'>
              <span className='text-md font-medium'>{plan.plan}</span>
              <span className='text-xs text-foreground/60'>{plan.title}</span>
            </div>
            <span className='text-3xl font-bold'>
              {plan.plan === 'Custom' ? 'On Quote' : (isYearly ? plan.yearlyPrice : plan.price)}{' '}
              {plan.plan !== 'Custom' && (
                <span className='text-xs text-foreground/60 font-medium'>/month</span>
              )}
            </span>
          </div>
          <Separator className='my-4' />
          <ul className='space-y-4 flex-grow'>
            {plan.details.map((detail, idx) => (
              <li
                key={idx}
                className='flex items-center gap-2 text-sm font-medium text-foreground/60'
              >
                <BadgeCheck className='h-4 w-4' /> {detail.title}
              </li>
            ))}
          </ul>
        </div>
        <div className='mt-8'>
          {plan.plan_name_alias === 'enterprise'
            ? (
              <Button size='sm' className='w-full group' variant='default' onClick={() => setOpenCalendly(true)}>
                Meet our Team <MessageSquareText className='w-4 h-4 opacity-0 transition-all group-hover:opacity-100 group-hover:translate-x-1' />
              </Button>
              )
            : getDynamicButton(plan, 'image')}
        </div>
      </div>
    ))
  )
}

ImagePricing.propTypes = {
  PRICING_PLANS: PropTypes.object.isRequired,
  getDynamicButton: PropTypes.func.isRequired,
  isYearly: PropTypes.bool.isRequired,
  setOpenCalendly: PropTypes.func.isRequired
}
