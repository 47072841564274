import ContentSection from '@/components/shared-sections/content-section'
import WebhookForm from './webhook-form'

export default function AddWebhook () {
  return (
    <ContentSection
      title='Add Webhook'
      desc='Webhooks allow you to build or set up integrations that subscribe to certain events on Gumlet APIs.'
    >
      <WebhookForm />
    </ContentSection>
  )
}
