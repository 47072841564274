import { useState } from 'react'
import { Button } from '@/components/ui/button'
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@/components/ui/card'
import { RefreshCcw } from 'lucide-react'
import PropTypes from 'prop-types'
import { toast } from 'sonner'
import { endpoints } from '@/utils/endpoints'
import http from '@/utils/http'
import { CopyButton } from '@/components/ui/copy-button'
import DeleteActionDialog from 'components/delete-action-dialog'

export default function WidevineCard ({ widevineData, setWidewindeData }) {
  const [isRegenerateOpen, setIsRegenerateOpen] = useState(false)

  async function _regenerateWidevineCredentials () {
    const promise = http({}).post(`${endpoints.organization.drm.widevine.generate}`)
    toast.promise(promise, {
      loading: 'Regenerating Widevine credentials...',
      success: 'Widevine credentials regenerated successfully',
      error: 'Failed to regenerate Widevine credentials'
    })
    try {
      const response = await promise
      setWidewindeData(response)
    } catch (error) {
      console.log('Failed to regenerate Widevine credentials', error)
    } finally {
      setIsRegenerateOpen(false)
    }
  }

  return (
    <>
      <Card className='rounded-lg border shadow-none'>
        <CardHeader>
          <CardTitle className='text-base'>Widevine Setup</CardTitle>
        </CardHeader>
        <CardContent className='space-y-6'>
          <div className='flex gap-4 text-sm items-center'>
            <span className='font-medium w-[200px]'>License Proxy URL :</span>
            {widevineData.proxy_url
              ? (
                <span className='flex gap-2 items-center text-muted-foreground break-all'>
                  {widevineData.proxy_url}
                  <CopyButton data={widevineData.proxy_url} message='Proxy URL copied to clipboard' tooltip='Copy Proxy URL' />
                </span>
                )
              : (
                <span>-</span>
                )}
          </div>
          <div className='flex gap-4 text-sm items-center'>
            <span className='font-medium w-[200px]'>Proxy URL Signing Secret :</span>
            {widevineData.secret
              ? (
                <span className='flex gap-2 items-center text-muted-foreground break-all'>
                  {widevineData.secret}
                  <CopyButton data={widevineData.secret} message='Secret key copied to clipboard' tooltip='Copy Secret Key' />
                </span>
                )
              : (
                <span>-</span>
                )}
          </div>
        </CardContent>
        <CardFooter className='flex justify-end'>
          <Button variant='white' className='cursor-pointer text-sm' onClick={() => setIsRegenerateOpen(true)}>
            <RefreshCcw className='h-3 w-3 mr-2' /> Regenerate Signing Secret
          </Button>
        </CardFooter>
      </Card>
      {
        isRegenerateOpen && <DeleteActionDialog isDeleteOpen={isRegenerateOpen} setIsDeleteOpen={setIsRegenerateOpen} onSubmit={_regenerateWidevineCredentials} title='Are you sure you want to re-generate? All integrations using this secrent will need update.' btnVariant='destructive' btnText='Confirm' />
      }
    </>
  )
}

WidevineCard.propTypes = {
  widevineData: PropTypes.shape({
    proxy_url: PropTypes.string,
    secret: PropTypes.string
  }),
  setWidewindeData: PropTypes.func
}
