import { useLocation } from 'react-router'
import { getBreadcrumbItems } from '@/utils/helper'
import { SectionHeader } from '@/components/ui-layout/section-header'
import { Separator } from '@/components/ui/separator'
import { Header } from '@/components/ui-layout/header'
import {
  BreadcrumbComponents
} from '@/components/ui/breadcrumb'

import {
  User,
  ShieldCheck,
  Fingerprint,
  // Send,
  KeyRound,
  SquareActivity
} from 'lucide-react'
import SidebarNav from '@/components/sidebar-nav'
import { Outlet } from 'react-router'
import { RoleBasedRouteChildren } from '@/utils/roleBasedRoute'
import { SkeletonBasics } from '@/components/shimmers/basic'
import { DataContext } from '@/contexts/DataProvider'
import { use } from 'react'
import Unauthorized from '@/app/401'

export default function UserProfile () {
  const location = useLocation()
  let breadcrumbItem = getBreadcrumbItems(location.pathname)
  // case to handle the breadcrumb for some routes
  switch (breadcrumbItem) {
    case 'Apikey':
      breadcrumbItem = 'API Keys'
      break
    case 'Two-factor':
      breadcrumbItem = 'Two Factor'
      break
    default:
      break
  }
  const { userData } = use(DataContext)
  const { isAllowed, loading } = RoleBasedRouteChildren()
  return (
    <>
      <Header>
        <BreadcrumbComponents
          Breadcrum1='User'
          Breadcrum2={breadcrumbItem}
        />
      </Header>

      <SectionHeader fixed>
        <div className='space-y-0.5'>
          <h1 className='text-2xl font-medium md:text-3xl'>
            Personal Settings
          </h1>
        </div>
        <Separator className='my-4 lg:my-6' />
        <div className='flex flex-1 flex-col space-y-2 overflow-hidden md:space-y-2 lg:flex-row lg:space-x-12 lg:space-y-0'>
          <aside className='top-0 lg:sticky lg:w-1/5'>
            <SidebarNav items={
              sidebarNavItems.filter(item => {
                // if provider is not local then don't send the Two factor object is not in the sidebar
                if (userData?.provider !== 'local') {
                  return item.title !== 'Two Factor'
                }
                return item
              })
            }
            />
          </aside>

          <div className='flex w-full overflow-y-hidden p-1 pr-4'>
            {loading ? <SkeletonBasics /> : isAllowed ? <Outlet /> : <Unauthorized />}
          </div>
        </div>
      </SectionHeader>
    </>
  )
}

const sidebarNavItems = [
  {
    title: 'Profile',
    icon: <User size={18} />,
    href: '/user/profile'
  },
  {
    title: 'Password',
    icon: <ShieldCheck size={18} />,
    href: '/user/password'
  },
  {
    title: 'Two Factor',
    icon: <Fingerprint size={18} />,
    href: '/user/two-factor'
  },
  {
    title: 'API Keys',
    icon: <KeyRound size={18} />,
    href: '/user/apikey'
  },
  {
    title: 'Active Sessions',
    icon: <SquareActivity size={18} />,
    href: '/user/sessions'
  }
  // {
  //   title: 'Send Summary',
  //   icon: <Send size={18} />,
  //   href: '/user/summary'
  // }
]
