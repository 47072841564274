import React from 'react'
import { cn } from '@/utils/helper'
import { Separator } from '@/components/ui/separator'
import { SidebarTrigger } from '@/components/ui/sidebar'
import { useTheme } from '@/components/theme-provider'
import { Button } from '@/components/ui/button'
import { Moon, Sun, BellRing } from 'lucide-react'
import { Search } from '@/components/search'

export const Header = ({
  className,
  fixed,
  children,
  ...props
}) => {
  const [offset, setOffset] = React.useState(0)
  const { setTheme, theme } = useTheme()

  React.useEffect(() => {
    const onScroll = () => {
      setOffset(document.body.scrollTop || document.documentElement.scrollTop)
    }

    // Add scroll listener to the body
    document.addEventListener('scroll', onScroll, { passive: true })

    // Clean up the event listener on unmount
    return () => document.removeEventListener('scroll', onScroll)
  }, [])

  return (
    <header
      className={cn(
        'flex h-16 items-center gap-3 bg-background p-4 sm:gap-4',
        fixed && 'header-fixed peer/header fixed z-50 w-[inherit] rounded-md',
        offset > 10 && fixed ? 'shadow' : 'shadow-none',
        className
      )}
      {...props}
    >
      <SidebarTrigger variant='outline' className='scale-125 sm:scale-100' />
      <Separator orientation='vertical' className='h-6' />
      {children}
      <div className='flex flex-1 items-center justify-between gap-3 md:justify-end mr-4'>
        <Search />
        <Button
          variant='ghost'
          size='icon'
          onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
          className='cursor-pointer'
        >
          {theme === 'dark'
            ? (
              <Sun className='h-[1.2rem] w-[1.2rem] rotate-90 scale-0 transition-all dark:-rotate-0 dark:scale-100' />
              )
            : (
              <Moon className='absolute h-[1.2rem] w-[1.2rem] rotate-0 scale-100 transition-all dark:rotate-90 dark:scale-0' />
              )}
        </Button>
        <Button
          title='Updates'
          variant='ghost'
          size='icon'
          className='cursor-pointer'
          data-canny-changelog
        >
          <span className='sr-only'>Canny Notifications</span>
          <BellRing />
        </Button>
      </div>
    </header>
  )
}

Header.displayName = 'Header'
