import { useLocation } from 'react-router'
import { getBreadcrumbItems } from '@/utils/helper'
import { SectionHeader } from '@/components/ui-layout/section-header'
import { Separator } from '@/components/ui/separator'
import { Header } from '@/components/ui-layout/header'
import {
  BreadcrumbComponents
} from '@/components/ui/breadcrumb'

import {
  User,
  Webhook,
  ShieldCheck,
  Tag
} from 'lucide-react'
import SidebarNav from '@/components/sidebar-nav'
import { Outlet } from 'react-router'
import { RoleBasedRouteChildren } from '@/utils/roleBasedRoute'
import { SkeletonBasics } from '@/components/shimmers/basic'
import Unauthorized from '../401'

export default function Organization () {
  const location = useLocation()
  let breadcrumbItem = getBreadcrumbItems(location.pathname)
  // case to handle the breadcrumb for some routes
  switch (breadcrumbItem) {
    case 'Drm-credentials':
      breadcrumbItem = 'DRM'
      break
    default:
      break
  }
  const { isAllowed, loading } = RoleBasedRouteChildren()
  return (
    <>
      <Header>
        <BreadcrumbComponents
          Breadcrum1='Organization'
          Breadcrum2={breadcrumbItem === 'Add' ? 'Webhooks' : breadcrumbItem}
        />
      </Header>

      <SectionHeader fixed>
        <div className='space-y-0.5'>
          <h1 className='text-2xl font-medium md:text-3xl'>
            Organization Settings
          </h1>
        </div>
        <Separator className='my-4 lg:my-6' />
        <div className='flex flex-1 flex-col space-y-2 overflow-hidden md:space-y-2 lg:flex-row lg:space-x-12 lg:space-y-0'>
          <aside className='top-0 lg:sticky lg:w-1/5'>
            <SidebarNav items={sidebarNavItems} />
          </aside>
          <div className='flex w-full overflow-y-hidden p-1 pr-4'>
            {loading ? <SkeletonBasics /> : isAllowed ? <Outlet /> : <Unauthorized />}
          </div>
        </div>
      </SectionHeader>
    </>
  )
}

const sidebarNavItems = [
  {
    title: 'Manage Users',
    icon: <User size={18} />,
    href: '/organization/accounts'
  },
  {
    title: 'Webhooks',
    icon: <Webhook size={18} />,
    href: '/organization/webhooks'
  },
  {
    title: 'DRM Credentials',
    icon: <ShieldCheck size={18} />,
    href: '/organization/drm-credentials'
  },
  {
    title: 'Coupons',
    icon: <Tag size={18} />,
    href: '/organization/coupons'
  }
]
