'use client'
import PropTypes from 'prop-types'
import {
  ChevronsUpDown,
  LogOut,
  ReceiptText,
  UserPen,
  Globe
} from 'lucide-react'
import {
  Avatar,
  AvatarFallback
} from '@/components/ui/avatar'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from '@/components/ui/dropdown-menu'
import {
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  useSidebar
} from '@/components/ui/sidebar'
import { Link } from 'react-router'
import { createAvatarFallback } from '@/utils/helper'
import { routePaths } from '@/utils/routePaths'

export function NavProfile ({ userData }) {
  const { isMobile } = useSidebar()

  const renderAvatar = () => (
    <Avatar className='h-8 w-8 rounded-lg'>
      <AvatarFallback className='rounded-lg uppercase'>
        {createAvatarFallback(userData?.name, userData?.email)}
      </AvatarFallback>
    </Avatar>
  )

  const menuItems = [
    {
      label: 'Billing',
      icon: ReceiptText,
      url: routePaths.organization.billing.base
    },
    {
      label: 'Organization',
      icon: Globe,
      url: routePaths.organization.accounts.base
    },
    {
      label: 'Profile',
      icon: UserPen,
      url: routePaths.user.profile
    }
  ]

  return (
    <SidebarMenu>
      <SidebarMenuItem>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <SidebarMenuButton
              size='lg'
              className='data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground cursor-pointer'
            >
              {renderAvatar()}
              <div className='grid flex-1 text-left text-sm leading-tight'>
                <span className='truncate font-semibold'>{userData?.name}</span>
              </div>
              <ChevronsUpDown className='ml-auto size-4' />
            </SidebarMenuButton>
          </DropdownMenuTrigger>

          <DropdownMenuContent
            className='w-(--radix-dropdown-menu-trigger-width) min-w-48 rounded-lg'
            side={isMobile ? 'bottom' : 'left'}
            align='end'
            sideOffset={4}
          >
            <DropdownMenuLabel className='p-0 font-normal'>
              <div className='flex items-center gap-2 px-1 py-1.5 text-left text-sm'>
                {renderAvatar()}
                <div className='grid flex-1 text-left text-sm leading-tight'>
                  <span className='truncate font-semibold'>{userData?.name}</span>
                  <span className='truncate text-xs'>{userData?.roles?.join(', ')}</span>
                </div>
              </div>
            </DropdownMenuLabel>

            <DropdownMenuSeparator />

            <DropdownMenuGroup>
              {menuItems.map(({ label, icon: Icon, url }) => (
                <DropdownMenuItem asChild key={label}>
                  <Link to={url} className='flex items-center gap-2 cursor-pointer'>
                    <Icon className='size-4' />
                    {label}
                  </Link>
                </DropdownMenuItem>
              ))}
            </DropdownMenuGroup>

            <DropdownMenuSeparator />

            <DropdownMenuItem asChild>
              <Link to='/logout' className='flex items-center gap-2 cursor-pointer'>
                <LogOut className='size-4' />
                Log out
              </Link>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </SidebarMenuItem>
    </SidebarMenu>
  )
}

NavProfile.propTypes = {
  userData: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    roles: PropTypes.arrayOf(PropTypes.string)
  }).isRequired
}
