import { Card, CardContent } from '@/components/ui/card'
import { Separator } from '@/components/ui/separator'
import { Badge } from '@/components/ui/badge'
import { convertEpochToTimezone } from '@/utils/helper'
import PropTypes from 'prop-types'
import { CopyButton } from '@/components/ui/copy-button'

const InfoItem = ({ label, value, isLast, useBadge, isCopyable }) => {
  return (
    <>
      <div className='flex flex-col sm:flex-row sm:items-center gap-2 sm:gap-4'>
        <span className='font-medium text-sm w-32'>{label}:</span>
        <div className='flex items-start gap-3 flex-1'>
          {useBadge
            ? (
              <div className='flex items-center gap-2'>
                {Array.isArray(value)
                  ? (
                      value.map((resolution, index) => (
                        <Badge key={index} variant='outline' className='text-xs'>{resolution}</Badge>
                      ))
                    )
                  : (
                    <Badge variant='outline' className='text-xs'>{value}</Badge>
                    )}
              </div>
              )
            : (
              <div className='flex items-center gap-2 flex-1'>
                <span className='text-sm text-muted-foreground break-all'>{value}</span>
                {isCopyable && (
                  <CopyButton data={value} message={`${label} copied to clipboard`} tooltip='Copy Live Asset ID' />
                )}
              </div>
              )}
        </div>
      </div>
      {!isLast && <Separator className='my-4' />}
    </>
  )
}

InfoItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  isLast: PropTypes.bool,
  useBadge: PropTypes.bool,
  isCopyable: PropTypes.bool
}

export default function AssetInfo ({ getLiveAssetApiResponse, userData }) {
  const getValue = (path) => {
    return path.split('.').reduce((obj, key) => obj?.[key], getLiveAssetApiResponse)
  }

  const assetDetails = [
    { label: 'Live Asset ID', value: 'live_asset_id', isCopyable: true },
    { label: 'Resolution', value: 'input.resolution', useBadge: true },
    ...(getValue('status') !== 'preparing'
      ? [
          { label: 'Stream Key', value: 'stream_key', isCopyable: true },
          {
            label: 'Stream URL',
            value: 'stream_url',
            isCopyable: true,
            transform: (url) => url?.split('/').slice(0, -1).join('/')
          }
        ]
      : []),
    { label: 'Status', value: 'status', useBadge: true },
    ...(getValue('output.playback_url') && getValue('status') !== 'preparing'
      ? [
          { label: 'Playback URL', value: 'output.playback_url', isCopyable: true }
        ]
      : []),
    {
      label: 'Created At',
      value: 'created_at',
      transform: (value) => convertEpochToTimezone(value, userData.timezone)
    }
  ]

  return (
    <Card className='rounded-lg border shadow-none'>
      <CardContent className='pt-6'>
        <h3 className='text-md font-semibold mb-8'>Asset Details</h3>
        <div className='space-y-3'>
          {assetDetails.map(({ label, value, transform, useBadge, isCopyable }, index) => (
            <InfoItem
              key={label}
              label={label}
              value={transform ? transform(getValue(value)) : getValue(value)}
              isLast={index === assetDetails.length - 1}
              useBadge={useBadge}
              isCopyable={isCopyable}
            />
          ))}
        </div>
      </CardContent>
    </Card>
  )
}

AssetInfo.propTypes = {
  getLiveAssetApiResponse: PropTypes.object.isRequired,
  userData: PropTypes.object.isRequired
}
