export const authenticators = [{
  name: 'Authy',
  links: [
    { platform: 'iPhone', url: 'https://itunes.apple.com/us/app/authy/id494168017?mt=8' },
    { platform: 'Android', url: 'https://play.google.com/store/apps/details?id=com.authy.authy&hl=en' },
    { platform: 'Chrome', url: 'https://chrome.google.com/webstore/detail/authy/gaedmjdfmmahhbjefcbgaolhhanlaolb?hl=en' },
    { platform: 'Linux', url: 'https://www.authy.com/personal/' },
    { platform: 'OS X', url: 'https://www.authy.com/personal/' },
    { platform: 'BlackBerry', url: 'https://appworld.blackberry.com/webstore/content/38831914/?countrycode=US&lang=en' }
  ]
},
{
  name: 'Google Authenticator',
  links: [
    { platform: 'iPhone', url: 'https://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8' },
    { platform: 'Android', url: 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en' }
  ]
},
{
  name: 'Microsoft Authenticator',
  links: [
    { platform: 'Windows Phone', url: 'https://www.microsoft.com/en-us/store/apps/authenticator/9wzdncrfj3rj' },
    { platform: 'Android', url: 'https://play.google.com/store/apps/details?id=com.microsoft.msa.authenticator' }
  ]
}]
