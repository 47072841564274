import axios from 'axios'
import { config } from '@/config'
import { getHeader } from './header'

const API_ROOT = config.API_URL
const TIMEOUT = 60000

const http = ({
  headerType = 'json',
  baseURL = API_ROOT,
  timeout = TIMEOUT,
  errorResponse = null
}) => {
  const headers = getHeader(headerType)
  const client = axios.create({
    baseURL,
    timeout,
    headers,
    transformRequest: [(data, headers) => {
      // If data is FormData, let Axios handle it without transforming
      if (data instanceof FormData) {
        delete headers['Content-Type'] // Let Axios set it
        return data
      }
      // Otherwise, transform data as needed (e.g., JSON.stringify)
      return JSON.stringify(data)
    }]
  })

  client.interceptors.response.use(handleSuccess, handleError)

  function handleSuccess (response) {
    return response
  }

  function handleError (error) {
    if (!error.response) {
      // No response means network issue (e.g., no internet)
      return Promise.reject({ message: 'No Internet Connection', status: 'NETWORK_ERROR' })
    }

    const { status } = error.response

    if (status === 500) {
      return Promise.reject({ message: 'Server Error. Please try again later.', status })
    }

    return Promise.reject(error.response.data)
  }

  function get (path) {
    return client.get(path).then(response => response.data).catch(error => Promise.reject(error))
  }

  function post (path, payload) {
    return client.post(path, payload).then(response => response.data).catch(error => Promise.reject(error))
  }

  function put (path, payload) {
    return client.put(path, payload).then(response => response.data).catch(error => Promise.reject(error))
  }

  function patch (path, payload) {
    return client.patch(path, payload).then(response => response.data).catch(error => Promise.reject(error))
  }

  function _delete (path, data) {
    return client.delete(path, { data }).then(response => response.data).catch(error => Promise.reject(error))
  }

  return { get, post, put, patch, delete: _delete }
}

export default http
