import { Card, CardContent } from '@/components/ui/card'
import { motion, AnimatePresence } from 'framer-motion'
import { useState, useEffect } from 'react'
import { Skeleton } from '@/components/ui/skeleton'

export function SkeletonCard ({
  description
}) {
  const [cards, setCards] = useState([1, 2, 3])

  useEffect(() => {
    const interval = setInterval(() => {
      setCards((prev) => {
        if (prev.length === 0) return [1, 2, 3]
        const updatedCards = prev.slice(1)
        return updatedCards.length < 2 ? [...updatedCards, Math.max(...prev) + 1] : updatedCards
      })
    }, 600)

    return () => clearInterval(interval)
  }, [])

  return (
    <div className='flex flex-col items-center justify-center gap-4 py-3 px-6 h-screen'>
      <div className='flex flex-col items-center justify-center gap-4 py-3 px-6 h-40'>
        <AnimatePresence>
          {cards.map((card, index) => (
            <motion.div
              key={card}
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -150 }}
              transition={{ duration: 0.5, ease: 'easeInOut', delay: index * 0.1 }}
              layout
            >
              <Card className='shadow-md bg-background dark:bg-card border-[0.5px] border-accent w-40 rounded-md'>
                <CardContent className='pt-6 space-y-6 p-3'>
                  <div className='flex items-center space-x-4'>
                    <div className='space-y-2 grow'>
                      <Skeleton className='h-1.5 w-2/3 bg-accent' />
                      <Skeleton className='h-1.5 w-1/2 bg-accent' />
                    </div>
                  </div>
                </CardContent>
              </Card>
            </motion.div>
          ))}
        </AnimatePresence>
      </div>
      <small className='text-muted-foreground mx-auto text-center animate-pulse'>{description}
        <span className='animate-pulse'>...</span>
      </small>
    </div>
  )
}
