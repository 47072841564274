import { DataContext } from '@/contexts/DataProvider'
import ContentSection from '@/components/shared-sections/content-section'
import { use } from 'react'
import { SummaryForm } from './summary-form'

export default function Summary () {
  const { orgData } = use(DataContext)
  return (
    <ContentSection
      title='Account Summary'
      className='max-w-xl'
    >
      <SummaryForm orgData={orgData} />
    </ContentSection>
  )
}
