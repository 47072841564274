import { useForm } from 'react-hook-form'
import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import PropTypes from 'prop-types'
import http from '@/utils/http'
import { endpoints } from '@/utils/endpoints'
import { toast } from 'sonner'

export default function PasswordForm ({ userData, setUserData }) {
  const form = useForm({ mode: 'onChange', defaultValues: { current_password: '', new_password: '' } })

  async function onSubmit (data) {
    let payload = {}
    if (userData.provider === 'local') {
      payload = {
        current_password: data.current_password,
        new_password: data.new_password
      }
    } else {
      payload = {
        new_password: data.new_password
      }
    }
    try {
      const response = await http({}).post(endpoints.userPass, payload)
      if (response.success) {
        setUserData({
          ...userData,
          provider: 'local'
        })
        toast.success('Password changed successfully')
      }
    } catch (error) {
      toast.error(error.error.message)
    }
  }
  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className='space-y-8'>
        {
            userData.provider === 'local' &&
              <FormField
                control={form.control}
                name='current_password'
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Current Password</FormLabel>
                    <FormControl>
                      <Input placeholder='Enter current password' {...field} />
                    </FormControl>
                  </FormItem>
                )}
              />
        }
        <FormField
          control={form.control}
          name='new_password'
          rules={{
            required: 'New password is required',
            minLength: { value: 8, message: 'Password must be at least 8 characters long' },
            pattern: {
              value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d|.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/,
              message: 'Password must include an uppercase letter, a lowercase letter, and a number or symbol'
            }
          }}
          render={({ field, fieldState }) => (
            <FormItem>
              <FormLabel>New Password</FormLabel>
              <FormControl>
                <Input placeholder='Enter new password' {...field} required />
              </FormControl>
              {fieldState.error && (
                <p className='text-red-500 text-sm'>{fieldState.error.message}</p>
              )}
              <FormDescription className='mt-2'>
                Please ensure that these suggestions are met:
                <ul className='list-disc list-inside ml-2 mt-1'>
                  <li>Minimum 8 characters long - the more, the better</li>
                  <li>At least one lowercase character</li>
                  <li>At least one uppercase character</li>
                  <li>At least one number or symbol</li>
                </ul>
              </FormDescription>
            </FormItem>
          )}
        />
        <Button type='submit' className='cursor-pointer'>Update Password</Button>
      </form>
    </Form>
  )
}

PasswordForm.propTypes = {
  setUserData: PropTypes.func.isRequired,
  userData: PropTypes.shape({ provider: PropTypes.string }).isRequired
}
