import { Button } from '@/components/ui/button'
import { Form, FormControl, FormField, FormItem, FormLabel } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { useForm } from 'react-hook-form'
import PropTypes from 'prop-types'
import { Card } from '@/components/ui/card'

export default function ReedemCouponForm ({ onSubmit }) {
  const form = useForm({
    defaultValues: {
      coupon_code: ''
    }
  })
  return (
    <Card className='rounded-lg border p-4 shadow-none'>
      <Form {...form}>
        <form onSubmit={form.handleSubmit((data) => onSubmit(data, form.reset))} className='flex flex-row gap-2'>
          <FormField
            control={form.control}
            name='coupon_code'
            render={({ field }) => (
              <FormItem className='w-full'>
                <FormLabel className='text-base mb-2'>
                  Reedem Coupon
                </FormLabel>
                <div className='flex gap-3 items-center'>
                  <FormControl className='max-w-80'>
                    <Input placeholder='Reedem here' {...field} required />
                  </FormControl>
                  <Button type='submit' className='cursor-pointer' disabled={form.formState.isSubmitting}>Reedem</Button>
                </div>
              </FormItem>
            )}
          />
        </form>
      </Form>
    </Card>
  )
}

ReedemCouponForm.propTypes = {
  onSubmit: PropTypes.func
}
