import PropTypes from 'prop-types'
import { Card, CardContent } from '@/components/ui/card'
import { convertEpochToTimezone } from '@/utils/helper'
import { useState, useRef, useEffect } from 'react'

export default function AssetEvents ({ getLiveAssetHistoryApiResponse, userData }) {
  const contentRef = useRef(null)
  const [showAll, setShowAll] = useState(false)
  const [contentHeight, setContentHeight] = useState(0)
  const events = [...getLiveAssetHistoryApiResponse.events].reverse()
  const displayEvents = showAll ? events : events.slice(0, 3)

  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight)
    }
  }, [displayEvents])

  return (
    <Card className='rounded-lg border shadow-none'>
      <CardContent className='pt-6'>
        <h3 className='text-md font-semibold mb-8'>Events</h3>
        <div className='flex flex-col items-start space-y-4'>
          <div
            className='transition-all duration-300 ease-in-out overflow-hidden'
            style={{ height: showAll ? contentHeight : Math.min(contentHeight, 300) }}
          >
            <div ref={contentRef}>
              {displayEvents.map((event, index) => (
                <div key={index} className='flex items-start'>
                  <div className='flex flex-col items-center'>
                    <div className='w-8 h-8 flex items-center justify-center rounded-full text-white text-sm font-semibold transition-all duration-300 bg-indigo-100'>
                      <div className='w-2 h-2 rounded-full bg-indigo-400' />
                    </div>
                    {index !== displayEvents.length - 1 && (
                      <div
                        className='w-0.5 h-10 transition-all duration-300 bg-gray-300 my-2'
                      />
                    )}
                  </div>
                  <div className='ml-4 flex flex-col gap-2'>
                    <p className='text-sm font-medium'>{event.event}</p>
                    <p className='text-muted-foreground text-xs'>{convertEpochToTimezone(event.changed_at, userData.timezone)}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {events.length > 3 && (
            <button
              onClick={() => setShowAll(!showAll)}
              className='text-xs text-muted-foreground cursor-pointer font-medium hover:text-gray-900 transition-colors duration-200'
            >
              {showAll ? 'Show Less' : 'Show More'}
            </button>
          )}
        </div>
      </CardContent>
    </Card>
  )
}

AssetEvents.propTypes = {
  getLiveAssetHistoryApiResponse: PropTypes.object.isRequired,
  userData: PropTypes.object.isRequired
}
