import { CircleChevronUp } from 'lucide-react'
import { Button } from './ui/button'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import { routePaths } from '@/utils/routePaths'

export default function UpgradePlanButton ({ planType = 'video', btnText = 'Upgrade Plan' }) {
  return (
    <div className='flex flex-col items-center justify-center gap-3 h-96 max-w-lg mx-auto'>
      <img className='mb-5 mt-6' src='https://assets.gumlet.io/public/img/new-onboarding/no_user_2.svg' alt='No subuser found' style={{ maxWidth: '50%' }} />
      <p className='text-center text-muted-foreground text-sm'>
        Your plan doesn&apos;t have this feature, please upgrade your current plan to access this feature.
      </p>
      <Button variant='default' size='sm' asChild><Link to={`${routePaths.planChange}?plan=${planType}`}><CircleChevronUp /> {btnText}</Link></Button>
    </div>
  )
}

UpgradePlanButton.propTypes = {
  planType: PropTypes.string,
  btnText: PropTypes.string
}
