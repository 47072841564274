import { cn } from '@/utils/helper'

export const SectionHeader = ({ fixed, ...props }) => {
  return (
    <main
      className={cn(
        'peer-[.header-fixed]/header:mt-16 px-4 py-6',
        fixed && 'fixed-main flex flex-grow flex-col overflow-hidden'
      )}
      {...props}
    />
  )
}

SectionHeader.displayName = 'SectionHeader'
