import clsx from 'clsx'
import { formatInTimeZone } from 'date-fns-tz'
import { toast } from 'sonner'
import { twMerge } from 'tailwind-merge'
import rbac from '@/utils/rbac.json'
import { startOfMonth, endOfMonth, subMonths, format, getDaysInMonth } from 'date-fns'

export function cn (...inputs) {
  return twMerge(clsx(inputs))
}

export const openIntercom = () => {
  window.Intercom('show')
}

// This function is used to track events in MixPanel
export const SendToMixPanel = (trackerString, trackingObject) => {
  if (typeof window.gumletSegment !== 'undefined') {
    window.gumletSegment.track(trackerString, trackingObject)
  } else {
    console.log(
      'window.gumletSegment is not defined. Tracking cannot be performed.'
    )
  }
}

export const getAllowedRolesForPath = (path) => {
  return rbac[path] || []
}

export const createAvatarFallback = (name, email) => {
  if (!name && !email) return 'GM' // if no name and email
  if (!name && email) return email.charAt(0) + email.charAt(1) // if no name but email
  if (name && email) return name.split(' ').map((n) => n.charAt(0)).join('') // if name and email
}

export const isEmpty = obj => Object.keys(obj).length === 0

export const convertEpochToTimezone = (epoch, timezone, inputFormat) => {
  if (!epoch) return ''

  // if the timezone is not provided use the default bvrowser timezone
  const formateDatePattern = inputFormat || 'dd LLL yyyy, p'

  if (!timezone) {
    return format(new Date(epoch), formateDatePattern)
  }

  return formatInTimeZone(new Date(epoch), timezone, formateDatePattern)
}

export const handleCopy = async (text) => {
  try {
    await navigator.clipboard.writeText(text.data)
    toast.success(text.message)
  } catch (err) {
    toast.error('Failed to copy')
    console.error('Failed to copy:', err)
  }
}

export const calculateProgressBarWidth = (dataSize, quotaSize) => {
  const progress = (dataSize / quotaSize) * 100
  return progress
}

export const formatBytes1000 = (bytes, decimals) => {
  if (!bytes || bytes === 0) return '0 Byte'
  const k = 1000 // or 1000 for binary
  const dm = decimals + 1 || 3
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export const convertDataSize = (usedSize, totalSize) => {
  if (usedSize && totalSize) {
    const units = {
      B: 1,
      KB: 1024,
      MB: 1024 ** 2,
      GB: 1024 ** 3,
      TB: 1024 ** 4,
      PB: 1024 ** 5
    }

    // this function extracts the numeric values and units using regular expressions.
    // It converts the sizes to bytes and then calculates the percentage by dividing the used bytes
    // by the total bytes and multiplying by 100.

    const sizePattern = /^(\d+(\.\d+)?)\s*(\w+)$/i
    const [, usedValue, , usedUnit] = sizePattern.exec(usedSize)
    const [, totalValue, , totalUnit] = sizePattern.exec(totalSize)

    const usedBytes = parseFloat(usedValue) * units[usedUnit.toUpperCase()]
    const totalBytes = parseFloat(totalValue) * units[totalUnit.toUpperCase()]

    const percentage = (usedBytes / totalBytes) * 100

    return percentage
  }
}

const getRemanningDays = function () {
  const date = new Date()
  const time = new Date(date.getTime())
  time.setMonth(date.getMonth() + 1)
  time.setDate(0)
  const days =
    time.getDate() > date.getDate() ? time.getDate() - date.getDate() : 0
  return days + 1
}

export const getDiscountedProratedPrice = (monthly_price, yearly, is_yearly) => {
  let price
  if (is_yearly) {
    price = yearly * 12
  } else {
    price = ((monthly_price * getRemanningDays()) / getDaysInMonth(new Date())).toFixed(
      2
    )
  }

  if (isNaN(price)) {
    price = 0
  }
  return '$' + price
}

export const getProratedPrice = (monthly_price, is_yearly) => {
  let price

  if (is_yearly) {
    price = monthly_price * 10
  } else {
    price = ((monthly_price * getRemanningDays()) / getDaysInMonth(new Date())).toFixed(2)
  }
  if (isNaN(price)) {
    price = 0
  }
  return '$' + price
}

export const seo = (data = {}) => {
  data.title = data.title || 'Gumlet Dashboard'
  document.title = data.title
}

export const getDateRange = (range) => {
  const now = new Date()
  let lastMonth, threeMonthsAgo, sixMonthsAgo

  switch (range) {
    case 'this_month':
      return {
        start: format(startOfMonth(now), 'yyyy-MM-dd'),
        end: format(endOfMonth(now), 'yyyy-MM-dd')
      }
    case 'last_month':
      lastMonth = subMonths(now, 1)
      return {
        start: format(startOfMonth(lastMonth), 'yyyy-MM-dd'),
        end: format(endOfMonth(lastMonth), 'yyyy-MM-dd')
      }
    case 'last_3_months':
      threeMonthsAgo = subMonths(now, 3)
      return {
        start: format(startOfMonth(threeMonthsAgo), 'yyyy-MM-dd'),
        end: format(endOfMonth(now), 'yyyy-MM-dd')
      }
    case 'last_6_months':
      sixMonthsAgo = subMonths(now, 6)
      return {
        start: format(startOfMonth(sixMonthsAgo), 'yyyy-MM-dd'),
        end: format(endOfMonth(now), 'yyyy-MM-dd')
      }
    default:
      return {
        start: format(startOfMonth(now), 'yyyy-MM-dd'),
        end: format(endOfMonth(now), 'yyyy-MM-dd')
      }
  }
}


// Get current breadcrumb items 
export const getBreadcrumbItems = (path) => {
  //split with / and get the last item
  const pathArray = path.split('/')
  const lastItem = pathArray[pathArray.length - 1]
  // make first char uppercase and rest lowercase
  const breadcrumb = lastItem.charAt(0).toUpperCase() + lastItem.slice(1).toLowerCase()
  //return the formatted item
  return breadcrumb
}