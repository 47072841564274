import { createContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { endpoints } from '@/utils/endpoints'
import useQuery from '@/utils/useQuery'
import { config } from '@/config'
import { useIntercom } from 'react-use-intercom'

export const DataContext = createContext({})

export const DataProvider = ({ children }) => {
  const { boot } = useIntercom()
  const { data: orgData, setData: setOrgData, isLoading: isOrgDataLoading } = useQuery({
    url: `${endpoints.org}`
  })

  const { data: userData, setData: setUserData, isLoading: isUserDataLoading } = useQuery({
    url: `${endpoints.user}`
  })

  useEffect(() => {
    if (userData) {
      window.Canny('initChangelog', {
        appID: config.CANNY_APP_ID,
        position: 'bottom',
        align: 'right',
        theme: 'light',
        user: {
          email: userData.email,
          name: userData.name,
          id: userData.id,
          hash: userData.canny_hash,
          created: new Date(userData.creation_date).toISOString()
        }
      })
      boot({
        email: userData.email,
        createdAt: parseInt(userData.creation_date / 1000),
        name: userData.name,
        userId: userData.id,
        userHash: userData.intercom_hash,
        plan: userData.plans,
        roles: userData.roles?.join(','),
        customAttributes: {
          plan: userData.plans,
          roles: userData.roles?.join(','),
          user_type: userData?.onboarding_product || 'video'
        }
      })
    }
  }, [userData, boot])

  if (isOrgDataLoading || isUserDataLoading) return null

  return <DataContext.Provider value={{ userData, orgData, setUserData, setOrgData }}>{children}</DataContext.Provider>
}
DataProvider.propTypes = {
  children: PropTypes.node.isRequired
}
