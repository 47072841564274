import ContentSection from '@/components/shared-sections/content-section'
import { SkeletonBasics } from '@/components/shimmers/basic'
import { endpoints } from '@/utils/endpoints'
import useQuery from '@/utils/useQuery'
import PaymentCards from './payment-cards'
import { useState } from 'react'
import DeleteActionDialog from '@/components/delete-action-dialog'
import http from '@/utils/http'
import { toast } from 'sonner'
import { Button } from '@/components/ui/button'
import { Plus } from 'lucide-react'
import StripeCardModal from '@/components/stripe-card-modal'

export default function PaymentMethods () {
  const [loading, setLoading] = useState(null)
  const [isDeleteOpen, setIsDeleteOpen] = useState(null)
  const [openStripeCardModal, setOpenStripeCardModal] = useState(false)

  const { data: cards, setData, refetch, isLoading } = useQuery({
    url: `${endpoints.organization.billing.card.base}`
  })

  if (isLoading) return <SkeletonBasics />

  async function _primaryCard (id, onPrimary) {
    setLoading(id)
    const payload = {}
    if (onPrimary) {
      payload.payment_method_id = id
    } else {
      payload.payment_method_id = id
      payload.auto = true
    }
    try {
      const response = await http({}).post(endpoints.organization.billing.card.default, payload)
      setData((prevCards) => ({
        ...prevCards,
        default_card: response.default_card
      }))
      toast('Card set as primary')
    } catch (error) {
      toast(error?.error?.message)
    } finally {
      setLoading(null)
    }
  }

  async function _deleteCard (id) {
    try {
      await http({}).delete(endpoints.organization.billing.card.delete.replace(':card_id', id))
      setData((prevCards) => ({
        ...prevCards,
        cards: prevCards.cards.filter((item) => item.id !== id)
      }))
      toast('Card removed')
      setIsDeleteOpen(null)
    } catch (error) {
      toast(error?.error?.message)
    }
  }
  return (
    <ContentSection
      title='Payment Methods'
      desc='Cards will be charged at the end of the month. All major credit / debit cards are accepted.'
      className='max-w-none'
      has_action={
        <Button className='cursor-pointer' size='lg' onClick={() => setOpenStripeCardModal(true)}><Plus className='h-4 w-4' /> Add Card</Button>
      }
    >
      <PaymentCards cards={cards} setIsDeleteOpen={setIsDeleteOpen} _primaryCard={_primaryCard} loading={loading} setOpenStripeCardModal={setOpenStripeCardModal} />
      {isDeleteOpen &&
        <DeleteActionDialog
          isDeleteOpen={isDeleteOpen}
          setIsDeleteOpen={setIsDeleteOpen}
          onSubmit={_deleteCard}
          title='Are you sure you want to remove this card?'
        />}
      {openStripeCardModal && <StripeCardModal isOpen={openStripeCardModal} setIsOpen={setOpenStripeCardModal} refetchCards={refetch} />}
    </ContentSection>
  )
}
