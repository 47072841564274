import { Header } from '@/components/ui-layout/header'
import { SectionHeader } from '@/components/ui-layout/section-header'
import { BreadcrumbComponents } from '@/components/ui/breadcrumb'
import { Separator } from '@/components/ui/separator'
import useQuery from '@/utils/useQuery'
import { endpoints } from '@/utils/endpoints'
import { SkeletonBasics } from '@/components/shimmers/basic'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'components/ui/select'
import { useNavigate } from 'react-router'
import { DatePickerWithPresets } from '@/components/ui/date-picker'
import { getDateRange } from '@/utils/helper'
import { useState } from 'react'

export default function UsageAnalytics () {
  const navigate = useNavigate()
  const urlParams = new URLSearchParams(location.search)
  const sourceId = urlParams.get('source_id')
  const range = urlParams.get('range') || 'this_month'
  const startDate = urlParams.get('start_date')
  const endDate = urlParams.get('end_date')

  const [date, setDate] = useState({
    from: startDate ? new Date(startDate) : null,
    to: endDate ? new Date(endDate) : null
  })

  const { data: allCollectionApiResponse, isLoading } = useQuery({
    url: `${endpoints.stream.sources.base}`
  })

  const dateRange = getDateRange(range)
  const payload = {
    metrics: ['bandwidth_consumption', 'asset_duration', 'storage_unit'],
    group_by: 'daily',
    date_range: {
      start_at: startDate || dateRange.start,
      end_at: endDate || dateRange.end
    },
    ...(sourceId && sourceId !== 'all' && {
      filters: {
        source_id: sourceId
      }
    })
  }

  const handleDateChange = ({ type, value, dateRange }) => {
    if (type === 'preset') {
      urlParams.set('range', value)
      urlParams.delete('start_date')
      urlParams.delete('end_date')
      setDate({ from: null, to: null })
    } else if (type === 'custom') {
      if (dateRange) {
        urlParams.delete('range')
        urlParams.set('start_date', dateRange.start)
        urlParams.set('end_date', dateRange.end)
        setDate(value)
      } else {
        setDate(value)
      }
    }
    navigate(`${location.pathname}?${urlParams.toString()}`, { replace: false })
  }

  if (isLoading) return <SkeletonBasics />
  // console.log(payload)
  return (
    <>
      <Header>
        <BreadcrumbComponents
          Breadcrum1='Live Stream'
          Breadcrum1Link='/dashboard/video'
          Breadcrum3='Analytics'
        />
      </Header>
      <SectionHeader fixed>
        <div className='flex items-end justify-between sm:my-0 sm:items-center'>
          <h1 className='text-2xl font-medium md:text-3xl'>
            Usage Analytics
          </h1>
          <div className='flex flex-col gap-4 sm:flex-row'>
            <Select
              value={sourceId || 'all'} onValueChange={(value) => {
                if (value === 'all') {
                  urlParams.delete('source_id')
                } else {
                  urlParams.set('source_id', value)
                }
                navigate(`${location.pathname}?${urlParams.toString()}`, { replace: false })
              }}
            >
              <SelectTrigger className='SelectTrigger w-36'>
                <SelectValue placeholder='Select collection' />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value='all'>All Collections</SelectItem>
                {allCollectionApiResponse.all_live_sources.map((source, i) => (
                  <SelectItem key={i} value={source._id}>{source.name}</SelectItem>
                ))}
              </SelectContent>
            </Select>
            <DatePickerWithPresets
              range={range}
              date={date}
              onChange={handleDateChange}
            />
          </div>
        </div>
        <Separator className='my-4 lg:my-6' />
      </SectionHeader>
    </>
  )
}
