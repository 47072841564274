import {
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem
} from '@/components/ui/sidebar'
import GumletIcon from '@/assets/gum-icon.svg'
import PropTypes from 'prop-types'

export function NavHeader ({ userData }) {
  return (
    <SidebarMenu>
      <SidebarMenuItem>
        <SidebarMenuButton
          size='lg'
          className='data-[state=open]:bg-sidebar-accent data-[state=open]:text-sidebar-accent-foreground'
        >
          <div
            className='text-sidebar-primary-foreground flex aspect-square size-8 items-center justify-center rounded-lg'
          >
            <img src={GumletIcon} alt='Gumlet' className='size-7' />
          </div>
          <div className='grid flex-1 text-left text-sm leading-tight'>
            <span className='truncate font-semibold'>
              Gumlet
            </span>
            <span className='truncate text-xs'>{userData?.email}</span>
          </div>
        </SidebarMenuButton>
      </SidebarMenuItem>
    </SidebarMenu>
  )
}

NavHeader.propTypes = {
  userData: PropTypes.shape({
    email: PropTypes.string
  })
}
