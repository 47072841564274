import { use, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@/components/ui/button'
import { LoadingSpinner } from '@/components/ui/spinner'
import { endpoints } from '@/utils/endpoints'
import { config } from '@/config'
import { loadStripe } from '@stripe/stripe-js/pure'
import { AddressElement, Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import http from '@/utils/http'
import { toast } from 'sonner'
import { DialogFooter } from '@/components/ui/dialog'
import { useTheme } from './theme-provider'
import { DataContext } from '@/contexts/DataProvider'

function StripeCardForm ({ userData, setIsOpen, paymentElementLoading, setPaymentElementLoading, refetchCards, isModal }) {
  const stripe = useStripe()
  const elements = useElements()
  const [loading, setLoading] = useState(false)

  const handleSubmit = async (event) => {
    event.preventDefault()
    if (!stripe || !elements) return // Stripe.js has not loaded yet

    setLoading(true)
    const { setupIntent, error } = await stripe.confirmSetup({ elements, redirect: 'if_required' })

    if (setupIntent) {
      setLoading(false)
      refetchCards()
      if (isModal) {
        setIsOpen(false)
      } else {
        setIsOpen('success')
      }
      toast('Card added successfully')
    }
    if (error) {
      setLoading(false)
      toast(error.message)
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className='content'>
        <h4 className='my-3'>Billing Details</h4>
        <AddressElement options={{ mode: 'billing' }} />
        <hr className='my-4' />
        {paymentElementLoading && (
          <div className='flex flex-col items-center justify-center my-12'>
            <LoadingSpinner />
            <p className='text-muted-foreground text-sm ml-2'>Loading payment methods...</p>
          </div>
        )}
        <PaymentElement
          onReady={() => setPaymentElementLoading(false)}
          options={{
            defaultValues: {
              billingDetails: {
                email: userData?.email,
                name: userData?.name
              }
            }
          }}
        />
      </div>
      <DialogFooter className='mt-4 flex flex-row sm:justify-between items-center'>
        <div>
          <img
            className='img-fluid max-w-[6rem]'
            src='https://assets.gumlet.io/public/img/Powered-by-Stripe.png'
            alt='powered by stripe label'
          />
        </div>
        <div className='flex flex-row items-center gap-2'>
          <Button
            type='button'
            onClick={() => isModal ? setIsOpen(false) : setIsOpen('close')}
            className='cursor-pointer'
            variant='outline'
          >
            Close
          </Button>
          <Button
            type='submit'
            disabled={loading || paymentElementLoading}
            className='cursor-pointer'
          >
            {loading && <LoadingSpinner />}
            <span>Save Card</span>
          </Button>
        </div>
      </DialogFooter>
    </form>
  )
}

StripeCardForm.propTypes = {
  userData: PropTypes.object,
  setIsOpen: PropTypes.func.isRequired,
  paymentElementLoading: PropTypes.bool.isRequired,
  setPaymentElementLoading: PropTypes.func.isRequired,
  refetchCards: PropTypes.func.isRequired,
  isModal: PropTypes.bool
}

export default function StripeElements ({ setIsOpen, refetchCards, isModal = false }) {
  const { theme } = useTheme()
  const { userData, orgData } = use(DataContext)
  const [intent, setIntent] = useState('')
  const [paymentElementLoading, setPaymentElementLoading] = useState(true)

  const stripeCardStyle = (theme) => ({
    theme: theme === 'dark' ? 'night' : 'stripe',
    variables: {
      colorPrimary: '#6759eb',
      fontSizeBase: '14px',
      fontFamily: 'Inter, system-ui, sans-serif'
    }
  })

  useEffect(() => {
    const fetchStripeIntent = async () => {
      try {
        const response = await http({}).post(endpoints.organization.billing.stripe.intent)
        if (response.intent_secret) {
          setIntent(response.intent_secret)
        }
      } catch (error) {
        toast(error.error.message)
      }
    }
    fetchStripeIntent()
  }, [])

  const key = orgData?.stripe_account_loc === 'sgp' ? config.STRIPE_KEY : config.STRIPE_KEY_IND
  const stripePromise = loadStripe(key)
  const options = { clientSecret: intent, appearance: stripeCardStyle(theme) }
  return (
    intent
      ? (
        <Elements stripe={stripePromise} options={options}>
          <StripeCardForm
            userData={userData}
            setIsOpen={setIsOpen}
            paymentElementLoading={paymentElementLoading}
            setPaymentElementLoading={setPaymentElementLoading}
            refetchCards={refetchCards}
            isModal={isModal}
          />
        </Elements>
        )
      : (
        <div className='flex flex-col items-center justify-center my-24'>
          <LoadingSpinner />
          <p className='text-muted-foreground text-sm mt-2'>Please wait...</p>
        </div>
        )
  )
}

StripeElements.propTypes = {
  setIsOpen: PropTypes.func.isRequired,
  refetchCards: PropTypes.func.isRequired,
  isModal: PropTypes.bool
}
