import { Link, useLocation } from 'react-router'
import { ChevronRight } from 'lucide-react'
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger
} from '@/components/ui/collapsible'
import {
  SidebarGroup,
  SidebarGroupLabel,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSub,
  SidebarMenuSubButton,
  SidebarMenuSubItem,
  useSidebar
} from '@/components/ui/sidebar'
import { Badge } from '../ui/badge'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from '../ui/dropdown-menu'
import PropTypes from 'prop-types'
import { checkPermissionForUrl } from '@/utils/roleBasedRoute'
import { use } from 'react'
import { DataContext } from '@/contexts/DataProvider'
import { UNAUTHORIZED_TOOLTIP } from '@/utils/constants'

export function NavGroup ({ title, items }) {
  const { state } = useSidebar()
  const href = useLocation().pathname
  const { userData } = use(DataContext)
  const userRoles = userData?.roles || []
  return (
    <SidebarGroup>
      <SidebarGroupLabel>{title}</SidebarGroupLabel>
      <SidebarMenu>
        {items.map((item) => {
          const key = `${item.title}-${item.url}`
          const isAllowed = checkPermissionForUrl(item.url, userRoles)
          if (!item.items) { return <SidebarMenuLink key={key} item={item} href={href} isAllowed={isAllowed} /> }

          if (state === 'collapsed') {
            return (
              <SidebarMenuCollapsedDropdown key={key} item={item} href={href} />
            )
          }

          return <SidebarMenuCollapsible key={key} item={item} href={href} />
        })}
      </SidebarMenu>
    </SidebarGroup>
  )
}

const NavBadge = ({ children }) => (
  <Badge className='rounded-full px-1 py-0 text-xs'>{children}</Badge>
)

const SidebarMenuLink = ({ item, href, isAllowed }) => {
  const { setOpenMobile } = useSidebar()
  return (
    <SidebarMenuItem>
      <SidebarMenuButton
        asChild
        isActive={checkIsActive(href, item)}
        tooltip={isAllowed ? item.title : UNAUTHORIZED_TOOLTIP}
      >
        {isAllowed
          ? (
            <Link to={item.url} onClick={() => setOpenMobile(false)}>
              {item.icon && <item.icon />}
              <span>{item.title}</span>
              {item.badge && <NavBadge>{item.badge}</NavBadge>}
            </Link>
            )
          : (
            <div className='flex w-full items-center gap-2 text-sidebar-foreground/50'>
              {item.icon && <item.icon />}
              <span>{item.title}</span>
              {item.badge && <NavBadge>{item.badge}</NavBadge>}
            </div>
            )}
      </SidebarMenuButton>
    </SidebarMenuItem>
  )
}

const SidebarMenuCollapsible = ({ item, href }) => {
  const { setOpenMobile } = useSidebar()
  const { userData } = use(DataContext)
  const userRoles = userData?.roles || []

  return (
    <Collapsible
      asChild
      defaultOpen={checkIsActive(href, item, true)}
      className='group/collapsible'
    >
      <SidebarMenuItem>
        <CollapsibleTrigger asChild>
          <SidebarMenuButton className='cursor-pointer'>
            {item.icon && <item.icon />}
            <span>{item.title}</span>
            {item.badge && <NavBadge>{item.badge}</NavBadge>}
            <ChevronRight className='ml-auto transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90' />
          </SidebarMenuButton>
        </CollapsibleTrigger>
        <CollapsibleContent className='CollapsibleContent'>
          <SidebarMenuSub>
            {item.items.map((subItem) => {
              const subItemAllowed = checkPermissionForUrl(subItem.url, userRoles)
              return (
                <SidebarMenuSubItem key={subItem.title}>
                  <SidebarMenuSubButton
                    asChild
                    isActive={checkIsActive(href, subItem)}
                    tooltip={subItemAllowed ? subItem.title : UNAUTHORIZED_TOOLTIP}
                  >
                    {subItemAllowed
                      ? (
                        <Link to={subItem.url} onClick={() => setOpenMobile(false)}>
                          {subItem.icon && <subItem.icon />}
                          <span>{subItem.title}</span>
                          {subItem.badge && <NavBadge>{subItem.badge}</NavBadge>}
                        </Link>
                        )
                      : (
                        <div className='flex w-full items-center gap-2 text-sidebar-foreground/50'>
                          {subItem.icon && <subItem.icon />}
                          <span>{subItem.title}</span>
                          {subItem.badge && <NavBadge>{subItem.badge}</NavBadge>}
                        </div>
                        )}
                  </SidebarMenuSubButton>
                </SidebarMenuSubItem>
              )
            })}
          </SidebarMenuSub>
        </CollapsibleContent>
      </SidebarMenuItem>
    </Collapsible>
  )
}

const SidebarMenuCollapsedDropdown = ({ item, href }) => {
  return (
    <SidebarMenuItem>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <SidebarMenuButton
            tooltip={item.title}
            isActive={checkIsActive(href, item)}
          >
            {item.icon && <item.icon />}
            <span>{item.title}</span>
            {item.badge && <NavBadge>{item.badge}</NavBadge>}
            <ChevronRight className='ml-auto transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90' />
          </SidebarMenuButton>
        </DropdownMenuTrigger>
        <DropdownMenuContent side='right' align='start' sideOffset={4}>
          <DropdownMenuLabel>
            {item.title} {item.badge ? `(${item.badge})` : ''}
          </DropdownMenuLabel>
          <DropdownMenuSeparator />
          {item.items.map((sub) => (
            <DropdownMenuItem key={`${sub.title}-${sub.url}`} asChild>
              <Link
                to={sub.url}
                className={`${checkIsActive(href, sub) ? 'bg-secondary' : ''}`}
              >
                {sub.icon && <sub.icon />}
                <span className='max-w-52 text-wrap'>{sub.title}</span>
                {sub.badge && (
                  <span className='ml-auto text-xs'>{sub.badge}</span>
                )}
              </Link>
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </SidebarMenuItem>
  )
}

function checkIsActive (href, item, mainNav = false) {
  return (
    href === item.url || // /endpint?search=param
    href.split('?')[0] === item.url || // endpoint
    !!item?.items?.filter((i) => i.url === href).length || // if child nav is active
    (mainNav &&
      href.split('/')[1] !== '' &&
      href.split('/')[1] === item?.url?.split('/')[1])
  )
}

NavGroup.propTypes = {
  title: PropTypes.string,
  items: PropTypes.array
}

SidebarMenuCollapsedDropdown.propTypes = {
  item: PropTypes.object,
  href: PropTypes.string
}

SidebarMenuCollapsible.propTypes = {
  item: PropTypes.object,
  href: PropTypes.string
}

SidebarMenuLink.propTypes = {
  item: PropTypes.object,
  href: PropTypes.string,
  isAllowed: PropTypes.bool
}

NavBadge.propTypes = {
  children: PropTypes.node
}
