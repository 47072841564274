import ContentSection from '@/components/shared-sections/content-section'
import TotalInvoices from './invoice-table'
import useQuery from '@/utils/useQuery'
import { endpoints } from '@/utils/endpoints'
import { SkeletonBasics } from '@/components/shimmers/basic'
// import { StickyBanner } from '@/components/ui/sticky-alert'

export default function Invoices () {
  const { data: totalInvoices, isLoading } = useQuery({
    url: `${endpoints.organization.billing.invoices}`
  })

  if (isLoading) return <SkeletonBasics />

  return (
    <ContentSection
      title='List of Invoices'
      desc='Past invoices and their payment status.'
      className='max-w-none'
    >
      {/* // temp commented code // */}
      {/* {
        // if unpaid_invoices is true then show the unpaid show alert message
        totalInvoices?.unpaid_invoices &&
          <StickyBanner>
            <span>
              Your account has unpaid invoices. Please clear the unpaid amount
              via payment link in invoices section.
            </span>
          </StickyBanner>
      } */}
      <TotalInvoices
        invoices={
          totalInvoices?.stripe_invoices?.length && totalInvoices?.stripe_invoices?.filter(
            (invoice) => invoice.status !== 'draft'
          )
        }
      />
    </ContentSection>
  )
}
