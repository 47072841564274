import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import MultipleSelector from '@/components/ui/multi-select'
import { Checkbox } from '@/components/ui/checkbox'
import useQuery from '@/utils/useQuery'
import { endpoints } from '@/utils/endpoints'
import http from '@/utils/http'
import { toast } from 'sonner'
import { useEffect, useState } from 'react'
import { LoadingSpinner } from '@/components/ui/spinner'
import { VIDEO_SOURCES_PAGINATION_LIMIT } from '@/utils/constants'
import { useNavigate } from 'react-router'
import { CircleHelp } from 'lucide-react'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip'

export default function WebhookForm ({ id, getWebhookByID }) {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  const { data: allVideoSources } = useQuery({
    url: `${endpoints.video.sources.base + VIDEO_SOURCES_PAGINATION_LIMIT}`
  })

  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      url: '',
      secret_token: '',
      sources: [],
      status: true
    }
  })

  useEffect(() => {
    if (getWebhookByID && allVideoSources) {
      const selectedSources = getWebhookByID.sources.map(sourceId =>
        allVideoSources.all_sources.find(videoSource => videoSource.id === sourceId)
      ).filter(Boolean)
      form.reset({
        url: getWebhookByID.url || '',
        secret_token: getWebhookByID.secret_token || '',
        sources: selectedSources.map(source => ({
          label: source.name,
          value: source.id
        })),
        status: getWebhookByID.triggers?.includes('status') || false
      })
    }
  }, [getWebhookByID, form, allVideoSources])

  async function onSubmit (data) {
    if (!data.sources.length) {
      return toast.error('Please select at least one source')
    }
    if (!data.status) {
      return toast.error('Please select at least one trigger event')
    }
    const payload = {
      url: data.url,
      secret_token: data.secret_token,
      triggers: ['status'],
      sources: data.sources.filter(source => source.value).map(source => source.value)
    }
    setLoading(true)
    try {
      if (!id) {
        await http({}).post(endpoints.organization.webhook.add, payload)
      } else {
        await http({}).post(endpoints.organization.webhook.edit.replace(':id', id), payload)
      }
      setLoading(false)
      toast.success(`Webhook ${id ? 'updated' : 'added'} successfully`)
      navigate('/organization/webhooks')
    } catch (error) {
      setLoading(false)
      toast.success(error.error.message)
    }
  }
  if (!allVideoSources) return null
  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className='space-y-8 !max-w-xl'>
        <FormField
          control={form.control}
          name='url'
          render={({ field }) => (
            <FormItem>
              <FormLabel>Webhook URL</FormLabel>
              <FormControl>
                <Input placeholder='Please enter your webhook URL' {...field} required />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name='secret_token'
          render={({ field }) => (
            <FormItem>
              <FormLabel className='flex items-center gap-1'>Secret Token<Tooltip><TooltipTrigger asChild><CircleHelp className='h-4 w-4 cursor-pointer' /></TooltipTrigger>
                <TooltipContent side='top' align='center' className='w-70'>Use this token to validate received payloads. It will be sent with the request in the x-gumlet-token HTTP header.</TooltipContent>
              </Tooltip>
              </FormLabel>
              <FormControl>
                <Input placeholder='Please enter your secret token' {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name='sources'
          render={({ field }) => (
            <FormItem>
              <FormLabel>Sources</FormLabel>
              <FormControl>
                <MultipleSelector
                  {...field}
                  defaultOptions={allVideoSources?.all_sources.map(({ name, id }) => ({
                    label: name,
                    value: id
                  }))}
                  placeholder='Select video sources'
                />
              </FormControl>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name='status'
          render={({ field }) => (
            <FormItem>
              <FormLabel>Trigger Event</FormLabel>
              <FormControl>
                <div className='flex items-center space-x-2 mt-2'>
                  <Checkbox
                    id='status'
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                  <label
                    htmlFor='status'
                    className='text-sm leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70'
                  >
                    Video Status
                  </label>
                </div>
              </FormControl>
              <FormDescription>
                <p className='text-xs'>(This event is triggered when video status changes)</p>
              </FormDescription>
            </FormItem>
          )}
        />
        <Button type='submit' className='cursor-pointer' disabled={!form.formState.isDirty || loading}>{loading && <LoadingSpinner />} Save Changes</Button>
      </form>
    </Form>
  )
}

WebhookForm.propTypes = {
  id: PropTypes.string,
  getWebhookByID: PropTypes.object
}
