import useQuery from '@/utils/useQuery'
import { endpoints } from '@/utils/endpoints'
import ContentSection from '@/components/shared-sections/content-section'
import UpcomingInvoices from './upcoming-invoice-table'
import { SkeletonBasics } from '@/components/shimmers/basic'
import CurrentPlanDetail from './current-plan-detail'
import { use, useEffect, useState } from 'react'
import { DataContext } from '@/contexts/DataProvider'
import { getImageAnalyticsData, getVideoAnalyticsData } from '@/utils/commonAPICalls'
import { formatBytes1000 } from '@/utils/helper'
import { toast } from 'sonner'

export default function PlanDetails () {
  const { orgData } = use(DataContext)
  const [bandwidthConsumption, setBandwidthConsumption] = useState('')
  const [videoBandwidthConsumption, setVideoBandwidthConsumption] = useState('')

  const { data: upcomingInvoice, isLoading: isUpcomingInvoiceLoading } = useQuery({
    url: `${endpoints.organization.billing.upcomingInvoice}`
  })
  const { data: billing, isLoading: isBillingLoading } = useQuery({
    url: `${endpoints.organization.billing.base}`
  })

  useEffect(() => {
    async function fetchImageData () {
      try {
        const bandwidthData = await getImageAnalyticsData()
        const formattedBandwidth = formatBytes1000(bandwidthData, 1)
        setBandwidthConsumption(formattedBandwidth)
      } catch (error) {
        toast.error(error.error.message)
      }
    }
    async function fetchVideoData () {
      try {
        const bandwidthData = await getVideoAnalyticsData()
        const formattedBandwidth = formatBytes1000(bandwidthData, 1)
        setVideoBandwidthConsumption(formattedBandwidth)
      } catch (error) {
        toast.error(error.error.message)
      }
    }
    fetchImageData()
    fetchVideoData()
  }, [])

  if (isUpcomingInvoiceLoading || isBillingLoading) return <SkeletonBasics />
  return (
    <>
      <ContentSection
        title='Plan Details'
        className='max-w-none'
      >
        <CurrentPlanDetail orgData={orgData} billing={billing} bandwidthConsumption={bandwidthConsumption} videoBandwidthConsumption={videoBandwidthConsumption} />
        <UpcomingInvoices
          upcomingInvoice={upcomingInvoice}
          billing={billing}
        />
      </ContentSection>
    </>
  )
}
