import { Button } from '@/components/ui/button'
import PropTypes from 'prop-types'
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { Form, FormControl, FormField, FormItem, FormLabel } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { useForm, useWatch } from 'react-hook-form'
import { ASSIGNABLE_ROLES, LOGIN_OPTIONS } from '@/utils/constants'
import MultipleSelector from '@/components/ui/multi-select'
import { useEffect } from 'react'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'components/ui/select'
import { LoadingSpinner } from '@/components/ui/spinner'
import { toast } from 'sonner'
import { endpoints } from '@/utils/endpoints'
import http from '@/utils/http'

export default function AddUserFormDialog ({ isDialogOpen, setIsDialogOpen, allImageSources, allVideoSources, _createUser }) {
  const form = useForm({ mode: 'onChange', defaultValues: { name: '', email: '', roles: [], sources: [], video_sources: [] } })
  const selectedRoles = useWatch({ control: form.control, name: 'roles' })
  const { formState: { isSubmitting } } = form

  // Check if a selected role is specifically related to video sources or image sources
  const hasImageRole = selectedRoles.some(role => role.value === 'source-specific')
  const hasVideoRole = selectedRoles.some(role => role.value === 'video-source-specific')

  useEffect(() => {
    if (!hasImageRole) {
      form.setValue('sources', [])
    }
    if (!hasVideoRole) {
      form.setValue('video_sources', [])
    }
    if (!isDialogOpen) {
      form.reset()
    }
  }, [hasImageRole, hasVideoRole, form, isDialogOpen])

  useEffect(() => {
    async function fetchUserDataByID () {
      try {
        const data = await http({}).get(endpoints.organization.account.getaccountbyid.replace(':account_id', isDialogOpen?.id))
        if (data) {
          const formValues = {
            name: data.name || '',
            email: data.email || '',
            roles: data.roles.map(role => ({ label: role, value: role })),
            login_type: data.login_type || ''
          }
          if (Array.isArray(data.video_sources) && data.video_sources.length > 0) {
            formValues.video_sources = data.video_sources
              .map(sourceId => allVideoSources.find(videoSource => videoSource.id === sourceId))
              .filter(Boolean)
              .map(source => ({ label: source.name, value: source.id }))
          }

          if (Array.isArray(data.sources) && data.sources.length > 0) {
            formValues.sources = data.sources
              .map(sourceId => allImageSources.find(imageSource => imageSource.id === sourceId))
              .filter(Boolean)
              .map(source => ({ label: source.namespace, value: source.id }))
          }

          form.reset(formValues)
        }
      } catch (error) {
        toast(error?.error?.message)
      }
    }

    if (isDialogOpen?.id) {
      fetchUserDataByID()
    }
  }, [isDialogOpen, form, allVideoSources, allImageSources])

  return (
    <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{isDialogOpen?.id ? 'Edit User' : 'Add New User'}</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(_createUser)} className='space-y-8'>
            {
                !isDialogOpen?.id &&
                  <FormField
                    control={form.control}
                    name='name'
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Name*</FormLabel>
                        <FormControl>
                          <Input placeholder='Please enter a name for user' {...field} required />
                        </FormControl>
                      </FormItem>
                    )}
                  />
            }
            <FormField
              control={form.control}
              name='email'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email*</FormLabel>
                  <FormControl>
                    <Input placeholder='Please enter a email for user' {...field} required disabled={isDialogOpen?.id} />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name='roles'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Roles*</FormLabel>
                  <FormControl>
                    <MultipleSelector
                      {...field}
                      defaultOptions={ASSIGNABLE_ROLES}
                      placeholder='Select user roles'
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            {
              hasImageRole &&
                <FormField
                  control={form.control}
                  name='sources'
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Sources*</FormLabel>
                      <FormControl>
                        <MultipleSelector
                          {...field}
                          defaultOptions={allImageSources.map(({ namespace, id }) => ({
                            label: namespace,
                            value: id
                          }))}
                          placeholder='Select image sources'
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />
            }
            {
              hasVideoRole &&
                <FormField
                  control={form.control}
                  name='video_sources'
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Video Collections*</FormLabel>
                      <FormControl>
                        <MultipleSelector
                          {...field}
                          defaultOptions={allVideoSources.map(({ name, id }) => ({
                            label: name,
                            value: id
                          }))}
                          placeholder='Select video sources'
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />
            }
            <FormField
              control={form.control}
              name='login_type'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Login Type*</FormLabel>
                  <Select onValueChange={field.onChange} value={field.value}>
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder='Select login type' />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {LOGIN_OPTIONS.map((login, i) => (
                        <SelectItem key={i} value={login.value}>{login.label}</SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </FormItem>
              )}
            />
            <DialogFooter>
              <Button type='button' variant='outline' onClick={() => setIsDialogOpen(false)} className='cursor-pointer'>Close</Button>
              <Button type='submit' className='cursor-pointer' disabled={isSubmitting}>{isSubmitting && <LoadingSpinner />}{isDialogOpen?.id ? 'Edit User' : 'Add User'}</Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}

AddUserFormDialog.propTypes = {
  isDialogOpen: PropTypes.bool.isRequired,
  setIsDialogOpen: PropTypes.func.isRequired,
  allImageSources: PropTypes.array.isRequired,
  allVideoSources: PropTypes.array.isRequired,
  _createUser: PropTypes.func.isRequired
}
