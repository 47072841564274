import { Navigate, useLocation } from 'react-router'
import PropTypes from 'prop-types'
import { use } from 'react'
import { DataContext } from '../contexts/DataProvider'
import rbac from '@/utils/rbac.json'

// Utility to extract important RBAC key from the path
const extractRBACKey = (pathname) => {
  const segments = pathname.split('/').filter(Boolean)
  for (const segment of [...segments].reverse()) {
    if (rbac[segment]) {
      return segment
    }
  }
  return ''
}

// Utility to check permissions for a specific URL
export const checkPermissionForUrl = (url, userRoles) => {
  if (!userRoles) return false
  const rbacKey = extractRBACKey(url.replace(/^\//, ''))
  const allowedRoles = rbac[rbacKey] || []
  return allowedRoles.length === 0 || userRoles.some(role => allowedRoles.includes(role))
}

const useRBACCheck = () => {
  const { userData } = use(DataContext)
  const location = useLocation()

  if (!userData) return { isAllowed: false, loading: true }

  const userRoles = userData.roles || []

  const rbacKey = extractRBACKey(location.pathname.replace(/^\//, ''))
  const allowedRoles = rbac[rbacKey] || []

  const isAllowed = allowedRoles.length === 0 || userRoles.some(role => allowedRoles.includes(role))

  return { isAllowed, loading: false }
}

export const RoleBasedRoute = ({ element }) => {
  const { isAllowed, loading } = useRBACCheck()

  if (loading) return null

  return isAllowed ? element : <Navigate to='/unauthorized' replace />
}

export const RoleBasedRouteChildren = () => {
  const { isAllowed, loading } = useRBACCheck()
  return { isAllowed, loading }
}

RoleBasedRoute.propTypes = {
  element: PropTypes.node.isRequired
}
