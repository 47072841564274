import * as React from 'react'
import { Slot } from '@radix-ui/react-slot'
import { cva } from 'class-variance-authority'

import { cn } from '@/utils/helper'

const badgeVariants = cva(
  'inline-flex items-center justify-center rounded-md border px-2 py-0.5 text-xs font-medium w-fit whitespace-nowrap shrink-0 [&>svg]:size-3 gap-1 [&>svg]:pointer-events-none focus-visible:border-ring focus-visible:ring-ring/50 focus-visible:ring-[3px] aria-invalid:ring-destructive/20 dark:aria-invalid:ring-destructive/40 aria-invalid:border-destructive transition-[color,box-shadow] overflow-hidden',
  {
    variants: {
      variant: {
        primary:
          'border-transparent bg-primary/15 text-primary [a&]:hover:bg-primary/90',
        white:
          'border-transparent bg-foreground text-accent [a&]:hover:bg-foreground/90',
        secondary:
          'border-transparent bg-secondary text-secondary-foreground [a&]:hover:bg-secondary/90',
        success:
          'border-transparent bg-success/15 text-success [a&]:hover:bg-success/90',
        info:
          'border-transparent bg-info/15 text-info [a&]:hover:bg-info/90',
        warning:
          'border-transparent bg-warning/15 text-warning [a&]:hover:bg-warning/90',
        destructive:
          'border-transparent bg-destructive/20 text-errored [a&]:hover:bg-destructive/90 focus-visible:ring-destructive/20 dark:focus-visible:ring-destructive/40',
        outline:
          'text-foreground [a&]:hover:bg-accent [a&]:hover:text-accent-foreground'
      }
    },
    size: {
      default: 'h-9 px-4 py-2 has-[>svg]:px-3',
      xs: 'h-6 px-2.5 py-1.5 has-[>svg]:px-2 text-xs',
      sm: 'h-8 rounded-md px-4 has-[>svg]:px-3.5 text-sm',
      lg: 'h-10 rounded-md px-6 has-[>svg]:px-4',
      icon: 'size-9'
    },
    defaultVariants: {
      variant: 'default',
      size: 'default'
    }
  }
)

function Badge ({
  className,
  variant,
  size,
  asChild = false,
  ...props
}) {
  const Comp = asChild ? Slot : 'span'

  return (
    (
      <Comp
        data-slot='badge'
        className={cn(badgeVariants({ variant, size }), className)}
        {...props}
      />
    )
  )
}

export { Badge, badgeVariants }
