import { Skeleton } from '@/components/ui/skeleton'
import { Separator } from '@/components/ui/separator'

export function SkeletonBasics () {
  return (
    <>
      <div className='flex flex-1 flex-col'>
        <div className='flex-none'>
          <div className='flex flex-col items-stat justify-start gap-2.5'>
            <Skeleton className='w-[350px] h-5.5 bg-accent' />
            <Skeleton className='w-[200px] h-3 bg-accent' />
          </div>
        </div>
        <Separator className='my-4 flex-none' />
        <div className='flex flex-col gap-4 max-w-2xl h-svh'>
          <Skeleton className='h-4 bg-accent' />
          <Skeleton className='h-4 w-[600] bg-accent' />
          <Skeleton className='h-4 w-[590px] bg-accent' />
          <Skeleton className='h-4 w-[530px] bg-accent/80' />
          <Skeleton className='h-4 w-[500px] bg-accent/50' />
          <Skeleton className='h-4 w-[450px] bg-accent/50' />
          <Skeleton className='h-4 w-[450px] bg-accent/50' />
          <Skeleton className='h-4 w-[450px] bg-accent/50' />
          <Skeleton className='h-4 w-[350px] bg-accent/20' />
          <Skeleton className='h-4 w-[300px] bg-accent/20' />
          <Skeleton className='h-4 w-[200px] bg-accent/20' />
        </div>
      </div>
    </>
  )
}
