import { useState } from 'react'
import ContentSection from '@/components/shared-sections/content-section'
import BillingDetailsForm from './details-form'
import useQuery from '@/utils/useQuery'
import { endpoints } from '@/utils/endpoints'
import { SkeletonBasics } from '@/components/shimmers/basic'
import { Button } from '@/components/ui/button'
import { SquarePen } from 'lucide-react'

export default function BillingDetails () {
  const [editMode, setEditMode] = useState(false)
  const [isDirty, setIsDirty] = useState(false)
  const [formSubmit, setFormSubmit] = useState(null)
  const [formReset, setFormReset] = useState(null)

  const { data: billingDetails, isLoading } = useQuery({
    url: `${endpoints.organization.billing.details}`
  })

  if (isLoading) return <SkeletonBasics />

  const handleEditButton = () => {
    if (!editMode) {
      return (
        <Button variant='secondary' onClick={() => setEditMode(true)}>
          <SquarePen className='mr-1' />
          Edit
        </Button>
      )
    }

    return (
      <div className='flex gap-1'>
        <Button
          variant='default'
          onClick={() => {
            if (formSubmit) formSubmit()
          }}
          disabled={!isDirty}
        >
          Save Changes
        </Button>
        <Button
          variant='outline'
          onClick={() => {
            if (formReset) formReset()
            setEditMode(false)
          }}
        >
          Cancel
        </Button>
      </div>
    )
  }

  return (
    <ContentSection
      title='Billing Details'
      desc='Update your billing details.'
      has_action={handleEditButton()}
    >
      <BillingDetailsForm
        billingDetails={billingDetails}
        editMode={editMode}
        setEditMode={setEditMode}
        setIsDirty={setIsDirty}
        setFormSubmit={setFormSubmit}
        setFormReset={setFormReset}
      />
    </ContentSection>
  )
}
