'use client'

import { Command as CommandPrimitive, useCommandState } from 'cmdk'
import { X } from 'lucide-react'
import * as React from 'react'
import { forwardRef, useEffect } from 'react'

import { Badge } from '@/components/ui/badge'
import { Command, CommandGroup, CommandItem, CommandList } from '@/components/ui/command'
import { cn } from '@/utils/helper'
import { useDebounce } from '@/hooks/use-debounce'
import PropTypes from 'prop-types'
import { CheckIcon, ChevronDownIcon, ChevronUpIcon } from "lucide-react"

function transToGroupOption (options, groupBy) {
  if (options.length === 0) {
    return {}
  }
  if (!groupBy) {
    return { '': options }
  }

  const groupOption = {}
  options.forEach((option) => {
    const key = option[groupBy] || ''
    if (!groupOption[key]) {
      groupOption[key] = []
    }
    groupOption[key].push(option)
  })
  return groupOption
}

function removePickedOption (groupOption, picked) {
  const cloneOption = JSON.parse(JSON.stringify(groupOption))
  for (const [key, value] of Object.entries(cloneOption)) {
    cloneOption[key] = value.filter((val) => !picked.find((p) => p.value === val.value))
  }
  return cloneOption
}

/**
 * The `CommandEmpty` of shadcn/ui will cause the cmdk empty not rendering correctly.
 * So we create one and copy the `Empty` implementation from `cmdk`.
 *
 * @reference: https://github.com/hsuanyi-chou/shadcn-ui-expansions/issues/34#issuecomment-1949561607
 **/
const CommandEmpty = forwardRef(({ className, ...props }, forwardedRef) => {
  const render = useCommandState((state) => state.filtered.count === 0)

  if (!render) return null

  return (
    <div
      ref={forwardedRef}
      className={cn('py-6 text-center text-sm', className)}
      role='presentation'
      {...props}
    />
  )
})

CommandEmpty.displayName = 'CommandEmpty'

// Child component to handle dropdown content with useCommandState
const CommandContent = ({
  isLoading,
  loadingIndicator,
  emptyIndicator,
  creatable,
  inputValue,
  selected,
  maxSelected,
  onMaxSelected,
  onChange,
  setInputValue,
  selectables,
  selectFirstItem,
  onSearch,
  setOpen
}) => {
  const filteredCount = useCommandState((state) => state.filtered.count)

  const createOption =
    creatable && inputValue.length > 0 && filteredCount === 0 && !isLoading
      ? (
        <CommandItem
          value={inputValue}
          className='cursor-pointer'
          onMouseDown={(e) => {
            e.preventDefault()
            e.stopPropagation()
          }}
          onSelect={(value) => {
            if (selected.length >= maxSelected) {
              onMaxSelected?.(selected.length)
              return
            }
            setInputValue('')
            const newOptions = [...selected, { value, label: value }]
            onChange?.(newOptions)
          }}
        >
          {`Create "${inputValue}"`}
        </CommandItem>
        )
      : undefined

  const emptyItem =
    emptyIndicator && filteredCount === 0
      ? (
          onSearch && !creatable && Object.keys(selectables).length === 0
            ? (
              <CommandItem value='-' disabled>
                {emptyIndicator}
              </CommandItem>
              )
            : (
              <CommandEmpty>{emptyIndicator}</CommandEmpty>
              )
        )
      : undefined

  return (
    <CommandList
      className='absolute top-1 z-10 w-full rounded-md border bg-popover text-popover-foreground shadow-md outline-none animate-in'
      onMouseLeave={() => setOpen(false)}
      onMouseEnter={() => setOpen(true)}
    >
      {isLoading
        ? (
          <>{loadingIndicator}</>
          )
        : (
          <>
            {emptyItem}
            {createOption}
            {!selectFirstItem && <CommandItem value='-' className='hidden' />}
            {Object.entries(selectables).map(([key, dropdowns]) => (
              <CommandGroup key={key} heading={key} className='h-full overflow-auto'>
                {dropdowns.map((option) => (
                  <CommandItem
                    key={option.value}
                    value={option.label}
                    disabled={option.disable}
                    onMouseDown={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                    }}
                    onSelect={() => {
                      if (selected.length >= maxSelected) {
                        onMaxSelected?.(selected.length)
                        return
                      }
                      setInputValue('')
                      const newOptions = [...selected, option]
                      onChange?.(newOptions)
                      setOpen(false)
                    }}
                    className={cn(
                      'cursor-pointer',
                      option.disable && 'cursor-default text-muted-foreground'
                    )}
                  >
                    {
                      option.title && option.description  ?
                      <div className='flex flex-col px-0 py-0.5 justify-center'>
                        <span className='text-base'>{option.title}</span>
                        <span className='text-sm text-muted-foreground'>{option.description}</span>
                      </div>
                      :
                      <div className='flex items-center gap-2'>
                        {option.icon && <option.icon className='h-4 w-4' />}
                        <span>{option.label}</span>
                      </div>
                    }
                  </CommandItem>
                ))}
              </CommandGroup>
            ))}
          </>
          )}
    </CommandList>
  )
}

const MultipleSelector = React.forwardRef(
  (
    {
      value,
      onChange,
      placeholder,
      defaultOptions: arrayDefaultOptions = [],
      options: arrayOptions,
      delay,
      onSearch,
      onSearchSync,
      loadingIndicator,
      emptyIndicator = <p className='text-center text-sm'>no results found.</p>,
      maxSelected = Number.MAX_SAFE_INTEGER,
      onMaxSelected,
      hidePlaceholderWhenSelected,
      disabled,
      groupBy,
      className,
      badgeClassName,
      selectFirstItem = true,
      creatable = false,
      triggerSearchOnFocus = false,
      commandProps,
      inputProps,
      hideClearAllButton = false
    },
    ref
  ) => {
    const inputRef = React.useRef(null)
    const dropdownRef = React.useRef(null)
    const [open, setOpen] = React.useState(false)
    const [onScrollbar, setOnScrollbar] = React.useState(false)
    const [isLoading, setIsLoading] = React.useState(false)
    const [selected, setSelected] = React.useState(value || [])
    const [options, setOptions] = React.useState(transToGroupOption(arrayDefaultOptions, groupBy))
    const [inputValue, setInputValue] = React.useState('')
    const debouncedSearchTerm = useDebounce(inputValue, delay || 500)
    React.useImperativeHandle(
      ref,
      () => ({
        selectedValue: [...selected],
        input: inputRef.current,
        focus: () => inputRef?.current?.focus(),
        reset: () => setSelected([])
      }),
      [selected]
    )

    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        inputRef.current &&
        !inputRef.current.contains(event.target)
      ) {
        setOpen(false)
        inputRef.current.blur()
      }
    }

    const handleUnselect = React.useCallback(
      (option) => {
        const newOptions = selected.filter((s) => s.value !== option.value)
        setSelected(newOptions)
        onChange?.(newOptions)
      },
      [onChange, selected]
    )

    const handleKeyDown = React.useCallback(
      (e) => {
        const input = inputRef.current
        if (input) {
          if (e.key === 'Delete' || e.key === 'Backspace') {
            if (input.value === '' && selected.length > 0) {
              const lastSelectOption = selected[selected.length - 1]
              if (!lastSelectOption.fixed) {
                handleUnselect(selected[selected.length - 1])
              }
            }
          } else if (e.key === 'Escape') {
            input.blur()
            setOpen(false)
          } else if (e.key === 'Tab') {
            if (open) {
              setOpen(false) // Close dropdown on Tab out
            }
            // Do not preventDefault; let the browser handle Tab naturally
          }
        }
      },
      [handleUnselect, selected, open]
    )

    useEffect(() => {
      if (open) {
        document.addEventListener('mousedown', handleClickOutside)
        document.addEventListener('touchend', handleClickOutside)
      } else {
        document.removeEventListener('mousedown', handleClickOutside)
        document.removeEventListener('touchend', handleClickOutside)
      }
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
        document.removeEventListener('touchend', handleClickOutside)
      }
    }, [open])

    useEffect(() => {
      if (value) {
        setSelected(value)
      }
    }, [value])

    useEffect(() => {
      if (!arrayOptions || onSearch) {
        return
      }
      const newOption = transToGroupOption(arrayOptions || [], groupBy)
      if (JSON.stringify(newOption) !== JSON.stringify(options)) {
        setOptions(newOption)
      }
    }, [arrayDefaultOptions, arrayOptions, groupBy, onSearch, options])

    useEffect(() => {
      const doSearchSync = () => {
        const res = onSearchSync?.(debouncedSearchTerm)
        setOptions(transToGroupOption(res || [], groupBy))
      }

      const exec = async () => {
        if (!onSearchSync || !open) return
        if (triggerSearchOnFocus || debouncedSearchTerm) {
          doSearchSync()
        }
      }

      void exec()
    }, [debouncedSearchTerm, groupBy, open, triggerSearchOnFocus, onSearchSync])

    useEffect(() => {
      const doSearch = async () => {
        setIsLoading(true)
        const res = await onSearch?.(debouncedSearchTerm)
        setOptions(transToGroupOption(res || [], groupBy))
        setIsLoading(false)
      }

      const exec = async () => {
        if (!onSearch || !open) return
        if (triggerSearchOnFocus || debouncedSearchTerm) {
          await doSearch()
        }
      }

      void exec()
    }, [debouncedSearchTerm, groupBy, open, triggerSearchOnFocus, onSearch])

    const selectables = React.useMemo(
      () => removePickedOption(options, selected),
      [options, selected]
    )

    const commandFilter = React.useCallback(() => {
      if (commandProps?.filter) {
        return commandProps.filter
      }
      if (creatable) {
        return (value, search) => {
          return value.toLowerCase().includes(search.toLowerCase()) ? 1 : -1
        }
      }
      return undefined
    }, [creatable, commandProps?.filter])

    return (
      <Command
        ref={dropdownRef}
        {...commandProps}
        onKeyDown={(e) => {
          handleKeyDown(e)
          commandProps?.onKeyDown?.(e)
        }}
        className={cn('h-auto overflow-visible bg-transparent', commandProps?.className)}
        shouldFilter={
          commandProps?.shouldFilter !== undefined ? commandProps.shouldFilter : !onSearch
        }
        filter={commandFilter()}
      >
        <div
          className={cn(
            'min-h-10 rounded-md border border-input text-base md:text-sm',
            {
              'px-3 py-2': selected.length !== 0,
              'cursor-text': !disabled && selected.length !== 0
            },
            className
          )}
          onClick={() => {
            if (!disabled) inputRef?.current?.focus()
          }}
        >
          <div className='relative flex flex-wrap gap-2 items-center cursor-pointer'>
            {selected.map((option) => (
              <Badge
                key={option.value}
                variant='secondary'
                className={cn(
                  'data-[disabled]:bg-muted-foreground data-[disabled]:text-muted data-[disabled]:hover:bg-muted-foreground',
                  'data-[fixed]:bg-muted-foreground data-[fixed]:text-muted data-[fixed]:hover:bg-muted-foreground whitespace-normal',
                  'rounded-base px-3 py-1 -ml-1',
                  badgeClassName
                )}
                data-fixed={option.fixed}
                data-disabled={disabled || undefined}
              >
                {option.icon && <CheckIcon className='h-4 w-4' />}
                {option.title && option.description
                  ? option.title
                  : option.label
                }
                <button
                  className={cn(
                    'ml-1 rounded-full outline-none ring-offset-background focus:ring-2 focus:ring-ring focus:ring-offset-2 cursor-pointer',
                    (disabled || option.fixed) && 'hidden'
                  )}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleUnselect(option)
                    }
                  }}
                  onMouseDown={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                  }}
                  onClick={() => handleUnselect(option)}
                >
                  <X className='h-3 w-3' />
                </button>
              </Badge>
            ))}
            <CommandPrimitive.Input
              {...inputProps}
              ref={inputRef}
              value={inputValue}
              disabled={disabled}
              onValueChange={(value) => {
                setInputValue(value)
                inputProps?.onValueChange?.(value)
              }}
              onBlur={(event) => {
                if (!onScrollbar) setOpen(false)
                inputProps?.onBlur?.(event)
              }}
              onFocus={(event) => {
                setOpen(true) // Open dropdown on focus
                inputProps?.onFocus?.(event)
              }}
              onClick={() => setOpen(true)}
              placeholder={hidePlaceholderWhenSelected && selected.length !== 0 ? '' : placeholder}
              className={cn(
                'flex-1 bg-transparent outline-none placeholder:text-muted-foreground',
                {
                  'w-full': hidePlaceholderWhenSelected,
                  'px-3 py-2': selected.length === 0,
                  'ml-1': selected.length !== 0
                },
                inputProps?.className
              )}
            />
            {
              // decide up and low arrow icon based on open state
              open
                ? <ChevronUpIcon className='absolute right-2 h-4 w-4 opacity-70' />
                : <ChevronDownIcon className='absolute right-2 h-4 w-4 opacity-70' />
            }
          </div>
        </div>
        <div className='relative'>
          {open && (
            <CommandContent
              isLoading={isLoading}
              loadingIndicator={loadingIndicator}
              emptyIndicator={emptyIndicator}
              creatable={creatable}
              inputValue={inputValue}
              selected={selected}
              maxSelected={maxSelected}
              onMaxSelected={onMaxSelected}
              onChange={(newOptions) => {
                setSelected(newOptions)
                onChange?.(newOptions)
              }}
              setInputValue={setInputValue}
              selectables={selectables}
              selectFirstItem={selectFirstItem}
              onSearch={onSearch}
              setOpen={setOnScrollbar}
            />
          )}
        </div>
      </Command>
    )
  }
)

MultipleSelector.displayName = 'MultipleSelector'
export default MultipleSelector

MultipleSelector.propTypes = {
  value: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired,
  placeholder: PropTypes.string,
  emptyText: PropTypes.string,
  selectedOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  groupBy: PropTypes.string,
  onSearch: PropTypes.func,
  onSearchSync: PropTypes.func,
  creatable: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  badgeClassName: PropTypes.string,
  maxSelected: PropTypes.number,
  onMaxSelected: PropTypes.func,
  hidePlaceholderWhenSelected: PropTypes.bool,
  delay: PropTypes.number,
  loadingIndicator: PropTypes.node,
  emptyIndicator: PropTypes.node,
  selectFirstItem: PropTypes.bool,
  triggerSearchOnFocus: PropTypes.bool,
  commandProps: PropTypes.object,
  inputProps: PropTypes.object,
  hideClearAllButton: PropTypes.bool,
  defaultOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  )
}

CommandEmpty.propTypes = {
  className: PropTypes.string
}
