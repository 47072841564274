import { seo } from '@/utils/helper'
import { BreadcrumbComponents } from '@/components/ui/breadcrumb'
import { Header } from '@/components/ui-layout/header'
import { Main } from '@/components/ui-layout/main'
import { Separator } from '@/components/ui/separator'
import { Input } from '@/components/ui/input'
import { Button } from '@/components/ui/button'
import { Plus } from 'lucide-react'
import useQuery from '@/utils/useQuery'
import { endpoints } from '@/utils/endpoints'
import { SkeletonCard } from '@/components/shimmers/card'
import CollectionLists from './all-collections'
import AddCollection from './add-collection-form'

export default function Collections () {
  seo({
    title: 'All Stream - Gumlet'
  })

  const { data: collections, isLoading } = useQuery({
    url: `${endpoints.stream.sources.base}`
  })

  return (
    <>
      <Header>
        <BreadcrumbComponents
          Breadcrum1='Live Stream'
          Breadcrum1Link='/dashboard/video'
          Breadcrum3='Collections'
        />
      </Header>
      {/* ===== Content ===== */}
      <Main fixed>
        <div>
          <h1 className='text-2xl font-medium'>
            All Collections
          </h1>
          <p className='text-muted-foreground'>
            Manage your live stream collections
          </p>
        </div>
        <div className='my-4 flex items-end justify-between sm:my-0 sm:items-center'>
          <div className='flex flex-col gap-4 sm:my-4 sm:flex-row'>
            <Input
              placeholder='Search collection'
              className='h-9 w-40 lg:w-[250px]'
            />
          </div>
          <AddCollection />
        </div>
        <Separator className='shadow' />
        {
          isLoading && <SkeletonCard
            description='Fetching collections'
                       />
        }

        <CollectionLists collections={collections} />
      </Main>
    </>
  )
}
