import ContentSection from '@/components/shared-sections/content-section'
import { endpoints } from '@/utils/endpoints'
import useQuery from '@/utils/useQuery'
import CouponTable from './coupon-table'
import ReedemCouponForm from './reedem-coupon-form'
import http from '@/utils/http'
import { toast } from 'sonner'
import { use } from 'react'
import { DataContext } from '@/contexts/DataProvider'
import { SkeletonBasics } from '@/components/shimmers/basic'

export default function Coupons () {
  const { userData } = use(DataContext)

  const { data: coupons, setData, isLoading } = useQuery({
    url: `${endpoints.organization.coupon.base}`
  })

  if (isLoading) return <SkeletonBasics />

  async function onSubmit (data, reset) {
    const promise = http({}).post(endpoints.organization.coupon.add, data)
    toast.promise(promise, {
      loading: 'Please wait while we redeem the coupon...',
      success: 'Congratulations 🎉   You have redeemed a coupon.',
      error: (error) => error?.error?.message || 'An error occurred while redeeming coupon'
    })

    try {
      const response = await promise
      const updatedCoupons = [...(coupons.coupons || [])]
      updatedCoupons.pop()
      updatedCoupons.push(response.new_coupon_added)
      setData((prev) => ({
        ...prev,
        coupons: updatedCoupons
      }))
      reset()
    } catch (error) {
      console.log('An error occurred while redeeming coupon', error)
    }
  }

  return (
    <ContentSection
      title='Coupons'
      desc='Redeem your coupons here and get discounts.'
      className='max-w-none'
    >
      <div className='flex gap-8 flex-col'>
        <ReedemCouponForm onSubmit={onSubmit} />
        <CouponTable coupons={coupons.coupons} userData={userData} />
      </div>
    </ContentSection>
  )
}
