import PropTypes from 'prop-types'
import {
  flexRender,
  getCoreRowModel,
  useReactTable
} from '@tanstack/react-table'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from '@/components/ui/table'
import { Card } from '@/components/ui/card'
import { convertEpochToTimezone } from '@/utils/helper'
import { Badge } from '@/components/ui/badge'

export default function CouponTable ({ coupons, userData }) {
  const getQuantity = (coupon, row) => {
    if (row.original.origin === 'gumlet' && row.original.feature_name) {
      return coupon
    } else if (
      row.original.origin === 'stripe' &&
      row.original.discount_type === 'percent'
    ) {
      return coupon
    } else if (
      row.original.origin === 'stripe' &&
      row.original.discount_type === 'amount'
    ) {
      return row.original.currency.toUpperCase() + coupon
    } else {
      return (coupon || ' ') + 'Credits'
    }
  }

  const columns = [
    {
      id: 'code',
      accessorKey: 'code',
      header: 'Coupon',
      cell: ({ getValue, row }) => getValue() || row.original.coupon?.id || '-'
    },
    {
      id: 'description',
      accessorKey: 'description',
      header: 'Description',
      cell: ({ getValue, row }) => getValue() || row.original.coupon?.name || '-'
    },
    {
      id: 'value',
      accessorKey: 'value',
      header: 'Quantity',
      cell: ({ getValue, row }) => getQuantity(getValue(), row) || '-'
    },
    {
      id: 'type',
      accessorKey: 'type',
      header: 'Type',
      cell: ({ getValue, row }) => <Badge variant='outline' size='sm'>{getValue() || row.original.coupon?.duration}</Badge> || '-'
    },
    {
      id: 'redemption_date',
      accessorKey: 'redemption_date',
      header: 'Redeemed Date',
      cell: ({ getValue, row }) => convertEpochToTimezone(getValue() || row.original.start, userData.timezone) || '-'
    }
  ]

  const table = useReactTable({
    data: coupons,
    columns,
    getCoreRowModel: getCoreRowModel()
  })
  return (
    <div className='space-y-4 w-full'>
      <Card className='rounded-md border shadow-none'>
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id} className='group/row'>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead
                      key={header.id}
                      colSpan={header.colSpan}
                      className={header.column.columnDef.meta?.className ?? ''}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                    </TableHead>
                  )
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length
              ? (
                  table.getRowModel().rows.map((row) => (
                    <TableRow
                      key={row.id}
                      data-state={row.getIsSelected() && 'selected'}
                      className='group/row'
                    >
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <TableCell
                            key={cell.id}
                            className={cell.column.columnDef.meta?.className ?? ''}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            ) || '-'}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  ))
                )
              : (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    className='h-24 text-center'
                  >
                    <p className='mt-4'>No coupon applied.</p>
                  </TableCell>
                </TableRow>
                )}
          </TableBody>
        </Table>
      </Card>
    </div>
  )
}

CouponTable.propTypes = {
  coupons: PropTypes.array,
  userData: PropTypes.object
}
