import ContentSection from '@/components/shared-sections/content-section'
import { endpoints } from '@/utils/endpoints'
import useQuery from '@/utils/useQuery'
import http from '@/utils/http'
import { toast } from 'sonner'
import SubUserTable from './user-table'
import { DataContext } from '@/contexts/DataProvider'
import { use, useState } from 'react'
import { Button } from '@/components/ui/button'
import { UserPlus } from 'lucide-react'
import { IMAGE_SOURCES_PAGINATION_LIMIT, INCLUDE_PLANS, VIDEO_SOURCES_PAGINATION_LIMIT } from '@/utils/constants'
import AddUserFormDialog from './add-user-form'
import DeleteActionDialog from '@/components/delete-action-dialog'
import { SkeletonBasics } from '@/components/shimmers/basic'
import UpgradePlanButton from '@/components/upgrade-plan-button'

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
export default function Accounts () {
  const { userData, orgData } = use(DataContext)
  const [isDialogOpen, setIsDialogOpen] = useState(null)
  const [isDeleteOpen, setIsDeleteOpen] = useState(null)

  const isSubUserAllowed = !INCLUDE_PLANS.subUser.includes(orgData.plan_data.video.plan) || !INCLUDE_PLANS.subUser.includes(orgData.plan_data.image.plan)

  const { data: subUsers, setData, isLoading } = useQuery({
    url: `${endpoints.organization.account.base}`,
    hasCondition: !isSubUserAllowed
  })

  const { data: allImageSources, isLoading: isImageSourcesLoading } = useQuery({
    url: `${endpoints.image.sources.base + IMAGE_SOURCES_PAGINATION_LIMIT}`
  })

  const { data: allVideoSources, isLoading: isVideoSourcesLoading } = useQuery({
    url: `${endpoints.video.sources.base + VIDEO_SOURCES_PAGINATION_LIMIT}`
  })

  if ((isSubUserAllowed && isLoading) || isImageSourcesLoading || isVideoSourcesLoading) return <SkeletonBasics />

  async function _createUser (data) {

    if(!data){
      return toast.error('Please fill out all required fields')
    }
    for (const { key, message, condition } of [
      { key: 'roles', message: 'Select one or more roles' },
      { key: 'login_type', message: 'Select a login type' },
      {
        key:'email',
        message: 'Please enter a valid email address',
        condition: data?.email && !emailRegex.test(data?.email)
      },
      {
        key: 'sources',
        message: 'Select one or more image sources',
        condition: data?.roles.some(r => r.value === 'source-specific') && !data.sources?.length
      },
      {
        key: 'video_sources',
        message: 'Select one or more video sources',
        condition: data?.roles.some(r => r.value === 'video-source-specific') && !data.video_sources?.length
      }
    ]) {
      if ((key !== 'sources' && key !== 'video_sources' && !data[key]?.length) || condition) {
        return toast.error(message)
      }
    }
    const payload = {
      name: data.name,
      email: data.email,
      inviter_id: userData.id,
      roles: data.roles.map(role => role.value),
      login_type: data.login_type,
      ...(data.sources?.length && { sources: data.sources.map(s => s.value) }),
      ...(data.video_sources?.length && { video_sources: data.video_sources.map(s => s.value) })
    }
    try {
      if (isDialogOpen?.id) {
        const response = await http({}).post(endpoints.organization.account.edit.replace(':id', isDialogOpen?.id), payload)
        setData(prev => ({ ...prev, subUsers: prev.subUsers.map(user => user.id === isDialogOpen.id ? response : user) }))
      } else {
        const response = await http({}).post(endpoints.organization.account.add, payload)
        setData(prev => ({ ...prev, subUsers: [...prev.subUsers, response] }))
      }
      setIsDialogOpen(false)
      toast.success(`User ${isDialogOpen?.id ? 'edited' : 'added'} successfully`)
    } catch (error) {
      toast.error(error.error.message || error.error.error.message)
    }
  }

  const _deleteUser = async (id) => {
    try {
      await http({}).delete(endpoints.organization.account.edit.replace(':id', id))
      toast.success('User deleted successfully')
      setData(prev => ({ ...prev, subUsers: prev.subUsers.filter(user => user.id !== id) }))
      setIsDeleteOpen(null)
    } catch (error) {
      toast.error(error?.error?.message)
    }
  }

  // Filter out the current user
  const filteredSubUsers = subUsers && subUsers.subUsers?.filter(user => user?.id !== userData?.id) || []
  return (
    <ContentSection
      title='Manage Users'
      desc='Invite a team member to collaborate.'
      className='max-w-none'
      has_action={
        <Button variant='white' className='cursor-pointer' onClick={() => setIsDialogOpen(true)}><UserPlus className='h-2 w-2' />Add User</Button>
      }
    >
      {
        isSubUserAllowed
          ? <>
            <SubUserTable subUsers={filteredSubUsers} setIsDialogOpen={setIsDialogOpen} setIsDeleteOpen={setIsDeleteOpen} />
            {isDialogOpen && <AddUserFormDialog isDialogOpen={isDialogOpen} setIsDialogOpen={setIsDialogOpen} allImageSources={allImageSources?.all_sources} allVideoSources={allVideoSources?.all_sources} _createUser={_createUser} />}
            {isDeleteOpen && <DeleteActionDialog isDeleteOpen={isDeleteOpen} setIsDeleteOpen={setIsDeleteOpen} onSubmit={_deleteUser} title='Are you sure you wish to delete this user?' />}
          </>
          : <UpgradePlanButton planType='video' btnText='Upgrade to Starter' />
      }
    </ContentSection>
  )
}
