import { Button } from '@/components/ui/button'
import PropTypes from 'prop-types'
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { Form, FormControl, FormField, FormItem, FormLabel } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { useForm, useWatch } from 'react-hook-form'
import { ASSIGNABLE_ROLES } from '@/utils/constants'
import MultipleSelector from '@/components/ui/multi-select'
import { useEffect } from 'react'
import { LoadingSpinner } from '@/components/ui/spinner'

export default function AddFormDialog ({ isDialogOpen, setIsDialogOpen, allImageSources, allVideoSources, _createUserToken }) {
  const form = useForm({ mode: 'onChange', defaultValues: { name: '', roles: [], sources: [], video_sources: [] } })
  const selectedRoles = useWatch({ control: form.control, name: 'roles' })

  // Check if a selected role is specifically related to video sources or image sources
  const hasImageRole = selectedRoles.some(role => role.value === 'source-specific')
  const hasVideoRole = selectedRoles.some(role => role.value === 'video-source-specific')

  useEffect(() => {
    if (!hasImageRole) {
      form.setValue('sources', [])
    }
    if (!hasVideoRole) {
      form.setValue('video_sources', [])
    }

    if (!isDialogOpen) {
      form.reset()
    }
  }, [hasImageRole, hasVideoRole, form, isDialogOpen])

  return (
    <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Add new key</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(_createUserToken)} className='space-y-8'>
            <FormField
              control={form.control}
              name='name'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Name</FormLabel>
                  <FormControl>
                    <Input placeholder='Please enter a name for key' {...field} required />
                  </FormControl>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name='roles'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Roles</FormLabel>
                  <FormControl>
                    <MultipleSelector
                      {...field}
                      defaultOptions={ASSIGNABLE_ROLES}
                      placeholder='Select roles for new key'
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            {
              hasImageRole &&
                <FormField
                  control={form.control}
                  name='sources'
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Sources</FormLabel>
                      <FormControl>
                        <MultipleSelector
                          {...field}
                          defaultOptions={allImageSources.map(({ namespace, id }) => ({
                            label: namespace,
                            value: id
                          }))}
                          placeholder='Select image sources'
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />
            }
            {
              hasVideoRole &&
                <FormField
                  control={form.control}
                  name='video_sources'
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Video Collections</FormLabel>
                      <FormControl>
                        <MultipleSelector
                          {...field}
                          defaultOptions={allVideoSources.map(({ name, id }) => ({
                            label: name,
                            value: id
                          }))}
                          placeholder='Select video sources'
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />
            }
            <DialogFooter>
              <Button type='button' variant='outline' onClick={() => setIsDialogOpen(false)} className='cursor-pointer'>Close</Button>
              <Button type='submit' className='cursor-pointer' disabled={form.formState.isSubmitting}>{form.formState.isSubmitting && <LoadingSpinner />}Create API Key</Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  )
}

AddFormDialog.propTypes = {
  isDialogOpen: PropTypes.bool.isRequired,
  setIsDialogOpen: PropTypes.func.isRequired,
  allImageSources: PropTypes.array.isRequired,
  allVideoSources: PropTypes.array.isRequired,
  _createUserToken: PropTypes.func.isRequired
}
