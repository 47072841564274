import { useForm } from 'react-hook-form'
import { Button } from '@/components/ui/button'
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel
} from '@/components/ui/form'
import { Switch } from '@/components/ui/switch'
import { Input } from '@/components/ui/input'
import { Badge } from '@/components/ui/badge'
import PropTypes from 'prop-types'
import { toast } from 'sonner'
import { useEffect, useState } from 'react'
import { X, BellRing } from 'lucide-react'
import http from '@/utils/http'
import { endpoints } from '@/utils/endpoints'

export default function BillingAlertForm ({
  billingAlertData,
  userData
}) {
  const billingID = billingAlertData.id || ''
  const [isEditing, setIsEditing] = useState(false)
  const [showInputs, setShowInputs] = useState(
    billingAlertData?.state === 'active' && billingID
  )
  const [emails, setEmails] = useState(billingAlertData?.emails || [userData.email] || [])
  const form = useForm({
    defaultValues: {
      id: billingID || '',
      state: billingAlertData?.state === 'active' || false,
      threshold: billingAlertData?.threshold || ''
    }
  })

  // set the default value of the form
  useEffect(() => {
    setShowInputs(form.getValues('state'))
  }, [form.watch('state')])

  // **** SUBMIT FORM **** //
  async function onSubmit (data) {
    // if there inactive state and no billingId then just remove the input fields
    if (!data.state && billingID === '') {
      // reset form value
      form.setValue('state', false)
      form.setValue('threshold', '')
      setShowInputs(false)
      setIsEditing(false)
      return
    }

    // if there is no threshold then show error
    if (!data.threshold) {
      toast.error('Threshold is required')
      return
    }

    // if there is no email then show error
    if (emails.length === 0) {
      toast.error('Please add an email')
      return
    }

    // if threshold is negative then show error
    if (data.threshold < 0) {
      toast.error('Threshold cannot be negative')
      return
    }

    // Payload to be sent to the server
    const payload = {
      ...data,
      state: data.state ? 'active' : 'inactive',
      emails
    }

    if (payload.state === 'active') {
      const promise = billingID
        ? http({}).post(
          `${endpoints.organization.billing.alerts.edit.replace(':alert_id', billingID)}`,
          payload
        )
        : http({}).post(endpoints.organization.billing.alerts.base, payload)
      toast.promise(promise, {
        loading: billingID ? 'Seting up...' : 'Updating alert...',
        success: 'Saved Successfully.!',
        error: (error) => error?.response?.data?.error?.message || 'An error occurred while saving alert settings'
      })
      try {
        await promise
      } catch (error) {
        toast(error?.error?.message)
      } finally {
        setIsEditing(false)
      }
    } else if (payload.state === 'inactive') {
      // send the reuest to disable the alert with state of inactive
      const promise = http({}).post(
        `${endpoints.organization.billing.alerts.edit.replace(':alert_id', billingID)}`,
        payload
      )
      toast.promise(promise, {
        loading: 'Disabling alert...',
        success: 'Alert disabled successfully',
        error: (error) => error?.response?.data?.error?.message || 'An error occurred while disabling alert'
      })
      try {
        await promise
      } catch (error) {
        toast(error?.error?.message)
      } finally {
        form.setValue('state', false)
        setShowInputs(false)
      }
    }
  }

  // function to submit the form with toggle switch
  // if the toggle switch is on then submit the form with state as true
  // if the toggle switch is off then submit the form with state as false
  function handleSwitchChange (value) {
    if (billingID !== '') {
      if (value) {
        onSubmit({
          id: form.getValues('id'),
          state: true,
          emails,
          threshold: form.getValues('threshold')
        })
      } else {
        onSubmit({
          id: form.getValues('id'),
          state: false,
          emails,
          threshold: form.getValues('threshold')
        })
      }
    }
    if (!value && isEditing) {
      if (confirm('You have unsaved settings. Are you sure you don’t want to undo this setting ?')) {
        onSubmit({
          id: form.getValues('id'),
          state: false,
          emails,
          threshold: form.getValues('threshold')
        })
      } else {
        form.setValue('state', true)
      }
    } else {
      form.setValue('state', value)
      setShowInputs(value)
    }
  }

  // This handle is when user presses enter key in enter email input
  // handle different scenarios
  // 1. if the email is already in the list then show error
  // 2. if the email is not valid then show error
  // etc..
  function handleAddEmail (event) {
    if (event.key === 'Enter' && event.target.value) {
      // prevent duplicate emails in the list
      if (emails.includes(event.target.value.trim())) {
        toast.error('Email already exists')
        event.target.value = ''
        return
      }
      // if email is not valid then show error
      // regex to check if email is valid
      if (!/\S+@\S+\.\S+/.test(event.target.value.trim())) {
        toast.error('Please enter a valid email address')
        event.target.value = ''
        return
      }

      event.preventDefault()
      setEmails([...emails, event.target.value.trim()])
      event.target.value = ''
      setIsEditing(true)
    }
    if (event.key === 'Backspace' && event.target.value === '' && emails.length > 0) {
      setEmails(emails.slice(0, -1))
      setIsEditing(true)
    }
  }

  // function to remove email from the list
  function handleRemoveEmail (index) {
    setEmails(emails.filter((_, i) => i !== index))
    setIsEditing(true)
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className='space-y-8'>
        <div className='relative'>
          <div className='space-y-4'>
            <FormField
              control={form.control}
              name='state'
              render={({ field }) => (
                <FormItem className='flex flex-row items-center justify-between rounded-lg border p-4 bg-card'>
                  <div className='space-y-0.5'>
                    <FormLabel className='text-base'>Get Alert</FormLabel>
                    <FormDescription>Set alert for billing access and usage</FormDescription>
                  </div>
                  <FormControl>
                    <Switch
                      checked={field.value}
                      onCheckedChange={handleSwitchChange}
                      onChange={() => _submitDataWithToggle()}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            {showInputs && (
              <div className='space-y-6 rounded-lg border p-4'>
                <FormField
                  control={form.control}
                  name='emails'
                  render={() => (
                    <FormItem>
                      <FormLabel>Emails</FormLabel>
                      <FormControl>
                        <div className='flex flex-wrap gap-2 border rounded-lg px-1 py-1'>
                          {emails.map((email, index) => (
                            <Badge variant='primary' key={index} className='px-2 py-1 rounded-sm flex items-center gap-2 text-sm'>
                              {email}
                              <button type='button' onClick={() => handleRemoveEmail(index)}>
                                <X size={12} className='cursor-pointer' />
                              </button>
                            </Badge>
                          ))}
                          <input
                            className='bg-transparent outline-none px-2 py-1 text-sm placeholder:text-muted-foreground'
                            placeholder='Add Email'
                            onKeyDown={handleAddEmail}
                          />
                        </div>
                      </FormControl>
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name='threshold'
                  rules={{ required: 'Threshold is required' }}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Threshold (USD)</FormLabel>
                      <FormControl>
                        <Input
                          onKeyDown={() => setIsEditing(true)}
                          placeholder='e.g. 2000'
                          type='number'
                          {...field}
                        />
                      </FormControl>
                    </FormItem>
                  )}
                />
                <div className='flex justify-end'>
                  <Button
                    size='sm'
                    type='submit'
                    className='cursor-pointer'
                    disabled={!isEditing}
                  >
                    <BellRing />
                    Set Alert
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </form>
    </Form>
  )
}

BillingAlertForm.propTypes = {
  billingAlertData: PropTypes.object.isRequired,
  userData: PropTypes.object.isRequired
}
