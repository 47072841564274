import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { useEffect } from 'react'
import http from '@/utils/http'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Textarea } from '@/components/ui/textarea'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/components/ui/select'
import { Button } from '@/components/ui/button'
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
} from "@/components/ui/popover"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command"
import { Check, ChevronsUpDown } from "lucide-react"
import { cn } from '@/utils/helper' 
import { countryList } from '@/utils/countryLists'
import { toast } from 'sonner'
import { endpoints } from '@/utils/endpoints'

export default function BillingDetailsForm ({
  billingDetails,
  editMode,
  setEditMode,
  setIsDirty,
  setFormSubmit,
  setFormReset
}) {

  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      company_name: billingDetails?.company_name || '',
      gst_number: billingDetails?.gst_number || '',
      address_line: billingDetails?.address_line || '',
      city: billingDetails?.city || '',
      state_code: billingDetails?.state_code || '',
      postal: billingDetails?.postal || '',
      country_code: billingDetails?.country_code || ''
    }
  })

  useEffect(() => {
    setIsDirty(form.formState.isDirty)
  }, [form.formState.isDirty])

  // Define handlers and pass them up to parent
  useEffect(() => {
    setFormSubmit(() => form.handleSubmit(onSubmit))
    setFormReset(() => () => form.reset(billingDetails))
  }, [form, billingDetails, setFormSubmit, setFormReset])

  async function onSubmit (data) {
    const gstNumberRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/
    if (data.gst_number && !gstNumberRegex.test(data.gst_number)) {
      toast.error('Please enter a valid GST number')
      form.setValue('gst_number', '')
      return
    }

    const promise = http({}).post(endpoints.organization.billing.details, data)
    toast.promise(promise, {
      loading: 'Saving..',
      success: 'Updated successfully',
      error: (error) => error?.response?.data?.error?.message || 'An error occurred while updating billing details'
    })

    try {
      const response = await promise
      if (response) {
        form.reset(data)
        setEditMode(false)
      }
    } catch (error) {
      console.error('Error updating billing details', error)
    }
  }

  return (
    <Form {...form}>
      <form id='edit-billing-details-form' className='space-y-8'>
        <div className='grid grid-cols-1 gap-6 md:grid-cols-2'>
          <FormField
            control={form.control}
            name='company_name'
            disabled={!editMode}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Company Name</FormLabel>
                <FormControl>
                  <Input placeholder='e.g. Monte Inc.' {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name='gst_number'
            disabled={!editMode}
            render={({ field }) => (
              <FormItem>
                <FormLabel>GST Number</FormLabel>
                <FormControl>
                  <Input placeholder='Enter your GST number' {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className='grid grid-cols-1'>
          <FormField
            control={form.control}
            name='address_line'
            disabled={!editMode}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Address Line</FormLabel>
                <FormControl>
                  <Textarea placeholder='Enter your company address.' {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className='grid grid-cols-1 gap-x-6 gap-y-8 md:grid-cols-2'>
          <FormField
            control={form.control}
            name='city'
            disabled={!editMode}
            render={({ field }) => (
              <FormItem>
                <FormLabel>City</FormLabel>
                <FormControl>
                  <Input placeholder='Your City' {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name='state_code'
            disabled={!editMode}
            render={({ field }) => (
              <FormItem>
                <FormLabel>State Code</FormLabel>
                <FormControl>
                  <Input placeholder='State Code' {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name='postal'
            disabled={!editMode}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Postal</FormLabel>
                <FormControl>
                  <Input placeholder='Postal code' {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          {/* // Country Code */}
          <FormField
            control={form.control}
            name="country_code"
            render={({ field }) => {
              const [open, setOpen] = useState(false)

              const selectedCountry = countryList.find(
                (item) => item.value === field.value
              )

              return (
                <FormItem className="flex flex-col">
                  <FormLabel>Country Code</FormLabel>
                  <Popover open={open} onOpenChange={setOpen}>
                    <PopoverTrigger asChild>
                      <Button
                        variant="outline"
                        role="combobox"
                        aria-expanded={open}
                        className="w-[300px]] justify-between"
                        disabled={!editMode}
                      >
                        <span className='truncate text-left' title={selectedCountry ? selectedCountry.label : "Select country..."}>
                          {selectedCountry ? selectedCountry.label : "Select country..."}
                        </span>
                        <ChevronsUpDown className="ml-2 h-4 w-4 opacity-50 shrink-0" />
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent className="lg:w-[270px] p-0">
                      <Command>
                        <CommandInput
                          placeholder="Search country..."
                          className="h-9"
                        />
                        <CommandList>
                          <CommandEmpty>No country found.</CommandEmpty>
                          <CommandGroup>
                            {countryList.map((country) => (
                              <CommandItem
                                key={country.value}
                                value={country.value}
                                onSelect={(currentValue) => {
                                  if (currentValue !== field.value) {
                                    field.onChange(currentValue)
                                  }
                                  setOpen(false) // ✅ close popover after selection
                                }}
                              >
                                {country.label}
                                <Check
                                  className={cn(
                                    "ml-auto h-4 w-4",
                                    field.value === country.value
                                      ? "opacity-100"
                                      : "opacity-0"
                                  )}
                                />
                              </CommandItem>
                            ))}
                          </CommandGroup>
                        </CommandList>
                      </Command>
                    </PopoverContent>
                  </Popover>
                  <FormMessage />
                </FormItem>
              )
            }}
          />
        </div>
      </form>
    </Form>
  )
}

BillingDetailsForm.propTypes = {
  billingDetails: PropTypes.object,
  editMode: PropTypes.bool,
  setEditMode: PropTypes.func,
  setIsDirty: PropTypes.func,
  setFormSubmit: PropTypes.func,
  setFormReset: PropTypes.func
}
