import PropTypes from 'prop-types'
import { Button } from '@/components/ui/button'
import { Card } from '@/components/ui/card'
import { Badge } from '@/components/ui/badge'
import { LoadingSpinner } from '@/components/ui/spinner'
import MasterCard from '@/assets/mastercard.svg'
import Visa from '@/assets/visa.svg'
import Amex from '@/assets/amex.svg'
import { Separator } from '@/components/ui/separator'

export default function PaymentCards ({ cards, setIsDeleteOpen, _primaryCard, loading, setOpenStripeCardModal }) {
  const getCardImage = (card) => {
    if (card.card.brand === 'mastercard') {
      return MasterCard
    } else if (card.card.brand === 'visa') {
      return Visa
    } else if (card.card.brand === 'amex') {
      return Amex
    }
    return null
  }
  return (
    <Card className='rounded-lg border shadow-none'>
      {
        !cards.cards && cards.cards.length > 0
          ? cards.cards.map((card, i) => {
            return (
              <div key={card.id}>
                <div className='space-y-2 flex flex-row items-center justify-between p-4'>
                  <div className='mb-0 flex items-center gap-4'>
                    <div className='flex size-10 items-center justify-center rounded-lg bg-muted p-2'>
                      <img
                        className='tabler-icon'
                        src={getCardImage(card)}
                        alt={card.card.brand}
                      />
                    </div>
                    <div className='flex flex-col'>
                      <div className='flex items-center gap-2'>
                        <label className='font-medium peer-disabled:cursor-not-allowed peer-disabled:opacity-70 text-base'>
                          {card.card.brand.charAt(0).toUpperCase() + card.card.brand.slice(1)}{' '} •••• {card.card.last4}
                        </label>
                        {cards.default_card === card.id && <Badge variant='success' className='uppercase text-[10px]'>Primary</Badge>}
                      </div>
                      <ul className='text-[0.8rem] text-muted-foreground flex gap-1.5 items-center'>
                        <li className='list-inline-item'>
                          <small className='d-block'>Expires: {card.card.exp_month} /{' '}{card.card.exp_year}</small>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className='flex flex-row items-center space-x-2'>
                    {cards.default_card !== card.id && <Button className='cursor-pointer' variant='outline' size='sm' disabled={loading === card.id} onClick={() => _primaryCard(card.id, 'onPrimary')}>{loading === card.id && <LoadingSpinner />} Set Primary</Button>}
                    <Button className='cursor-pointer' variant='destructive' size='sm' onClick={() => setIsDeleteOpen(card)}>Delete</Button>
                  </div>
                </div>
                {i < cards.cards.length - 1 && <Separator className='my-1 flex-none' />}
              </div>
            )
          })
          : <div className='flex flex-col items-center justify-center h-32 text-center gap-2'>
            <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' strokeWidth={0.8} stroke='currentColor' className='h-12 w-12 opacity-30'>
              <path strokeLinecap='round' strokeLinejoin='round' d='M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Z' />
            </svg>
            <p className='text-sm'>No payment methods found.</p>
            </div>
        }
    </Card>
  )
}

PaymentCards.propTypes = {
  cards: PropTypes.object,
  setIsDeleteOpen: PropTypes.func,
  _primaryCard: PropTypes.func,
  loading: PropTypes.bool,
  setOpenStripeCardModal: PropTypes.func
}
